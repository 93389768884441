.woocommerce .button,
.woocommerce-Button {
	@extend %btn;
	background-color: palette( grey );
	color: #fff;
	transition: all 0.2s $transition;
	padding: 0.85rem 1.2rem;

	margin: 0 0.6rem 0 0;

	&:hover {
		color: #fff;
		background-color: darken( palette( grey ), 15% );
		border-color: darken( palette( grey ), 15% );
	}

	&[disabled] {
		cursor: not-allowed;
		opacity: 0.65;
	}

	&.no-orders {
		margin-right: 1rem;
	}
}

.buttons {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-top: auto;
}

.button:last-of-type {
	margin: 0;
}
