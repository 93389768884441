body.checkout-order-received,
body.my-account-view-order {
	.woocommerce {
		.woocommerce-MyAccount-content {
			p:first-child {
				font-size: 0.9rem;
			}

			mark {
				background-color: transparent;
				font-weight: bold;
			}
		}

		.order-row {
			border-top: 1px solid #e3e3e3;
			padding: 1.5rem 0;
			width: 100%;
			display: flex;
			flex-direction: column;

			ul,
			li {
				margin: 0;
			}

			.order-details-info {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 0.9rem;
			}

			.product-details-info {
				display: flex;
				flex-direction: row;
				align-items: baseline;
				flex-wrap: wrap;

				h2 {
					font-size: 0.95rem;
				}

				.license-type {
					margin: 0 0 0 0.5rem;
					font-size: 0.63rem;
					color: #99a1b5;
				}

				.cost {
					margin: 0 0 0.75rem auto;
					font-size: 0.95rem;
					color: #444;
					letter-spacing: 0;
					text-align: right;

					@media ( max-width: 360px ) {
						margin-top: 1rem;
					}
				}
			}

			.download-link,
			.license-key {
				display: flex;
				align-items: baseline;
				line-height: 1;
				margin-top: 0.12rem;

				h3 {
					font-family: ff( "body" );
					font-weight: 700;
					font-size: 0.575rem;
					color: #aab3cc;
					letter-spacing: 0;
					text-transform: uppercase;
					width: 4.5rem;
				}

				p {
					font-family: Consolas, Monaco, Lucida Console, monospace;
					font-size: 0.6rem;
					margin: 0;
				}

			}
		}

		.cost-breakdown {
			width: 380px;
			display: flex;
			flex-direction: column;
			margin-left: auto !important;

			@media ( max-width: 360px ) {
				width: auto;
				margin-left: 0 !important;
				padding: 0;
			}

			h3 {
				font-family: ff( "body" );
				font-size: 0.75rem;
				font-weight: 500;
				color: #99a1b5;
				letter-spacing: 0;
			}

			p {
				margin: 0;
				font-size: 0.75rem;
			}

			.discount {
				color: #08ab0a;
			}

			.cost-item:first-of-type {
				margin: 0;
			}

			.total {
				padding: 0.8rem 0;
				margin-top: 1.25rem !important;
				border-top: 1px solid #e3e3e3;
			}

			.cost-item {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: space-between;
				margin-top: 0.35rem;

				.label {
					font-size: 0.85rem;
					color: #444;
					font-weight: 700;
				}

				&.total {
					p {
						font-size: 0.6rem;
						color: #99a1b5;
						text-transform: uppercase;
						align-self: flex-end;
						line-height: 2.2;
						display: flex;
						align-items: center;
					}

					.amount {
						font-size: 1.1rem;
						color: #444;
						font-weight: 700;
						margin-left: 0.35rem;
					}
				}

				span {
					font-size: 0.6rem;
					color: #99a1b5;
					margin: 0 0.35rem 0 auto;
					text-transform: uppercase;
					align-self: flex-end;
					line-height: 2.2;
				}

				.total-cost {
					font-size: 1.1rem;
					color: #444;
					font-weight: 700;
				}
			}
		}

		.order-summary {
			border-bottom: 1px solid #e3e3e3;
			padding-bottom: 0;
		}

		.btn-print {
			padding: 0.5rem 0.9rem;
			margin: 0;
		}

		.order_details {
			width: auto;
			margin-top: 0.9rem;

			li {
				margin-left: 3.5rem;
			}

			li:first-of-type {
				margin-left: 0;
			}

			h3 {
				font-family: ff( "body" );
				font-weight: 700;
				font-size: 0.62rem;
				color: #aab3cc;
				letter-spacing: 0;
				text-transform: uppercase;
			}

			p {
				font-size: 0.65rem;
				margin: 0.45rem 0 0 0;
				line-height: 1rem;
			}

			.product-total {
				text-align: right;
				vertical-align: top;

				.woocommerce-Price-amount {
					font-size: 0.8rem;
				}
			}

			tfoot {
				th {
					width: 100%;
					text-align: right;
					font-size: 0.7rem;
				}

				td {
					white-space: nowrap;
					text-align: right;
					font-size: 0.7rem;
				}
			}

			small {
				display: none;
			}
		}

		.shop_table.customer_details {
			width: auto;

			tbody {
				tr {
					th {
						font-weight: normal;
						text-align: left;
						padding: 0 0.25rem 0 0;
						text-transform: none;
					}

					td {
						border: 0;
						padding: 0;
					}
				}
			}
		}

		.title {
			display: none;
		}

		.woocommerce-order-downloads {
			display: none;
		}

		address {
			margin-bottom: 2rem;
			font-style: normal;
		}

		.wc-item-downloads {
			margin: 0.25rem 0;
			padding: 0;
			list-style: none;
			font-size: 80%;
		}

		.dbi-licence-key {
			font-size: 80%;

			code {
				padding: 2px 5px;
				background-color: #eff4f5;
			}
		}
	}
}

body.checkout-order-received,
body.my-account-view-order {
	.woocommerce {
		> p:first-child {
			font-size: 1.8em;
			margin-bottom: 1em;
		}

		.order-print {
			margin-bottom: 2.5rem;
		}
	}

	ul.order_details {
		margin-bottom: 1.5em;

		li {
			font-size: 1.4em;
			margin-bottom: 0.5em;
			list-style: none;
		}
	}

	.proceed {
		font-size: 1rem;
		z-index: 9;
		position: relative;
	}

	.affiliate-tracking-pixel {
		position: absolute;
	}

	header.page-title {
		h1 {
			margin-bottom: 0;
		}
	}

	.instructions {
		margin-bottom: 2rem;

		p {
			font-size: 0.75rem;
			color: #666;
		}

	}

	.cross-sell {
		padding: 1rem;
		margin-bottom: 2rem;
	}
}

.woocommerce-thankyou-order-details {
	padding: 0;
	display: flex;
}

.dbi-checkout-email-subscriptions {
	max-width: 900px;
	margin: 0 auto;
}

body.checkout-order-received,
body.my-account-view-order {
	.dbi-checkout-order-details {
		display: none;
	}

	.woocommerce-order-details,
	.dbi-checkout-order-details {
		&.dbi-checkout-order-details-show {
			display: inherit;
		}

		h2 {
			letter-spacing: 0;
			font-family: ff( "body" );
			font-weight: 700;
			font-size: 1.05rem;
			color: #444;
			text-transform: none;
			margin: 0;
		}
	}

	.dbi-email-subscription-slider {
		margin-bottom: 2rem;
		float: left;

		.dbi-email-subscription-slide {
			display: none;

			> p,
			section.instructions p {
				margin-bottom: 1rem;
				font-size: 0.75rem;
			}

			section.instructions {
				p:last-child {
					margin: 0;
				}
				margin: 0 0 1rem 0;
			}

			.dbi-email-subscription-slide-image {
				width: 100%;
				max-width: 100%;
				height: auto;
				margin-bottom: 2rem;
			}

			.dbi-email-subscription-slide-number {
				color: palette( grey );
				margin-top: 0.5rem;
				margin-left: auto;
			}

			&.dbi-email-subscription-slide-active {
				display: inline;
			}

			.dbi-email-subscription-slide-actions {
				margin-top: 1rem;
				display: block;

				@include bp(480px) {
					display: flex;
					align-items: center;
				}

				&.dbi-email-subscribe-processing {
					opacity: 0.6;
				}

				.dbi-email-subscription-dismiss {
					background: none !important;
					font: inherit;
					border: none;
					padding: 0;
					outline: 0;
					box-shadow: none;
					text-decoration: none;
					color: palette( grey, 400 );
					transition: color 0.2s $transition;
					margin-left: 30px;

					&:focus,
					&:hover {
						color: darken( grey, 15% );
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.dbi-checkout-sub-header {
	font-family: "Sofia Pro", sans-serif;
	position: relative;
	background: palette( brand, 500 );
	text-align: center;
	color: #fff;
	padding: 2rem 5%;
	font-weight: bold;
	font-size: 1.2rem;

	p {
		margin-bottom: 0;
	}

	a {
		color: #fff;
		text-decoration: underline;
	}
}

.woocommerce-column__title,
h2.woocommerce-order-details__title {
	font-family: Lato, sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 1rem;
	margin: 2rem 0 1rem;
	text-transform: none;
	color: #424242;
	letter-spacing: 0;
}

body.checkout-order-received,
body.my-account-view-order {
	.order-print {
		display: none;
	}

	.order-summary {
		margin-bottom: 2rem;
	}

	.woocommerce {
		max-width: 940px;

		.cost-breakdown .cost-item {
			span.woocommerce-Price-amount,
			span.woocommerce-Price-currencySymbol {
				font-size: inherit;
				color: inherit;
				margin-right: 0;
			}

			> span {
				align-self: initial;
			}
		}
	}
}
