.form-group {
	display: flex;
	justify-content: stretch;

	flex-direction: column;

	@include bp(md) {
		flex-direction: row;
	}

	label {
		align-self: center;
		text-transform: uppercase;
		color: palette( grey, 400 );
		font-size: 0.65rem;
		letter-spacing: 0.025rem;
		margin-bottom: 0.5rem;

		@include bp(md) {
			margin-right: 1rem;
			margin-bottom: 0;
		}
	}
	//align-items: center;
}
