@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

//post checkout header animation
.fade-in-up {
	animation: fadeInUp ease 1s;
	transition-delay: 2.5s;
}

@keyframes fadeInUp {
	from {
		opacity: 0;
		transform: translate3d( 0, 40px, 0 );
	}

	to {
		opacity: 1;
		transform: translate3d( 0, 0, 0 );
	}
}
