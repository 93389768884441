.dbi-plugin-tweets--upgrade {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin: 2rem 0 -1.5rem !important;

	.dbi-media {
		margin: 0 0 1.5rem;
		padding: 1rem 1.5rem 1rem 1rem;
		width: 100%;

		@include bp(md) {
			width: 47.5%;
		}
	}
}

.dbi-plugin-tweets--upgrade-top {
	margin: 1.5rem 0 1.5rem !important;

	@include bp(sm) {
		margin: 2rem 0 1.5rem !important;
	}
}

.dbi-page--upgrade {
	.section--padding-bottom:first-child {
		padding-bottom: 1.5rem;

		@include bp(sm) {
			padding-bottom: 2rem;
		}
	}

	p.intro {
		margin: 0;

		@include bp(sm) {
			margin: 0 0 1.5rem;
		}

		@include bp(md) {
			margin-bottom: 0;
		}
	}

	@media ( max-width: 768px ) {
		.section--upgrade-table .dbi-container {
			padding-left: 0;
			padding-right: 0;

			h2 {
				padding-left: 5%;
				padding-right: 5%;
			}
		}

		.dbi-upgrade-table__content {
			padding-left: 5%;
			padding-right: 5%;
		}
	}
}

.dbi-upgrade-table {
	display: block;

	thead,
	tbody,
	tr,
	th,
	td {
		display: block; }

	tr {
		position: relative; }

	@include bp(md) {
		display: table;

		thead {
			display: table-header-group; }

		tbody {
			display: table-row-group; }

		tr {
			display: table-row; }

		th,
		td {
			display: table-cell; }
	}

	.dbi-upgrade-table__content h3 {
		box-sizing: border-box;
		max-width: 60%;

		@include bp(md) {
			max-width: 100%;
		}
	}

	.dbi-upgrade-table__icon {
		position: absolute;
		top: 0;
		right: 0;
		background: none;
		border: 0;
		box-sizing: border-box;
		width: 20%;
		text-align: center;

		&.dbi-upgrade-table__icon--cross {
			right: 20%; }

		svg {
			vertical-align: middle; }

		@include bp(md) {
			position: static;
			top: auto;
			right: auto;
			width: auto;
			border-bottom: 1px solid #ebebeb;
			vertical-align: text-top;
		}
	}

	tbody tr:nth-child(odd) {
		background-color: #fafafa; }

	h3 {
		font-size: 0.85rem;
		font-weight: 600;
	}

	.icon {
		width: 1rem;
		height: 1rem;

		&.icon-checkmark {
			fill: #67bf75;
		}

		&.icon-close {
			fill: #f00;
		}
	}
}

.upgrade-animation {
	width: 100%;
	height: 100%;
	margin: -1rem auto -2rem auto;

	@include bp(sm) {
		width: 400px;
		height: 400px;
	}
}

.wp-migrate-db-pro-upgrade,
.wp-offload-media-upgrade,
.wp-offload-ses-upgrade {
	.upgrade {
		.dbi-page-header__title {
			text-transform: none;
			font-weight: 300;
			font-size: 50px;
			line-height: 1;
			margin-bottom: 2rem;

			@include bp(sm) {
				margin-bottom: 2rem;
			}
		}

		h2 {
			font-weight: normal !important;
			text-transform: none;
			font-size: 1rem;
			margin-top: 0 !important;

			&.mdb {
				color: #6dd6ff;
			}

			&.ome {
				color: #ffb560 !important;
			}

			&.oses {
				color: #ccabff !important;
			}
		}
	}
}
