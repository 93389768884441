.wcss-LicenceSupport {
	background-color: #fff;
	margin-bottom: 1.25rem;
	padding: 1.5rem 1.75rem 1.25rem;
	border-radius: 8px;
	box-shadow: -10px 10px 20px 0 rgba( 30, 30, 30, 0.05 );
	display: flex;
	flex-direction: column;

	p {
		padding: 0;
		margin: 0 0 0.5rem 0;
	}

	.tab-title {
		opacity: 0.8;
		font-family: ff( body );
		font-weight: 600;
		font-size: 20px;
		color: #000;
		letter-spacing: 0;
		padding: 0.65rem 0;
		margin-bottom: 1rem;
	}

	.licence-key {
		font-family: "Courier New", monospace;
		font-weight: 600;
		font-size: 0.7rem;
		display: inline;
	}

	.wcss-LicenceSupport-return-link {
		margin: 2rem 0 0 0;
	}
}

.wcss-LicenceSupport-contacts-input,
.wcss-LicenceSupport-contacts-textarea {
	width: 100%;
	max-width: 500px;
	margin: 1.5rem 0 1rem 0;
}

.wcss-LicenceSupport-contacts-textarea {
	min-height: 200px;
}

.wcss-LicenceSupport-return-link {
	margin-top: 1rem;
}
