.wcss-LicenceUpgrade-page-title {
	margin-bottom: 2rem;
	text-align: center;
}

.wcss-LicenceUpgrade {
	.dbi-pricing__table {
		text-align: center;
	}
	tbody {
		td {
			text-align: center;

			input[type="number"]::-webkit-inner-spin-button {
				opacity: 1;
			}

			.seats {
				width: 80px;
				text-align: center;
			}
		}
	}

	thead,
	tfoot {
		th {
			vertical-align: top;
		}
	}

	.discount-badge {
		top: -24px;
		left: -15px;
		right: inherit;

		&.show {
			display: flex !important;
		}
	}
}

section.wcss-LicenceUpgrade {
	margin-left: -10px;
	transform: translateX( 5px );
}

// This styling maybe used by new Product page pricing design.

//.licence-upgrade {
//	max-width: 1170px;
//	position: relative;
//
//	@media ( max-width: 1170px ) {
//		padding: 0 5%;
//	}
//
//	.owl-stage {
//		display: flex;
//	}
//
//	.owl-item {
//		display: flex;
//
//		&:not(.active) {
//			opacity: 0.2;
//		}
//	}
//
//	.owl-next,
//	.owl-prev {
//		position: absolute;
//		top: 50%;
//		background-color: #ee3b71;
//		border-radius: 50%;
//		width: 1.5rem;
//		height: 1.5rem;
//		transform: translateY( -50% );
//		cursor: pointer;
//		display: flex;
//		align-items: center;
//		justify-content: center;
//
//		.icon {
//			width: 1rem;
//			height: 1rem;
//			fill: #fff;
//		}
//
//		&.disabled {
//			pointer-events: none;
//			opacity: 0.25;
//		}
//	}
//
//	.owl-prev {
//		left: 0;
//	}
//
//	.owl-next {
//		right: 0;
//	}
//
//	@media ( min-width: 1270px ) {
//		.owl-next,
//		.owl-prev {
//			width: 2rem;
//			height: 2rem;
//
//			.icon {
//				width: 1.25rem;
//				height: 1.25rem;
//			}
//		}
//
//		.owl-prev {
//			left: -2.75rem;
//		}
//
//		.owl-next {
//			right: -2.75rem;
//		}
//	}
//
//	&__plan {
//		display: flex;
//		flex-direction: column;
//		background: #fff;
//		border-radius: 22px;
//		box-shadow: 0 8px 20px rgba( 37, 38, 50, 0.05 );
//		border: 1px solid #ddd;
//		margin-left: 10px;
//		margin-right: 10px;
//
//		&__business {
//			--upgrade-plan-background: rgba( 13, 104, 255, 0.07 );
//			--upgrade-plan-color: #0d68ff;
//			--upgrade-plan-border-color: rgba( 13, 104, 255, 0.5 );
//			--upgrade-plan-header-background: #234170;
//		}
//
//		header {
//			background: var( --upgrade-plan-header-background, #283345 );
//			text-align: center;
//			padding: 25px 0;
//			border-top-left-radius: 22px;
//			border-top-right-radius: 22px;
//			border: 2px solid #fff;
//
//			h3 {
//				text-transform: uppercase;
//				font-size: 0.78rem;
//				color: #fff;
//				margin: 0;
//				letter-spacing: 1px;
//			}
//		}
//
//		&--type {
//			background: var( --upgrade-plan-background, rgba( 0, 158, 15, 0.07 ) );
//			color: var( --upgrade-plan-color, #009e0f );
//			border: 1.5px solid var( --upgrade-plan-border-color, rgba( 0, 158, 15, 0.4 ) );
//			border-radius: 3px;
//			padding: 3px 16px;
//			margin: 12px 15px 0;
//			font-weight: 600;
//			font-size: 13px;
//			letter-spacing: 0.4px;
//			text-align: center;
//		}
//
//		&--cost {
//			font-family: ff( "headings" );
//			font-size: 14px;
//			letter-spacing: 0.221053px;
//			color: #333;
//			opacity: 0.6;
//			margin: 0 15px 24px 15px;
//			text-align: center;
//
//			span {
//				font-weight: 600;
//			}
//		}
//
//		h4 {
//			font-family: ff( "headings" );
//			line-height: 1;
//			margin: 32px auto 24px;
//
//			&.current {
//				opacity: 0.25;
//			}
//
//			.woocommerce-Price-currencySymbol {
//				font-size: 1.043rem;
//				margin-right: 8px;
//				transform: translateY( 6px );
//			}
//
//			bdi {
//				display: flex;
//				justify-content: center;
//			}
//
//			.amount {
//				font-size: 2.087rem;
//				color: #333;
//				font-weight: 500;
//				line-height: 1;
//			}
//		}
//
//		ul {
//			margin-left: 19px;
//			margin-right: 19px;
//			margin-bottom: 50px;
//			font-size: 12.5px;
//		}
//
//		footer {
//			margin-top: auto;
//			text-align: center;
//			margin-bottom: 32px;
//
//			a {
//				background: #0d68ff;
//				font-family: ff( "headings" );
//				font-size: 17px;
//				letter-spacing: -0.2px;
//				display: inline-block;
//				width: 150px;
//				text-align: center;
//				padding: 12px 15px;
//				text-transform: none;
//				border-radius: 6px;
//				color: #fff;
//				text-decoration: none;
//				font-weight: 600;
//				cursor: pointer;
//
//				&:hover,
//				&:visited {
//					color: #fff;
//				}
//
//				&[disabled],
//				&.disabled {
//					background: #cacaca;
//					cursor: not-allowed;
//					pointer-events: none;
//				}
//			}
//		}
//	}
//}
