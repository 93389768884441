.dbi-latest-posts {
	margin: 0;
	padding: 0;
	list-style: none;
	display: flex;
	justify-content: space-between;

	@include bp(md) {
		&::after {
			display: block;
		}
	}
}

@include bp(max sm) {
	.dbi-latest-posts {
		flex-direction: column;
	}
}
