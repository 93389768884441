.dbi-page-header {
	position: relative;
	background: url( ../images/bg_header.jpg ) no-repeat center center palette( grey, 000 );
	background-size: cover;
	text-align: center;
	color: #fff;
	padding: 4.75rem 5% 2.5rem;

	@include bp(lg) {
		padding-left: 0;
		padding-right: 0;
	}

	a:hover {
		text-decoration: none;
	}

	&.dbi-page-header--blog {
		background-image: url( "../images/blog/bg-levelup-hub-header.jpg" );
		padding: 7.4rem 5% 3.783rem;

		.dbi-levelup__search {
			margin-top: 2.783rem;
		}

		.dbi-page-header__title {
			text-transform: none;
			font-size: 1.65rem;
			font-weight: 500;
			line-height: 1.3;
			letter-spacing: -0.4px;
			margin: 0rem auto 1.5rem;

			@include bp(lg) {
				padding: 0 10.666%;
			}

			@include bp(xl) {
				padding: 0 200px;
			}

			+ time {
				font-weight: 100;
				font-family: ff( headings );
			}
		}
	}

	&__meta {
		display: flex;
		flex-direction: column;
		margin: 0 auto;
		align-items: center;

		@include bp(md) {
			display: inline-flex;
			flex-direction: row;
		}
	}

	&__date {
		font-size: 0.609rem;
		font-weight: bold;
		text-transform: uppercase;
		color: #fff;
	}

	&__author {
		display: inline-flex;
		align-items: center;
		margin-top: 12px;
		font-size: 0.609rem;
		font-weight: bold;
		text-transform: uppercase;
		color: #fff;

		@include bp(md) {
			margin-top: 0;
			display: inline-flex;
		}

		&:hover {
			color: #ccc;
		}

		&__avatar {
			max-width: 28px;
			max-height: 28px;
			margin-right: 12px;
			margin-left: 0;
			border: 2px solid #fff;
			display: none;

			@media (min-width: 400px) {
				display: block;
			}
			@include bp(md) {
				margin-left: 40px;
			}
		}
	}


	.dbi-page-header__title {
		text-transform: uppercase;
		font-size: 1.15rem;
		line-height: 1.2;
		letter-spacing: 0.02rem;
		font-weight: 400;

		a {
			color: #fff;
		}
	}

	.dbi-page-header__subtitle {
		font-size: 0.8rem;
		line-height: 1.3;
		margin-bottom: 0;
		margin-top: 0.5rem;
	}

	&.dbi-page-header--migrate {
		color: #fff;
		padding-top: 2.5rem;
		padding-bottom: 3.5rem;
		background-image: url( "../../assets/images/wave-pattern.png" ), linear-gradient( 135deg, #053b90 0%, #531081 100% );
		background-size: cover;
		background-position-y: center;

		.dbi-page-header__title {
			text-transform: none;
			font-size: 1.85rem;
			font-weight: 600;
			line-height: 1.3;
			letter-spacing: -0.4px;
		}

		@include bp(md) {
			padding-top: 7.5rem;
		}

		.dbi-stars {
			padding-right: 0;
			width: auto;
			text-align: center;
			display: block;

			.icon {
				width: 1rem;
				height: 1rem;
				fill: palette( mdb, primary-alt );
			}

			@include bp(md) {
				margin-left: 1.5rem;
				text-align: right;
				display: inline-block;
			}
		}

		.dbi-page-header__title {
			margin-top: 2rem;
			color: #fff;

			.dbi-stars {
				padding-bottom: 0;
			}
		}
	}

	// offload ses
	&.dbi-page-header--offload-ses {
		color: #fff;
		padding-top: 2.5rem;
		padding-bottom: 2rem;

		@include bp(md) {
			padding-top: 7.5rem;
		}

		.dbi-stars {
			padding-right: 0;
			width: auto;
			text-align: center;
			display: block;

			.icon {
				width: 1rem;
				height: 1rem;
				fill: palette( oses, secondary-alt );
			}

			@include bp(md) {
				margin-left: 1.5rem;
				text-align: right;
				display: inline-block;
			}
		}

		.dbi-page-header__title {
			margin-top: 2rem;
			color: #fff;

			+ h2 {
				font-weight: 700;
				color: #fff;
				margin-top: 2.25rem;
			}
		}

	}

	// offload

	&.dbi-page-header--offload {
		color: #fff;
		padding-top: 2.5rem;
		padding-bottom: 2rem;

		@include bp(md) {
			padding-top: 7.5rem;
		}

		.dbi-stars {
			padding-right: 0;
			width: auto;
			text-align: center;
			display: block;

			.icon {
				width: 1rem;
				height: 1rem;
				fill: palette( os3, secondary-alt );
			}

			@include bp(md) {
				margin-left: 1.5rem;
				text-align: right;
				display: inline-block;
			}
		}

		.dbi-page-header__title {
			margin-top: 2rem;
			color: #fff;

			+ h2 {
				font-weight: 700;
				color: #fff;
				margin-top: 2.25rem;
			}

			.dbi-stars {
				padding-bottom: 0;
			}
		}
	}
	// migrate

	&.dbi-page-header--pricing {
		background-image:
			url( "../../assets/images/wave-pattern.png" ),
			linear-gradient( 135deg, #053b90 0%, #531081 100% );

		h2 {
			color: #fff;
			text-align: center;
			flex: 0 0 100%;
			margin-bottom: 1rem;

			@include section-title;

			&::after {
				background-color: #fff;
			}
		}
	}
	// pricing

	&.dbi-page-header--offload {
		background: linear-gradient( 135deg, #8c3b35 0%, #4f103c 100% );
	}

	&.dbi-page-header--offload-ses {
		background: linear-gradient( 169deg, #3a336e 0%, #3d2347 100% );
	}

	&.dbi-page-header--comparison {
		display: none;
		padding-top: 2rem;

		@include bp(lg) {
			display: block;
		}
	}
}

.single {
	.dbi-menu--product + .dbi-page-header {
		.dbi-page-header__title {
			text-transform: none;
			max-width: 1170px;
			margin: 2rem auto;
			font-size: 1.65rem;
			font-weight: 500;
			line-height: 1.3;

			@include bp(lg) {
				padding: 0 10.666%;
			}

			@include bp(xl) {
				padding: 0 200px;
			}
		}
	}
}

//Level Up
.dbi-page-header__subtitle__levelup.dbi-page-header__subtitle__levelup {
	font-size: 0.72rem;
	line-height: 1.3;
	margin-bottom: 0;
	margin-top: 1.2rem;
}

.dbi-levelup__search {
	max-width: 590px;
	margin: 2rem auto 1.6rem;

	input {
		padding: 0.75rem 0 0.75rem 2.5rem;
		font-size: 0.75rem;
		border-radius: 0.2rem !important;
		background: #f8fafc;
		box-shadow: 0 3px 4px 0 (rgba( 0, 20, 36, 0.5 ));

		&:focus {
			outline: 2px solid var( --levelup-focus );
			outline-offset: 1px;
		}

		@media ( min-width: 481px ) {
			padding-right: 130px;
		}

		@media ( max-width: 480px ) {
			padding-right: 0;
		}
	}

	.form-group {
		position: relative;

		.dbi-btn {
			background: var( --levelup-accent );
			border-radius: 5px;
			padding: 0.65rem 0.9rem;
			position: absolute;
			top: 5px;
			right: 5px;

			&:hover {
				background: var( --levelup-btn-hover );
			}

			&:active{
				background: var( --levelup-btn-active );
			}

			&:focus:not(:focus-visible) {
				outline: none;
			}

			&:focus-visible {
				outline: 2px solid var( --levelup-btn-active );
				outline-offset: 1px;
			}

			@media ( max-width: 1339px ) {
				top: 4px;
				right: 4px;
			}

			@media ( max-width: 899px ) {
				top: 4px;
			}

			@media ( max-width: 768px ) {
				top: 3px;
				right: 3px;
			}

			@media ( max-width: 480px ) {
				margin-top: 1rem;
				position: initial;
				padding: 0.8rem 0;
			}
		}
	}

	.icon-levelup-search {
		width: 18px;
		height: 18px;
		top: 20px;
		left: 1rem;
		position: absolute;

		@media ( max-width: 899px ) {
			top: 18px;
		}

		@media ( max-width: 767px ) {
			top: 17px;
		}
	}
}
