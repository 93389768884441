%account-heading {
	font-family: ff( "body" );
	font-size: 20px;
	font-weight: 700;
	line-height: 1rem;
	margin-bottom: 1.75rem;
	text-transform: none;
	color: #424242;
}

.woocommerce-account {
	background: #f4f4f5;
}

body.woocommerce-account {
	.dbi-page-header {
		overflow: hidden;
		position: relative;
		background: #121e2a;
		background-size: cover;
		text-align: center;
		color: #fff;
		padding: 4.5rem 5% 1.5rem;
	}

	.dbi-page-title {
		width: 1170px;
		text-align: left;
		margin: 0 auto 0.85rem;
		font-size: 28px;
		font-weight: 500;
		padding: 0 20px;
	}

	.woocommerce-pagination {
		margin-top: 2rem;
	}
}

%account-tile {
	background-color: #fff;
	margin-bottom: 1.25rem;
	padding: 1.5rem 1.75rem 1.25rem;
	border-radius: 8px;
	box-shadow: -10px 10px 20px 0 rgba( 30, 30, 30, 0.05 );
	display: flex;
	flex-direction: column;
}

body.my-account-edit-account,
body.my-account-edit-address-billing {
	.button {
		@extend %btn-outline;
	}
}

body.my-account {
	.woocommerce {
		padding-top: 3rem;

		.button {
			@extend %btn-outline;
		}
	}

	.woocommerce-error li,
	.woocommerce-message {
		margin-bottom: 1.25rem;
	}

	.account-details {
		display: flex;
		flex-wrap: wrap;

		@include bp(md) {
			flex-wrap: inherit;
		}
		justify-content: space-between;

		.buttons {
			flex-wrap: wrap;
		}

		.button {
			margin-top: 0.5rem;
		}

		> section {
			@extend %account-tile;
			width: 100%;

			@include bp(md) {
				width: 48.75%;

			}
		}

		h2,
		h4 {
			@extend %account-heading;
		}

		.billing-address {
			.contact-details {
				font-size: 15px;
				margin-bottom: 2rem;
			}

			address {
				font-style: normal;
				line-height: 1.6;

				&::before {
					content: "";
					display: block;
					width: 90px;
					height: 20px;
					float: right;
				}
			}
		}

		.login-email {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.promos {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@media ( max-width: 640px ) {
			flex-direction: column;
		}

		> section {
			@extend %account-tile;

			@media ( max-width: 640px ) {
				flex-basis: auto;
			}
		}

		> section:nth-child(2n) {

			@media ( max-width: 640px ) {
				margin-left: 0;
			}
		}
	}

	section.auto-renew-notice {
		color: palette( slate, 500 );
		flex: 0 1 100%;

		.brand {
			display: inline-block;
			width: 36px;
			height: 24px;
			margin-left: 0.8rem;
			margin-right: 0.8rem;
			background-color: #fff;
			border-radius: 0.15rem;
		}

		h4 {
			@extend %account-heading;
			margin-bottom: 0.7rem;
			background: url( "../images/icon-renew.svg" ) no-repeat;
			background-size: 18px 18px;
			padding-left: 25px;
		}

		p {
			font-size: 0.68rem;
			margin-bottom: 0;

			@media ( max-width: 900px ) {
				font-size: 0.76rem;
			}
		}

		ul {
			padding: 0.8em 0 0;
			margin: 0;
		}

		li {
			margin-bottom: 0.5rem;
			list-style-type: none;

			a {
				font-size: 0.8rem;
				text-decoration: none;
			}
		}

		li:last-child {
			margin-bottom: 0;
		}

		.button {
			@extend %btn;
			background-color: palette( green );
			color: #fff;
			transition: all 0.2s $transition;

			&:hover {
				color: #fff;
				background-color: darken( palette( green ), 15% );
				border-color: darken( palette( green ), 15% );
			}
		}

		.paypal-email {
			white-space: nowrap;
		}

		.another-payment-method {
			font-size: 0.7rem;

			@media ( max-width: 900px ) {
				font-size: 0.76rem;
			}
		}

		.enable-automatic-billing-cta {
			float: none;
			font-weight: normal;
		}

		.card-number {
			white-space: nowrap;
		}
	}

	.account-credentials {
		ul {
			list-style-type: none;
			font-size: 15px;
			margin-bottom: 2rem;
			padding: 0;

			li {
				margin-bottom: 0.15rem;
			}
		}
	}

	section.cross-sell {
		min-height: 195px;
	}

	#stripe-credit-card-form {
		position: relative;
		margin-top: 1rem;

		label {
			font-size: 0.8rem;
			color: #666;
			display: block;
			margin-bottom: 0.2rem;
			padding-left: 0.05rem;
			font-size: 0.7rem;
			line-height: 1.42857;
			-moz-osx-font-smoothing: grayscale;
			color: #454748;

			@media ( max-width: 900px ) {
				font-size: 0.76rem;
			}
		}

		.test-credit-cards {
			font-size: 0.8rem;
			margin-bottom: 1rem;
			padding: 1rem;
			line-height: 1.4;
			background-color: darken( #eff4f5, 5% );
		}

		.form-row-last {
			margin-bottom: 1rem;
		}
	}

	.security {
		position: absolute;
		bottom: 0;
		right: 0;

		@media ( max-width: 1040px ) {
			position: relative;
			margin-top: 1.5rem;
		}

		svg {
			$scale: 0.05;
			width: 270px*$scale;
			height: 360px*$scale;
			margin-right: 0.4rem;
			vertical-align: baseline;
			fill: #999;
			position: relative;
			top: 1px;
		}

		h4 {
			font-size: 16px;
			color: #424242;
			margin-bottom: 0.2rem;
			font-weight: 700;
			line-height: 1;
			font-family: ff( "body" );
		}

		p {
			font-size: 14px;
			line-height: 1;
			color: #424242;
			text-align: right;
			font-style: italic;
			margin: 0 0 0.25rem;

			@media ( max-width: 1040px ) {
				text-align: left;
				margin: 0.5rem 0 1.5rem;
			}
		}

	}

	#stripe-card-number {
		width: 13rem;
	}

	#stripe-card-expiry {
		width: 6rem;
	}

	#stripe-card-cvc {
		width: 5rem;
	}

}

body.signin,
body.woocommerce-lost-password {
	.dbi-page-header {
		padding-bottom: 2.5rem;
	}

	.dbi-page-title {
		margin-bottom: 0;
	}
}

.woocommerce-MyAccount-navigation {
	display: none;
	max-width: $xlarge-max-width;
	margin: 0 auto;
	text-align: left;
	padding: 0 20px;

	ul {
		margin: 0;
		padding: 0;

		li:last-of-type {
			margin-right: 0;
		}
	}

	@include bp(md) {
		display: block;
	}
}

.woocommerce-MyAccount-navigation-link {
	list-style-type: none;
	display: inline-block;
	margin-right: 1.4rem;

	a:first-of-type {
		padding-left: 0;
	}

	a:first-of-type {
		margin: 0;
	}

	a {
		color: #898f9a;
		padding: 0.5rem 0 0.5rem 0;
		display: inline-block;
		font-size: 0.8rem;
		border-top-left-radius: 3px;
		border-top-right-radius: 3px;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.5px;

		&:hover {
			color: #ee3b71;
		}
	}

	&.is-active {
		a {
			color: #fff;
			border-bottom: 2px solid #ee3b71;

			&:hover {
				color: #fff;
			}

			&:focus {
				text-decoration: none;
			}

			&:active {
				text-decoration: none;
			}
		}
	}
}

.woocommerce-MyAccount-navigation-link--customer-logout {
	float: right;
	margin: 0;

	a {
		background-color: transparent;

		&:hover {
			background-color: transparent;
		}
	}
}

.woocommerce-EditAccountForm {
	max-width: 400px;

	legend {
		@extend h2;
		@extend %account-heading;
	}

	fieldset {
		margin-top: 2rem;
	}

	.woocommerce-Button {
		margin-top: 1rem;
	}
}

body.my-account-edit-address-billing {
	.woocommerce-MyAccount-content {
		form {
			max-width: 400px;
		}

		.required {
			border: 0;
		}

		.button {
			margin-top: 1rem;
		}
	}
}

.woocommerce-password-strength {
	margin: 1.5em 0 1.2em;
}

.woocommerce-password-hint {
	font-size: 100%;
	margin: 0 0 1.5em;
	max-width: 500px;
	display: block;
	color: palette( grey, 400 );
}

.form-edit-container {
	background-color: #fff;
	margin-bottom: 1.25rem;
	padding: 1.5rem 1.75rem 0;
	border-radius: 8px;
	box-shadow: -10px 10px 20px 0 rgba( 30, 30, 30, 0.05 );
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

body.woocommerce-edit-address h3,
body.woocommerce-page .dbi-form-title {
	font-family: Lato, sans-serif;
	font-size: 20px;
	font-weight: 700;
	line-height: 1rem;
	margin-bottom: 1.75rem;
	text-transform: none;
	color: #424242;
	letter-spacing: initial;
}
