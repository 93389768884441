.dbi-author {
	justify-content: center;
	background: #fff;
	border: 1px solid #E8E8E8;
	box-shadow: 0 1px 4px 0 rgba( 0, 0, 0, 0.05 );
	border-radius: 14px;
	padding: 32px;

	.dbi-author-bio {
		font-size: 0.7rem;
		color: palette( grey, 400 );
		line-height: 1.5;
		margin-bottom: 0;

		h4 {
			margin-bottom: 16px;
			line-height: 1;
		}

		p {
			margin-bottom: 16px;

			@include bp(max sm) {
				clear: left;
			}

		}

		a {
			color: #3f4e5a
		}

		&__name {
			font-size: .88rem;
			font-weight: bold;

			&.with-position:after {
				content: ',';
				font-weight: normal;
				text-decoration: none;
			}

		}

		&__title {
			color: palette( grey, 400 );
			font-size: .785rem;

			@include bp(max sm) {
				display: block;
				margin-top: 4px;
			}

		}

	}

	.dbi-author-social {
		a {
			display: inline-block;
			margin-right: 0.25rem;
			padding: 0.5em 0.75rem;
			font-size: 0.6rem;
			font-weight: 600;
			color: palette( grey, 400 );
			background: palette( grey, 800 );
			box-shadow: 1px 1px 0 #fff;
			border-radius: 0.15rem;
			text-decoration: none;

			&:hover {
				background: palette( grey, 700 );
			}
		}
	}

	.dbi-media__img {
		margin-right: 32px;

		@include bp(max sm) {
			margin-right: 16px;
		}

	}

	@include bp(max sm) {
		img {
			float: left;
			width: 4.5rem;
		}
	}
} // author
