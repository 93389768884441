.dbi-affiliates {
	max-width: $narrow-max-width;
	padding-top: 1.5rem;
	padding-bottom: 3rem;
	position: relative;
	left: 50%;
	transform: translateX( -50% );

	padding-left: 5%;
	padding-right: 5%;

	@include bp(md) {
		padding-top: 3rem;
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	.intro + p > span {
		display: block;
		color: palette( grey );
		font-size: 0.8rem;

		@include bp(xl) {
			display: inline;
			margin-left: 0.5rem;
		}
	}

	h2 {
		margin-bottom: 2rem; }

	.dbi-affiliates__faq__question {
		margin-bottom: 0.4rem;
		font-weight: bold;
	}
}

.spacer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;

	@include bp(sm) {
		flex-direction: row;
	}

	.dbi-btn {
		margin: 1rem 0 0;
		text-align: center;

		@include bp(sm) {
			margin: 0;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}
