.dbi-about {
	background: #f4f4f4;
	display: flex;
    flex-direction: column;
    padding: 4.5rem 1rem;
    justify-content: center;
    text-align: center;
    position: relative;
    margin: 0 auto;
    color: #3f4e5a;
    align-content: center;

	h2 {
		font-size: 1rem;
		line-height: 1.3;
		font-weight: 600;
		letter-spacing: 0;
	}

	.dbi-about-team {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		padding-top: 4.5rem;
		justify-content: center;
		text-align: center;
		max-width: $max-width;
		position: relative;
		left: 50%;
		transform: translateX( -50% );

		padding-left: 5%;
		padding-right: 5%;

		@include bp(1220px) {
			padding-left: 0;
			padding-right: 0;
		}

		.dbi-about__person {
			margin-bottom: 4.5rem;
			padding-left: $half-gutter;
			padding-right: $half-gutter;
			display: flex;
			flex-direction: column;
			align-items: center;

			flex: 1 1 100%;
			max-width: 100%;

			@include bp(sm) {
				flex: 1 1 50%;
				max-width: 50%;
			}

			@include bp(md) {
				flex: 1 1 33.333%;
				max-width: 33.333%;
			}

			@include bp(lg) {
				flex: 1 1 25%;
				max-width: 25%;
			}

			&.dbi-about__person--blend {
				img {
					width: 160px;
					height: 160px;
				}
			}

			img {
				width: 8rem;
				height: 8rem;
				// max-width: 62%;
				// height: auto;
				border-radius: 50%;
			}

			.dbi-about__person__bio {
				flex: 1;
				margin-bottom: 1rem;

				h4 {
					color: palette();
					margin: 0.5rem 0 0.5rem;
					font-family: ff( body );
					text-transform: uppercase;
					font-weight: bold;
					font-size: inherit;
				}

				p {
					margin: 0;
					color: palette( grey, 400 );
					line-height: 1.42857;
					font-size: 0.7rem;
				}
			}
		} // person
	}
}

.dbi-about__person__networks {
	align-self: flex-end;
	width: 100%;

	a {
		width: 1.8rem;
		height: 1.8rem;
		display: inline-block;
		position: relative;
		border-radius: 50%;
		transition: all 0.2s $transition;
		background: palette( grey, 600 );

		&::before {
			content: "";
			position: absolute;
			top: -0.5rem;
			left: -0.5rem;
			width: 2.8rem;
			height: 2.8rem;
			border: 2px solid palette();
			border-radius: 50%;
			opacity: 0;
			animation-iteration-count: 1;
			z-index: -1;
		}

		&:hover {
			&::before {
				animation: pulsate 0.5s forwards ease-out;
			}
		}

		&:not(:first-child) {
			margin-left: 1rem;
		}

		&.social-twitter {
			&::before {
				border-color: map-get( $social, twitter );
			}

			&:hover {
				background: map-get( $social, twitter );
			}
		}

		&.social-github {
			&::before {
				border-color: map-get( $social, github );
			}

			&:hover {
				background: map-get( $social, github );
			}
		}

		&.social-globe {
			&::before {
				border-color: map-get( $social, globe );
			}

			&:hover {
				background: map-get( $social, globe );
			}
		}

		.icon {
			width: 0.8rem;
			height: 0.8rem;
			fill: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate( -50%, -50% );

		}
	}
}

@keyframes pulsate {
	0% {
		opacity: 0;
		transform: scale( 0.4, 0.4 );
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		transform: scale( 1.2, 1.2 );
	}
}

.dbi-about-intro {
		display: flex;
    	flex-direction: column;
		flex-wrap: wrap;
		padding: 4.5rem 1rem;
		justify-content: center;
		text-align: center;
		position: relative;
		margin: 0 auto;
		color: #3f4e5a;
		align-content: center;
	a {
		color: #3f4e5a;
		font-weight: 600;
		&:hover {
			border-bottom: 1px dashed #3f4e5a;
			text-decoration: none;
		}
	}
	p {
		font-size: 0.75rem;
		max-width: 800px;
		text-align: left;
		line-height: 1.6;
		color: #3f4e5a;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
