.dbi-btn {
	//@include btn(palette(brand),#fff,palette(brand));
	@extend %btn;
	background-color: palette( grey );
	color: #fff;
	transition: all 0.2s $transition;
	margin: 0 0.25rem 0.25rem 0;

	&:hover {
		color: #fff;
	}

	.form-group & {
		margin: 0;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
	}

	.icon {
		fill: currentColor;
		width: 0.8rem;
		height: 0.8rem;
		vertical-align: top;
		position: relative;
		left: 0.5rem;
	}

	.icon-search {
		left: 0;
	}

	&.dbi-btn--lg {
		border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
		font-size: 0.75rem;
		padding: 0.75rem 2.5rem 0.75rem 2rem;

		.icon {
			left: 1rem;
			width: 1rem;
			height: 1rem;
		}
	}

	&.dbi-btn--full {
		border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
		font-size: 1rem;
		padding: 1.25rem 2.5rem 1.25rem 2rem;
		width: 100%;
		text-align: center;
		margin: 0;
	}

	&.dbi-btn--primary {
		background-color: palette( grey );
	}

	&.dbi-btn--info {
		background-color: palette( blue );
	}

	&.dbi-btn--success {
		background-color: palette( green );
	}

	&.dbi-btn--danger {
		background-color: palette();
	}

	&.dbi-btn--danger-dark {
		background-color: palette( brand, 200 );

		&:hover {
			background-color: lighten( palette( brand, 200 ), 15% );

			&[disabled] {
				background-color: palette( brand, 200 );
			}
		}
	}

	&.dbi-btn--outline {
		border-width: 2px;
		border-style: solid;
		background-color: transparent;
		padding-top: 0.6rem;
		padding-bottom: 0.6rem;

		&.dbi-btn--lg {
			padding-top: 0.9rem;
			padding-bottom: 0.9rem;
		}
	}

	&.dbi-btn--primary {
		border-color: palette( grey );

		&.dbi-btn--outline {
			color: palette( grey );
		}

		&:hover,
		&:focus,
		&:active {
			color: #fff;
			background-color: darken( palette( grey ), 15% );
			border-color: darken( palette( grey ), 15% );

			&[disabled] {
				background-color: palette( grey );
			}
		}
	}

	&.dbi-btn--mdb {
		background-color: palette( mdb, secondary );
		border-color: palette( mdb, secondary );
		color: #fff;

		&.dbi-btn--outline {
			background-color: transparent;
			color: palette( mdb, secondary );

			&:hover,
			&:focus,
			&:active {
				color: #fff;
			}
		}

		&:hover,
		&:focus,
		&:active {
			background-color: darken( palette( mdb, secondary ), 15% );
			border-color: darken( palette( mdb, secondary ), 15% );

			&[disabled] {
				background-color: palette( mdb, secondary );
			}
		}
	}

	&.dbi-btn--mdb-alt {
		background-color: palette( mdb, secondary-alt );
		border-color: palette( mdb, secondary-alt );
		color: #fff;

		&.dbi-btn--outline {
			background-color: transparent;
			color: palette( mdb, secondary-alt );

			&:hover,
			&:focus,
			&:active {
				color: #fff;
			}
		}

		&:hover,
		&:focus,
		&:active {
			background-color: darken( palette( mdb, secondary-alt ), 15% );
			border-color: darken( palette( mdb, secondary-alt ), 15% );

			&[disabled] {
				background-color: palette( mdb, secondary-alt );
			}
		}
	}

	&.dbi-btn--os3 {
		background-color: palette( os3, secondary );
		border-color: palette( os3, secondary );
		color: palette( grey, 100 );

		&.dbi-btn--outline {
			background-color: transparent;
			color: palette( os3, secondary );

			&:hover,
			&:focus,
			&:active {
				color: palette( grey, 100 );
			}
		}

		&:hover,
		&:focus,
		&:active {
			background-color: darken( palette( os3, secondary ), 15% );
			border-color: darken( palette( os3, secondary ), 15% );

			&[disabled] {
				background-color: palette( os3, secondary );
			}
		}
	}

	&.dbi-btn--os3-alt {
		background-color: palette( os3, secondary-alt );
		border-color: palette( os3, secondary-alt );
		color: palette( grey, 100 );

		&.dbi-btn--outline {
			background-color: transparent;
			color: palette( os3, secondary-alt );

			&:hover,
			&:focus,
			&:active {
				color: palette( grey, 100 );
			}
		}

		&:hover,
		&:focus,
		&:active {
			background-color: darken( palette( os3, secondary-alt ), 15% );
			border-color: darken( palette( os3, secondary-alt ), 15% );

			&[disabled] {
				background-color: palette( os3, secondary-alt );
			}
		}
	}

	&.dbi-btn--oses {
		background-color: palette( oses, secondary );
		border-color: palette( oses, secondary );
		color: palette( grey, 100 );

		&.dbi-btn--outline {
			background-color: transparent;
			color: palette( oses, secondary );

			&:hover,
			&:focus,
			&:active {
				color: palette( grey, 100 );
			}
		}

		&:hover,
		&:focus,
		&:active {
			background-color: darken( palette( oses, secondary ), 15% );
			border-color: darken( palette( oses, secondary ), 15% );

			&[disabled] {
				background-color: palette( oses, secondary );
			}
		}
	}

	&.dbi-btn--oses-alt {
		background-color: palette( oses, secondary-alt );
		border-color: palette( oses, secondary-alt );
		color: palette( grey, 100 );

		&.dbi-btn--outline {
			background-color: transparent;
			color: palette( oses, secondary-alt );

			&:hover,
			&:focus,
			&:active {
				color: palette( grey, 100 );
			}
		}

		&:hover,
		&:focus,
		&:active {
			background-color: darken( palette( oses, secondary-alt ), 15% );
			border-color: darken( palette( oses, secondary-alt ), 15% );

			&[disabled] {
				background-color: palette( oses, secondary-alt );
			}
		}
	}

	&.dbi-btn--info {
		border-color: palette( blue );
		color: #000;

		&.dbi-btn--outline {
			color: palette( blue );
		}

		&:hover,
		&:focus,
		&:active {
			background-color: darken( palette( blue ), 15% );
			border-color: darken( palette( blue ), 15% );

			&[disabled] {
				background-color: palette( blue );
			}
		}
	}

	&.dbi-btn--success {
		border-color: palette( green );

		&.dbi-btn--outline {
			color: palette( green );
		}

		&:hover,
		&:focus,
		&:active {
			color: #fff;
			background-color: darken( palette( green ), 15% );
			border-color: darken( palette( green ), 15% );

			&[disabled] {
				background-color: palette( green );
			}
		}
	}

	&.dbi-btn--danger {
		border-color: palette();

		&.dbi-btn--outline {
			color: palette();
		}

		&:hover,
		&:focus,
		&:active {
			color: #fff;
			background-color: darken( palette(), 15% );
			border-color: darken( palette(), 15% );

			&[disabled] {
				background-color: palette();
			}
		}
	}

	&.dbi-btn--disabled {
		cursor: auto;
		pointer-events: none;
		background-color: palette( grey, 200 );
	}

	&[disabled] {
		pointer-events: none;
		cursor: not-allowed;
		opacity: 0.65;
	}

}
