
$track-color: #fff !default;
$thumb-color: #005ef0 !default;

$thumb-radius: 12px !default;
$thumb-height: 24px !default;
$thumb-width: 24px !default;
$thumb-border-width: 0 !default;
$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 7px !default;
$track-border-width: 0 !default;
$track-border-color: #cfd8dc !default;

$track-radius: 5px !default;
$contrast: 5% !default;

$ie-bottom-track-color: darken( $track-color, $contrast ) !default;

@mixin shadow() {
	box-shadow: 0 4px 13px 5px rgba( 0, 0, 0, 0.1 );
}

@mixin track {
	cursor: default;
	height: $track-height;
	transition: all 0.2s ease;
	width: $track-width;
}

@mixin thumb {
	@include shadow();
	background: $thumb-color;
	border: $thumb-border-width solid $thumb-border-color;
	border-radius: $thumb-radius;
	box-sizing: border-box;
	cursor: pointer;
	height: $thumb-height;
	width: $thumb-width;
}

input[type="range"].mdb-range-input {
	-webkit-appearance: none;
	background: transparent;
	width: $track-width;
	display: flex;
	align-items: center;
	margin: 0;

	&::-moz-focus-outer {
		border: 0;
	}

	&:focus {
		outline: 0;

		&::-webkit-slider-runnable-track {
			background: #fff;
		}

		&::-ms-fill-lower {
			background: #63c2e6;
		}

		&::-ms-fill-upper {
			background: #fff;
		}
	}

	&::-webkit-slider-runnable-track {
		@include track;
		background: $track-color;
		border: none;
		border-radius: $track-radius;
	}

	&::-webkit-slider-thumb {
		@include thumb;
		-webkit-appearance: none;
		margin-top: ((-$track-border-width * 2 + $track-height) * 0.5 - $thumb-height * 0.5);
	}

	&::-moz-range-track {
		all: initial;

		@include track;
		background: $track-color;
		border: none;
		border-radius: $track-radius;
		height: $track-height * 0.5;
		flex: 1;
	}

	&::-moz-range-thumb {
		@include thumb;
	}

	&::-ms-track {
		@include track;
		background: transparent;
		border-color: transparent;
		border-width: ($thumb-height * 0.5) 0;
		color: transparent;
	}

	&::-ms-fill-lower {
		background: #63c2e6;
		border: none;
		border-radius: ($track-radius * 2);
	}

	&::-ms-fill-upper {
		background: $track-color;
		border: none;
		border-radius: ($track-radius * 2);
	}

	&::-ms-thumb {
		@include thumb;
		margin-top: $track-height * 0.25;
	}

	&:disabled {
		&::-webkit-slider-thumb,
		&::-moz-range-thumb,
		&::-ms-thumb,
		&::-webkit-slider-runnable-track,
		&::-ms-fill-lower,
		&::-ms-fill-upper {
			cursor: not-allowed;
		}
	}
}
