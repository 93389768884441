.dbi-accordion-container {
	position: relative;
}

%browser-button {
	content: " ";
	display: block;
	width: 12px;
	height: 12px;
	background-color: #aaa;
	position: absolute;
	border-radius: 50%;
}

.dbi-accordion {
	position: relative;
	padding-bottom: 4rem;

	.dbi-accordion__title {
		text-transform: uppercase;
		cursor: pointer;
		border-bottom: 1px solid palette( grey, 700 );
		line-height: 1.333;
		font-weight: 700;
		padding-bottom: 1rem;
		transition: all 0.2s $transition;
		position: relative;
		margin-top: 1rem;

		&::after {
			content: "+";
			font-weight: 100;
			font-size: 1.4rem;
			position: absolute;
			top: 0;
			line-height: 1rem;
			right: 5px;

			@include bp(1220px) {
				right: $gutter-width*2;
			}
		}

		&.open {
			&::after {
				content: "-";
			}
		}

	}

	.dbi-accordion__content {
		overflow: hidden;
		color: palette( grey, 400 );
		line-height: 1.71;
		height: 0;

		p {
			margin: 0;
		}

		img {
			margin: 1rem 0 0;

			@include bp(1060px) {
				display: none;
			}
		}

		&.open {
			height: auto;
		}
	}

	.dbi-accordion__content-text {
		padding: 1rem 0;
		border-bottom: 1px solid palette( grey, 700 );
	}
}

.dbi-accordion__screenshot {
	position: absolute;
	left: 50% + $half-gutter;
	top: 0;
	max-width: 900px;
	box-shadow: 0 0 1px 0 rgba( 0, 0, 0, 0.2 );
	border-radius: 5px;
	overflow: hidden;

	@include bp(1060px) {
		max-width: none;
	}

	&::before {
		content: " ";
		display: block;
		height: 35px;
		background: linear-gradient( to bottom, #eee, #ccc );
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
		z-index: 10;
	}

	&::after,
	span::before,
	span::after {
		@extend %browser-button;
		top: 12px;
		left: 12px;
	}

	span::before {
		left: 32px;
	}

	span::after {
		left: 52px;
	}

	div {
		background-color: #000;
	}

	.dbi-accordion__image {
		display: block;
		max-width: 900px;
		//transition: all .2s $transition;

		&.dbi-accordion__image--hidden {
			display: none;
		}
	}
}
