.dbi-symbol {
	font-size: 1rem;
	font-style: normal;
}

.dbi-symbol--cross {
	color: palette( error );
}

.dbi-symbol--tick {
	color: palette( green );
}

span.tada {
	&::before {
		content: "\01F389";
	}

	img {
		display: none !important;
	}
}
