.message {
	z-index: 2;
}

.dbi-welcome {
	background: #252628 url( ../images/bg_welcome.jpg ) center center no-repeat;
	background-size: cover;
	padding: 4rem 0 3rem;

	@include bp( 630px ) {
		padding: 7rem 0 8rem;
	}

	.snow {
		position: absolute;
		width: 100%;
		top: 40px;
		z-index: 1;
	}

	&__message {
		max-width: 380px;
		margin: 0 auto 2rem;
		font-size: 1.4rem;
		opacity: 0;
		animation-name: fadeIn;
		animation-duration: 1s;
		animation-delay: 0.5s;
		animation-fill-mode: forwards;
		text-align: center;

		@include bp( 630px ) {
			font-size: 1.4rem;
			max-width: 550px;
			margin: 0 auto;
			line-height: 1.3;
			font-weight: 300;
			text-align: left;
		}

		.icon {
			width: 50px;
			height: 61px;
			fill: palette( brand );
			margin: 1rem auto;

			@include bp( 630px ) {
				float: left;
				margin: 17px 0;
				width: 70px;
				height: 86px;
			}

			@include bp( 900px ) {
				margin: 23px 0;
			}
		}

		//If Xmas Logo
		.xmas {
			width: 62px;
			height: 68px;
			fill: palette( brand );
			margin: 1rem auto;

			@include bp( 630px ) {
				float: left;
				margin: 10px 0;
				width: 87px;
				height: 96px;
			}

			@include bp( 900px ) {
				margin: 17px 0;
			}
		}

		span {
			display: block;
			color: palette( brand );
			max-width: 350px;
			margin: 0 auto;

			@include bp( 630px ) {
				padding-left: 140px;
				max-width: 530px;
				margin: 0;
			}

			@include bp( 768px ) {
				max-width: none;
			}
		}
	}
}

.dbi-welcome-signup {
	position: relative;
	padding: 0;
	margin: -25px 0 0;

	@include bp( 630px ) {
		margin: -50px 0 0;
	}

	label {
		display: none;
	}

	&__clip {
		background: #d92a62 url( "../images/bg-pink-texture.jpg" ) center 0 no-repeat;
		background-size: cover;
		clip-path: polygon( 0 25px, 100% 0, 100% 100%, 0% 100% );
		padding: 4rem 0 3.5rem;
		position: relative;
		z-index: 10;

		@include bp( 630px ) {
			padding: 5rem 0 3rem;
			clip-path: polygon( 0 50px, 100% 0, 100% 100%, 0% 100% );
		}
	}

	&__envelope {
		width: 134px;
		height: 80px;
		position: absolute;
		top: -25px;
		left: 50%;
		margin: 0 0 0 -67px;
		box-shadow: 0 2px 5px 1px rgba( 0, 0, 0, 0.1 );
		display: none;
		z-index: 20;

		@include bp( 630px ) {
			display: block;
		}
	}

	&__title {
		color: #fff;
		font-size: 1.4rem;
		font-weight: 500;
		margin: 0 auto 20px;
		width: 100%;
		max-width: 380px;
		text-align: center;
		letter-spacing: 0.01rem;

		@include bp( 380px ) {
			padding: 0 25px;
		}

		@include bp( 630px ) {
			margin: 20px auto;
			font-size: 1.6rem;
			padding: 0;
		}

		@include bp( 800px ) {
			float: left;
			width: 48%;
			max-width: none;
			text-align: right;
			margin: 20px 0;
		}

		@include bp( 1220px ) {
			font-size: 1.8rem;
			margin: 16px 0 0;
		}
	}

	&__form {
		width: 100%;
		max-width: 380px;
		box-shadow: 0 2px 5px 1px rgba( 0, 0, 0, 0.1 );
		border-radius: 0.15rem;
		margin: 0 auto;

		@include bp( 800px ) {
			float: right;
			width: 48%;
			margin: 0;
		}
	}

	input[type="text"],
	input[type="email"] {
		border: none;
		display: block;
		width: 100%;
		margin: 0;
		height: 60px;
		padding: 0.5rem 1.2rem;
		border-radius: 0;
		font-size: 0.75rem;
		transition: all 0.15s $transition;

		&.dbi-welcome-signup__input-email {
			border-radius: 0.15rem 0.15rem 0 0;
		}

		&.dbi-welcome-signup__input-first-name {
			border-top: 1px solid palette( grey, 800 );
		}
	}

	&__submit {
		height: 60px;
		line-height: 56px;
		white-space: nowrap;
		text-transform: uppercase;
		color: #fff;
		font-size: 0.6rem;
		padding: 0 1.2rem;
		background: palette( brand );
		background: linear-gradient( to right, palette( brand ) 0%, darken( palette( brand ), 15% ) 100% );
		border: none;
		border-bottom: 4px solid darken( palette( brand ), 18% );
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		border-radius: 0 0 0.15rem 0.15rem;
		text-align: left;
		font-weight: 700;
		letter-spacing: 0.05rem;

		.icon {
			fill: #fff;
			width: 1.4rem;
			height: 1.4rem;
			margin-right: -0.6rem;
		}

		&:focus {
			outline: none;
		}
	}

	&__form:not(.expanded) {
		.dbi-welcome-signup__input-hidden {
			height: 0;
			visibility: hidden;
			padding-top: 0;
			padding-bottom: 0;
			border-width: 0;
		}
	}

	.dbi__signup-errors {
		float: right;
		width: 48%;
		max-width: 380px;
	}
}

.dbi-welcome-latest-post {
	padding: 0;
	background: #e13067 url( "../images/bg-pink-texture.jpg" ) center center no-repeat;
	background-size: cover;
	text-align: center;
	font-family: ff( "headings" );
	font-size: 0.8rem;
	font-weight: 500;

	&__inner {
		background: rgba( palette( brand ), 0.25 );
		padding: 1.2rem 0;

		@include bp( md ) {
			padding: 0.7rem 0;
		}
	}

	p {
		margin: 0;
		color: palette( brand, 100 );

		@include bp( md ) {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		span {
			color: #fff;
		}
	}

	&__post-title {
		color: #fff;
		text-decoration: underline;

		&:hover,
		&:active,
		&:focus {
			color: palette( brand, 100 );
		}
	}
}

#gridOverlay {
	pointer-events: none;
}
