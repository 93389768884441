.dbi-contact {
	padding: 1.5rem 5%;
	display: flex;
	flex-wrap: wrap;
	position: relative;
	z-index: 10;
	left: 50%;
	width: 100%;
	max-width: $max-width;
	transform: translateX( -50% );

	@include bp(md) {
		padding: 2.5rem 5%;
	}

	@include bp(xl) {
		padding-left: 0;
		padding-right: 0;
	}

	.dbi-contact__details {
		flex: 0 1 100%;
		max-width: 100%;
		padding-right: $half-gutter;
		padding-left: $half-gutter;

		@include bp(md) {
			flex: 0 1 50%;
			max-width: 50%;
			padding-left: $gutter-width;
			padding-right: $gutter-width;
		}

		@include bp(lg) {
			padding-left: $column-width;

			&:first-child {
				padding-left: $column-width*2;
			}
		}

	}
}
