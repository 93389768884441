.dbi-site__nav {
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%;
	transform: translateY( -100% );
	background-color: rgba( black, 0.9 );
	z-index: 1001;
	height: calc( 100vh - 2.5rem );
	overflow-y: scroll;
	overflow-scrolling: touch;
	-webkit-overflow-scrolling: touch;

	&.opened {
		@include bp(max mx) {
			transform: translateY( 50px );
		}
	}

	@include bp(mx) {
		position: relative !important;
		width: 100%;
		transform: none;
		background-color: transparent;
		min-height: 0;
		height: inherit;
		overflow-y: inherit;
	}

	.icon-logo {
		width: 1.1rem;
		height: 1.2rem;
		vertical-align: middle;
		position: relative;
		top: -2px;
		margin: 0 0.5rem;
		fill: palette();
		transition: fill 0.2s $transition;
	}

	&.dbi-site__nav--faded {
		@include bp(mx) {
			.dbi-menu__item:not(.dbi-menu__item--logo) a {
				opacity: 0.5;
				font-size: 0.54rem;
				line-height: 2rem;
			}

			.icon-logo {
				width: 0.85rem;
				height: 0.95rem;
				line-height: 2rem;
			}

			&:hover {
				.dbi-menu__item:not(.dbi-menu__item--logo) a {
					opacity: 1;
					line-height: 2rem;
				}
			}

			&:not(:hover) .dbi-menu {
				.icon-logo {
					fill: palette( grey, 1000 );
					line-height: 2rem;
				}

				.dbi-menu__item a,
				span.toggle-products {
					color: palette( grey, 500 );
					line-height: 2rem;
				}
			}

			.toggle-products {
				opacity: 0.2;
			}

			&:hover {
				.toggle-products {
					opacity: 1;
				}

				.dbi-menu {
					.icon-logo {
						line-height: 2rem;
					}

					.dbi-menu__item a,
					span.toggle-products {
						line-height: 2rem;
					}
				}
			}
		}
	}

	&--greyscale {
		@include bp(mx) {
			&:not(:hover) .dbi-menu {
				.icon-logo {
					fill: palette( grey, 1000 );
				}

				.dbi-menu__item a,
				span.toggle-products {
					color: palette( grey, 500 );
				}
			}
		}
	}
}

body.has-product-category,
body[class*="wp-offload-media"]:not(.woocommerce-page),
body[class*="wp-migrate-db-pro"]:not(.woocommerce-page),
body[class*="wp-offload-ses"]:not(.woocommerce-page) {
	.dbi-menu .dbi-menu__item.dbi-menu__item--parent-product.current-page-item {
		@include bp(mx) {
			display: none;
		}
	}

	.dbi-site__header {
		@include bp(mx) {
			height: 2rem;
		}
	}

	.dbi-menu .dbi-menu__item.dbi-menu__item--parent-product {
		@include bp(mx) {
			display: flex;
		}
	}
}

// nav

.dbi-menu {
	position: relative;
	margin: 0 auto;
	padding: 0;
	list-style: none;
	width: 100%;
	max-width: 1048px;

	@include bp(1220px) {
		max-width: 1600px;
	}

	.product-title-container {
		display: flex;

		h1 {
			margin: 0;
		}
	}

	.dbi-menu__item {
		margin: 0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		padding-right: 1rem;
		border-bottom: 1px solid palette(grey, 200);
		position: relative;
		cursor: pointer;

		.section_link {
			@include bp(mx) {
				line-height: 1rem;
			}
		}

		@include bp(mx) {
			display: inline-block;
			padding-right: 0;
			border-bottom: 0;
		}

		a,
		span.toggle-products {
			font-size: 0.62rem;
			display: block;
			line-height: 2.5rem;
			margin: 0 0.5rem;
			color: #fff;
			text-transform: uppercase;
			font-family: ff( headings );
			letter-spacing: 0.025rem;
			transition: all 0.2s $transition;
			cursor: pointer;

			&:hover,
			&:focus,
			&:active {
				text-decoration: none;
				color: palette();
			}

			@include bp(max mx) {
				margin: 0 1rem;
			}
		}

		&.dbi-menu__item--logo a {
			margin-left: 0; }

		&.dbi-menu__item--account a {
			margin-right: 0; }

		&.dbi-menu__item--logo {
			display: none;

			@include bp(mx) {
				display: inline-block;
			}
		}

		&.dbi-menu__item--about {
			margin-left: auto; }

		&.dbi-menu__item--button {
			margin-right: auto;

			@include bp(mx) {
				margin-right: 0;
				margin-left: auto;
			}

			a {
				line-height: 1;

				&:not(.dbi-btn):hover {
					color: #fff;
				}
			}
		}

		&.dbi-menu__item--products {
			display: none;

			a {
				&:focus,
				&:active {
					color: inherit;
				}

				&.active {
					color: palette();
				}
			}

			@include bp(mx) {
				display: inline-block;
			}

			.dbi-menu__sub-menu {
				display: none;
				background: #fff;
				box-shadow: 0 2px 40px 0 rgba( 0, 0, 0, 0.5 );
				border-radius: 2px;
				padding: 0;
				min-width: 340px;
				margin-left: 0.5rem;

				li {
					font-size: 0.61rem;
					text-transform: uppercase;
					font-family: ff( headings );
					letter-spacing: 0.025rem;
					padding: 0 8px;
					color: #35414b;
					display: block;

					&:first-child {
						border-bottom: 1px solid rgba( 0, 0, 0, 0.14 );
						color: #777;
						padding: 17px 22px 14px;
					}

					&:nth-child(2) {
						padding-top: 8px;
					}

					&:last-child {
						padding-bottom: 8px;
					}

					a {
						color: #35414b;
						opacity: 1;
						text-transform: uppercase;
						padding: 12px;
						margin: 0;
						line-height: 1;
						font-weight: 600;
						transition: all 0.2s $transition;
						border: 1px solid transparent;
						position: relative;

						&:hover {
							color: #e7396f;
							background: rgba( 238, 59, 114, 0.05 );
							border: 1px solid #ed3b72;
							border-radius: 2px;
							transition: all 0.2s $transition;
						}

						span {
							vertical-align: middle;
						}

						span.icon {
							display: inline-block;
							margin-right: 14px;

							svg {
								width: 1.65rem;
								height: 1.65rem;
								box-shadow: 0 2px 2px 0 rgba( 0, 0, 0, 0.25 );
								border-radius: 100%;
							}
						}

						span.new {
							color: #ee3b72;
							font-size: 11px;
							letter-spacing: 0.55px;
							text-align: center;
							border: 2px solid #ee3b72;
							border-radius: 2px;
							position: absolute;
							right: 0.5rem;
							top: 50%;
							transform: translate( 0, -50% );
							padding: 2px 6px;
						}
					}
				}
			}

			&:hover > .dbi-menu__sub-menu {
				display: block;
			}
		}

		&.dbi-menu__item--parent-product {
			display: flex;

			@include bp(mx) {
				display: none;
			}
		}

		&.dbi-menu__item--product {
			display: none;
			margin-right: 0.5rem;

			@include bp(mx) {
				display: inline-block;
			}

			a {
				line-height: 1;
			}

			.icon {
				fill: #fff;
				width: 2.5rem;
				height: 2.5rem;
				transition: fill 0.2s $transition;

				&:hover {
					fill: palette();
				}
			}

			a.product-title {
				font-size: 0.875rem;
				font-weight: 700;
				margin-bottom: 0.3rem;
			}

			.mdb {
				color: palette( mdb, secondary-alt );

				&:hover {
					color: #95cde2 !important;
				}
			}

			.oses {
				color: palette( oses, secondary-alt );

				&:hover {
					color: #e9dfae !important;
				}
			}

			.os3 {
				color: palette( os3, secondary-alt );

				&:hover {
					color: #ebb589 !important;
				}
			}
		}

		&.open {
			>.nav-arrow {
				transform: rotate(180deg);
			}
			>.dbi-menu__sub-menu {
				display: block !important;

				@include bp(mx) {
					display: none !important;
				}
			}
		}
	}
	// item

	.nav-arrow {
		padding: 0.7rem;
		display: inline-block;
		color: #fff;
		transition: transform 0.2s ease;
		margin: 0 -0.7rem 0 auto;
		vertical-align: middle;
		cursor: pointer;

		svg {
			fill: currentColor;
			width: 0.85rem;
			height: 0.85rem;
			vertical-align: middle;
			pointer-events: none;
		}

		&:hover {
			color: palette();

			svg {
				fill: palette();
			}
		}

		@include bp(mx) {
			display: none;
		}
	}

	&.dbi-menu--product {
		position: relative;
		left: 50%;
		width: 100%;
		max-width: 1600px;
		transform: translateX( -50% );
		//display: flex;
		padding: 2rem 0.8rem;
		margin-left: 0;
		flex-direction: column;
		z-index: 1000;
		transition: none;
		order: 2;
		display: none;
		justify-content: flex-start;
		will-change: position, padding, transform;

		.plugin-intro & {
			display: flex;
		}

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			background: rgba( black, 0.8 );
			left: 100%;
			width: 99rem;
			opacity: 0;
			visibility: hidden;
			transition: none;
			height: 100%;
		}

		&::after {
			left: auto;
			right: 100%;
		}

		@include bp(mx) {
			display: flex;
			position: absolute;
			top: 2.5rem;
			flex-direction: row;
			align-items: center;
			padding-top: 1rem;
			padding-bottom: 1rem;

			&.sticky {
				transition: background-color 0.2s $transition, padding 0.2s $transition 0.4s;
				position: fixed;
				top: 0;
				background-color: rgba( black, 0.8 );
				padding-top: 0.5rem;
				padding-bottom: 0.5rem;

				&::before,
				&::after {
					opacity: 1;
					visibility: visible;
					transition: opacity 0.2s $transition;
				}
			}

			.admin-bar &.sticky {
				top: 32px;
			}

			.dbi-menu__item:first-of-type a {
				margin-left: 0; }
		}

		@include bp(lg) {
			padding-top: 1rem;
			padding-bottom: 1rem;
			padding-left: 2rem;
			padding-right: 2rem;
		}

		.dbi-menu__item {
			a {
				white-space: nowrap;
			}
		}

		.dbi-menu__item--button {
			display: none;

			@include bp(lg) {
				display: inline-flex;
				align-self: center;
				display: inline-flex;

				a {
					margin-right: 0;
					align-self: center;
					display: inline-flex;
					line-height: 1.2;
				}
			}
		}

		//Feature Menu
		.dbi-menu__item#menu_features{

			.submenu-container{
				position: absolute;
				padding-top: 2.25rem;
				left: -999px;

				.dbi-submenu{

					opacity: 0;
					padding: .696rem .174rem;
					background: #ffffff;
					box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
					border-radius: .261rem;
					list-style: none;

					.dbi-submenu__item{
							opacity: 0.2;
							border-color: transparent;
							border-radius: .261rem;
							border-width: 1px;
							border-style: solid;
							cursor: pointer;
							padding: .522rem 1.043rem;

							a{

								margin-right: 0;
    							width: -webkit-max-content;
    							width: -moz-max-content;
    							width: max-content;
    							display: -webkit-box;
    							display: -ms-flexbox;
    							display: flex;
    							-webkit-box-align: center;
    							-ms-flex-align: center;
    							align-items: center;
    							line-height: 1;


								span:not(:first-child){
									margin-left: 1.043rem
								}
								//text-align: left;
							}
							span{
								font-weight: 500;
    							font-size: .696rem;
    							color: rgba(52,59,72,1);
								letter-spacing: 0;
    							text-transform: none;
								color : rgba(52,59,72,1);
								font-feature-settings:normal;

								text-transform: none;
								&.icon {
									img{
										border-radius: .261rem;
    									padding: .522rem;
    									background-color: #dae8ff;
    									display: inline-block;
									}
								}
							}
						&:hover{
							background-color: rgba(239,246,255,1);
							span {
								color: rgba(9,104,255,1);
								img {
									background-color: rgba(191,219,254,1);
								}
							}
						}
						}
				}
			}

			&:hover{
				.submenu-container{
					left : initial;
				}
			}

			&:hover, .submenu-container:hover{
				.dbi-submenu{
					opacity: 1;
					transition : opacity .3s cubic-bezier(.4,0,.2,1);
					.dbi-submenu__item{
						opacity: 1;
						transition : opacity .3s cubic-bezier(.4,0,.2,1);
					}

				}
			}
		}
	}
	// product

	&.dbi-menu--migrate {
		.dbi-menu__item.current-page-item > a {
			color: palette( mdb, secondary-alt );
		}

		.dbi-menu__item a:not(.dbi-btn) {
			&:hover,
			&:focus,
			&:active {
				color: palette( mdb, secondary-alt );
			}
		}
		.dbi-menu__item#menu_features{
			.submenu-container{
				&:hover + a.section_link{
					color: palette( mdb, secondary-alt );
				}
			}
		}
	}

	&.dbi-menu--offload {
		.dbi-menu__item.current-page-item > a {
			color: palette( os3, secondary-alt );
		}

		.dbi-menu__item a:not(.dbi-btn) {
			&:hover,
			&:focus,
			&:active {
				color: palette( os3, secondary-alt );
			}
		}
	}

	&.dbi-menu--offload-ses {
		.dbi-menu__item.current-page-item > a {
			color: palette( oses, secondary-alt );
		}

		.dbi-menu__item a:not(.dbi-btn) {
			&:hover,
			&:focus,
			&:active {
				color: palette( oses, secondary-alt );
			}
		}
	}

	.dbi-menu__sub-menu {
		display: none;
		padding-left: 1rem;
		margin-bottom: 0.5rem;

		li {
			border: 0;
		}

		a {
			display: block;
			text-transform: capitalize;
			line-height: 1.2;
			padding: 0.25rem;
			margin: 0;

			@include bp(max mx) {
				font-size: 0.9rem;
			}
		}
	&.level-2{
		padding-left: 0.75rem;
		padding-right: 0;
	}

	}

	@include bp(mx) {
		.dbi-menu__item--home {
			display: none;
		}
	}
}

// menu

.dbi-site__header {
	.nav-toggle {
		order: 1;
		margin: 0;
		padding: 0.125rem 0 0.125rem 1rem;
		background-color: transparent;
		border: 1px solid transparent;
		display: inline-block;
		z-index: 100;
		cursor: pointer;
		//width: 2.5rem;
		height: 2.5rem;
		line-height: 2.5rem;
		position: relative;
		vertical-align: middle;
		text-transform: uppercase;
		color: #fff;
		text-decoration: none;
		white-space: nowrap;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			color: palette();
		}

		.icon {
			margin-left: -8px;
			width: 2rem;
			height: 2rem;
			fill: currentColor;
			vertical-align: middle;
		}

		span {
			vertical-align: middle;
		}

		@include bp(lg) {
			line-height: 2;
		}

		@include bp(mx) {
			display: none;
		}
	}

	.nav-collapse ul {
		margin: 0 auto;
		padding: 0 2rem;
		width: 100%;
		display: block;
		list-style: none;

		@media ( max-width: 1636px ) {
			padding: 0 1.6rem;
		}

		@media ( max-width: 1614px ) {
			padding: 0 1.1rem;
		}

		@media ( max-width: 1023px ) {
			padding: 0 0.01rem;
		}

		@media ( min-width: 900px ) and ( max-width: $xlarge-max-width ) {
			max-width: $xlarge-max-width;
		}

		@media ( min-width: $xlarge-max-width ) and ( max-width: 1220px ) {
			max-width: $xlarge-max-width;
		}

		&.dbi-menu__sub-menu {
			display: none;
			padding-left: 1.5rem;
			margin-bottom: 0.5rem;
		}
	}

	@include bp(mx) {
		.dbi-menu,
		.nav-collapse ul {
			display: flex;
		}
	}

	.js .nav-collapse {
		//clip: rect(0 0 0 0);
		//max-height: 0;
		position: absolute;
		display: block;
		overflow: hidden;
		zoom: 1;
	}

	html.js .nav-collapse.opened {
		max-height: 9999px !important;
	}

	.disable-pointer-events {
		pointer-events: none !important;
	}

	.nav-toggle {
		-webkit-tap-highlight-color: rgba( 0, 0, 0, 0 );
		-webkit-touch-callout: none;
		user-select: none;
		margin-left: auto;
	}

	@include bp(sm) {
		.js .nav-collapse {
			position: relative;
			clip: none;
		}

		.js .nav-collapse.closed {
			max-height: none;
			position: relative;
			overflow: visible;
		}
	}
}
