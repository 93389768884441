.dbi-page {
	max-width: $narrow-max-width;
	position: relative;
	left: 50%;
	transform: translateX( -50% );
	display: flex;
	flex-wrap: wrap;
	padding-left: 5%;
	padding-right: 5%;
	margin-top: 1.5rem;
	line-height: 1.714;
	-webkit-font-smoothing: antialiased;

	@include bp(md) {
		margin: 2.5rem 0 4rem;

		p:last-child {
			margin-bottom: 0;
		}
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	.entry-content {
		img {
			max-width: 100%;
			height: auto;
		}
	}
}

.dbi-page--full {
	max-width: 100%;
	padding: 0;
}

.dbi-page--padded {
	margin-bottom: 4rem;
}
