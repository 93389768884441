/**
 * Owl Carousel v2.1.6
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;

	/* position relative and z-index fix webkit rendering fonts issue */
	position: relative;
	z-index: 1;
}

.owl-carousel .owl-stage {
	position: relative;
	-ms-touch-action: pan-y;
}

.owl-carousel .owl-stage::after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0;
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;

	/* fix for flashing background */
	-webkit-transform: translate3d( 0, 0, 0 );
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
	display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	cursor: pointer;
	cursor: hand;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-loaded {
	display: block;
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block;
}

.owl-carousel.owl-hidden {
	opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
	display: none;
}

.owl-carousel.owl-drag .owl-item {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: grab;
}

.owl-carousel.owl-rtl {
	direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
	float: right;
}

/* No Js */
.no-js .owl-carousel {
	display: block;
}

.owl-dots {
	margin: 0;
	text-align: center;
	-webkit-tap-highlight-color: transparent;
}

.owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
	*display: inline;
	padding-top: 1.4rem;
}

.owl-dots .owl-dot span {
	width: 20px;
	height: 4px;
	margin: 0 0.25rem 0 0;
	background-color: palette( slate, 300 );
	display: block;
	-webkit-backface-visibility: visible;
	transition: opacity 200ms ease;
	opacity: 0.4;
}

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot.active:hover span {
	opacity: 1;
}

.owl-dots .owl-dot:hover span {
	opacity: 0.8;
}
