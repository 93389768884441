.dbi-our-products {
	background-color: #18171d;
	padding: 3rem 0;

	@include bp(sm) {
		padding: 6rem 0;
	}

	&__header {
		margin: 0 auto 3rem auto;
	}

	&__heading {
		font-weight: 400;
		font-size: 1rem;

		/*Updated Products Title Styling
		text-align: center;
		color: #555268;
		font-size: 50px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 50px;
		text-transform: none;*/
	}

	&__products {
		display: flex;
		flex-direction: column;
	}

}
