#dbi-whats-new {
	background: #f4f4f4;

	.whats-new-content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		text-align: center;
		max-width: $max-width;
		position: relative;
		left: 50%;
		transform: translateX( -50% );
		background: #f4f4f4;
		padding: 2.75rem 5% 4.5rem 5%;
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	.release-row:last-of-type {
		padding-bottom: 0;
	}

	.release-row {
		display: flex;
		flex-direction: row;
		align-items: baseline;
		width: 100%;
		border-left: 4px solid #e1e1e1;
		padding: 0 0 2rem;

		@media ( max-width: 640px ) {
			border-left: 0;
			flex-direction: column;
		}

		.release-table {
			width: 100%;
		}

		.timeline {
			display: flex;
			align-items: center;

			@media ( max-width: 640px ) {
				width: 100%;
			}

			.dot {
				min-width: 16px;
				min-height: 16px;
				display: flex;
				border-radius: 50%;
				background: #919191;
				border: 3px solid #f4f4f4;
				margin-right: 0.75rem;
				margin-left: -10px;
				align-items: center;
				justify-content: center;

				@media ( max-width: 640px ) {
					display: none;
				}

				&--major {
					@extend .dot;
					min-width: 36px;
					min-height: 36px;
					margin-left: -20px;
					margin-right: 8px;
					background: #ed3a71;
				}

				svg {
					height: 18px;
					width: 18px;
					fill: white;
				}
			}

			.date {
				background: #e9e8e8;
				border-radius: 4px;
				color: #405260;
				font-size: 13px;
				font-weight: 500;
				padding: 0.25rem;
				margin-right: 1.25rem;
				min-width: 5rem;
				text-decoration: none;
				position: relative;

				&:hover::before {
					opacity: 1;
				}

				&::before {
					content: url( "data:image/svg+xml,%3Csvg class='anchor-hash-link__icon' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18'%3E%3Cpath d='M16.746 7.177l-4.101 4.101c-1.773 1.773-4.45 1.473-5.697.226-.378-.378-.275-1.093.229-1.596.433-.433.904-.588 1.594-.228.69.361 1.511.315 2.051-.224l4.101-4.101a1.61 1.61 0 0 0-2.278-2.278l-2.199 2.198a5.148 5.148 0 0 0-2.311-.545 5.18 5.18 0 0 0-.865.077l3.553-3.552a4.187 4.187 0 1 1 5.923 5.922zm-7.108 6.319a5.154 5.154 0 0 1-2.312-.544l-1.971 1.972a1.613 1.613 0 0 1-2.278 0 1.613 1.613 0 0 1 0-2.278l3.874-3.874c.54-.54 1.36-.586 2.05-.224.69.36 1.162.205 1.595-.228.504-.506.607-1.22.229-1.597-1.247-1.248-3.924-1.548-5.697.225l-3.874 3.874a4.187 4.187 0 1 0 5.923 5.923l3.326-3.327a5.068 5.068 0 0 1-.865.078z'%3E%3C/path%3E%3C/svg%3E" );
					position: absolute;
					width: 12px;
					height: 12px;
					filter: invert( 66% ) sepia( 7% ) saturate( 21% ) hue-rotate( 314deg ) brightness( 86% ) contrast( 90% );
					right: -18px;
					top: 7px;
					opacity: 0;
					transition: opacity 200ms;
					will-change: opacity;
				}

				@media ( max-width: 640px ) {
					margin: 0.5rem 0 1rem 0;
				}
			}
		}

		.container {
			background: #fff;
			border: 1px solid #eff0f0;
			box-shadow: 0 1px 4px 0 rgba( 0, 0, 0, 0.05 );
			border-radius: 4px;
			display: flex;
			flex-direction: column;
			align-items: center;
			font-family: ff( "body" );
			padding: 0;
			width: 100%;
			margin-top: -1px;

			h3 {
				font-size: 16px;
				color: #3f4e5a;
				font-family: ff( "body" );
				letter-spacing: 0;
				font-weight: 700;
				line-height: 1.4;
				flex: 1.2;
			}

			p {
				font-size: 15px;
				color: #3f4e5a;
				line-height: 1.5;
				margin: 0;
				flex: 2;
			}

			&--major {
				@extend .container;
				flex-direction: column;
				text-align: left;
				align-items: flex-start;

				&:not(:only-of-type) {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
				}

				+ .container {
					border-top-left-radius: 0;
					border-top-right-radius: 0;
				}

				.post-preview {
					padding: 1rem;
					cursor: auto;
				}

				h2 {
					font-family: ff( "headings" );
					font-size: 22px;
					color: #3f4e5a;
					letter-spacing: -0.2px;
					line-height: 30px;
					text-transform: none;
					margin-bottom: 1rem;

					a {
						color: #3f4e5a;

						&:hover {
							color: #ee3b71;
							text-decoration: none;
						}

						&:focus {
							text-decoration: none;
						}
					}
				}

				p {
					margin-bottom: 1.2rem;
				}

				a p {
					margin-bottom: 0;
				}
			}

			svg {
				min-width: 14px;
				min-height: 14px;
				width: 14px;
				height: 14px;
				display: flex;
				margin-left: auto;
			}
		}
	}

	.changelog-filters {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		width: 100%;

		> div {
			display: flex;
			flex-direction: row;
			align-items: center;

			&:last-child {
				margin-bottom: 2.75rem;
			}

			+ div {
				margin-top: 20px;
			}

			@media ( max-width: 768px ) {
				flex-direction: column;
				margin: 0 auto 2rem 0;
				align-items: baseline;
			}
		}

		p {
			font-size: 0.575rem;
			font-weight: 600;
			color: #7182a3;
			letter-spacing: 0;
			text-transform: uppercase;
			margin: 0 1.2rem 0 0;
		}

		.checkboxes {
			display: flex;
			align-items: center;

			@media ( max-width: 520px ) {
				margin-top: 1rem;
			}

			.checkbox {
				display: flex;
				cursor: pointer;
				position: relative;
				align-items: center;
				margin-left: 1rem;
			}

			.checkbox:first-of-type {
				margin-left: 0;
			}

			.checkbox > span {
				margin-left: 0.4rem;
				font-size: 0.6375rem;
				color: #333e53;
			}

			.example {
				color: #737d91;
			}

			.checkbox > input {
				height: 18px;
				width: 18px;
				appearance: none;
				-moz-appearance: none;
				-webkit-appearance: none;
				border: 1px solid #b3bbc3;
				border-radius: 4px;
				outline: none;
				background-color: #fff;
				cursor: pointer;
			}

			.checkbox > input:checked {
				border: 1px solid #ed3a71;
				background-color: #ed3a71;
			}

			.checkbox > input:checked + span::before {
				content: "";
				position: absolute;
				left: 4px;
				top: 9px;
				background: white;
				width: 2.4px;
				height: 2.4px;
				box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
				transform: rotate( 45deg );
			}

			.checkbox > input:active {
				border: 2px solid #737d91;
			}
		}
	}
}

.release-notes {
	display: flex;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s cubic-bezier( 0, 0, 0.58, 1 );
	width: 100%;
	flex-direction: column;
	will-change: max-height;
}

.release-notes-expand {
	width: 100%;
	display: flex;
	flex-direction: row;
	text-align: left;
	align-items: center;
	padding: 0.765rem 1rem;
	cursor: pointer;

	p {
		margin: 0 !important;
	}

	&--major {
		@extend .release-notes-expand;
		padding: 0.765rem 1rem !important;
	}

	.icon {
		transition: all 200ms;
	}

	&.active {
		.icon {
			transform: rotate( 180deg );
		}
	}
}

.expanded-content {
	padding: 0.8rem 1rem;
	text-align: left;

	.type-label:first-of-type {
		margin-top: 0.25rem;
	}

	.type-label {
		padding: 3px 10px;
		border: 1px solid;
		border-radius: 4px;
		width: max-content;
		font-size: 13px;
		letter-spacing: 0.2px;
		font-weight: 500;
		margin: 1.3rem 0 0.5rem;

		&--new {
			@extend .type-label;
			background: rgba( 116, 189, 105, 0.4 );
			border: 1px solid #30d01a;
			color: #199608;
		}

		&--improvements {
			@extend .type-label;
			background: rgba( 255, 153, 103, 0.4 );
			border: 1px solid #ea673a;
			color: #dc5800;
		}

		&--fixes {
			@extend .type-label;
			background: rgba( 255, 144, 144, 0.4 );
			border: 1px solid #e12020;
			color: #e12020;
		}

		&--testing {
			@extend .type-label;
			background: rgba( 97, 144, 227, 0.4 );
			border: 1px solid #658ed6;
			color: #2867c8;
		}
	}

	ul {
		margin: 0;

		li {
			margin-bottom: 0.2rem;
			font-size: 15px;
			color: #3f4e5a;
		}
	}

}

hr {
	border-top: 1px solid #e1e1e1;
	width: 100%;
	margin: 0;
}
