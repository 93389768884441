// Set Grid (1170gs default)
$column-width: 70px;
$gutter: 30px;
$columns: 12;

$max-width: ($column-width * $columns) + ($gutter * ($columns - 1));
$xlarge-max-width: 1170px;
$large-max-width: 960px;
$medium-max-width: 885px;
$narrow-max-width: 768px;
$small-max-width: 640px;
$mobile-max-width: 480px;
$blog-article-max-width: 770px;

$transition: cubic-bezier( 0.46, 0.03, 0.52, 0.96 );

$column-width: 8.333%;
$gutter-width: 2.564102564102564%;//1.5873%;
$column-minus-gutter: 5.982905982905983%;
$gutter-width-px: 30px;

$two-column: $column-width*2;
$half-gutter: $gutter-width*0.5;

$elementSeparator: "__";
$modifierSeparator: "--";

$fonts: (
	nav : "Lato, sans-serif",
	body : "Lato, sans-serif",
	headings : "Sofia Pro, sans-serif",
);

/*
	300,400,700
*/

// $columnpoints: (
// 	'xs': max 500px,
// 	'sm': 500px,
// 	'md': 768px,
// 	'mx': 900px,
// 	'lg': 1170px,
// 	'xl': 1400px,
// );

$breakpoints: (
	"sm": 500px,
	"md": 768px,
	"mx": 970px,
	"lg": 1024px,
	"xl": 1340px,
);

$rootsizes: (
	rootsize-0: 20,
	rootsize-1: 21,
	rootsize-2: 22,
	rootsize-3: 22,
	rootsize-4: 23
);

$points: map-keys( $breakpoints );
$sizes: map-values( $rootsizes );

$palettes: (
	brand: (
		100: #810a2e,
		200: #951c41,
		400: #ad2c53,
		500: #ee3b71,
		800: #fad3e1,
		900: #cb124a
	),
	error: (
		200: #fad3e1,
		400: #ee3a71,
		500: #f00
	),
	grey: (
		000: #1e2022,
		100: #252728,
		200: #333,
		400: #777,
		500: #909aa3,
		600: #adacad,
		700: #cbd3db,
		800: #ebebeb,
		900: #f8f8f8,
		1000: #3b3c3e,
		1100: #535458
	),
	blue: (
		200: #bfc8ca,
		300: #d6eff9,
		500: #50c7e4,
		700: #c2d0d4,
		800: #d5effa,
		900: #f2f7f8
	),
	green: (
		500: #67bf75,
		800: #e7f2dd
	),
	slate: (
		200: #212326,
		300: #374047,
		400: #3c566d,
		500: #4e5c67,
		600: #454748,
		700: #ebeff2,
		800: #eff4f6,
	),
	os3: (
		primary: #4f0733,
		primary-alt: #ea705e,
		secondary: #ffdc8e,
		secondary-alt: #f9d0af,
	),
	oses: (
		primary: #34203a,
		primary-alt: #f4c758,
		secondary: #ffdc8e,
		secondary-alt: #fff7d1,
	),
	mdb: (
		primary: #03223e,
		primary-alt: #7fbcd3,
		secondary: #42aadd,
		secondary-alt: #a5ddf2,
	),
	suwp: (
		primary: #ee5372,
		primary-alt: #c6eaff,
		secondary: #ee5372,
		secondary-alt: #a5ddf2,
	),
	acf: (
		secondary: #00dab4,
	),
);

$blog_body_color: rgba( 0, 0, 0, 0.84 );

//$palettes: generate-color-palette($palettes);

$social: (
	facebook : #0053a3,
	twitter  : #0095d6,
	linkedin : #4d83c3,
	googplus : #dd4b39,
	youtube  : #cd201f,
	vimeo    : #aad450,
	rss      : #f90,
	flickr   : #0063dc,
	yelp     : #af0606,
	github   : #4e3d77,
	globe    : #454f56,
	instagram   : #517fa4,
	pinterest   : #cb2027,
	foursquare  : #f94877,
	urbanspoon  : #cb202c,
	tripadvisor : #589442,
);
