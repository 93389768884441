.dbi-table-wrap {
	max-width: 100%;
	overflow: auto;
	margin-bottom: 1rem;

	/*
	 * This is not required, but some tables need the inner with padding on mobile when
	 * they scroll horizontally. We use inline-block to make the inner take the width of
	 * the table inside it instead of the container.
	 */
	.dbi-table-inner {
		padding-right: 32px;
		display: inline-block;
		@include bp(md) {
			padding-right: 0;
			display: block;
		}
	}

	table {
		color: #3c566d;
		font-size: 0.65rem;
		width: 100%;
		border: 1px solid #e2e9ef;

		thead tr,
		tr:nth-child(even) {
			background: #f4f9fd;
		}

		th,
		td {
			text-align: left;
			padding: 0.6rem 1rem;
		}

		th {
			text-transform: uppercase;
			font-weight: bold;
		}
	}
}