.woocommerce-message,
.woocommerce-info,
.woocommerce-password-strength {
	border-left: 5px solid palette( blue, 500 );
	background-color: palette( blue, 300 );
	padding: 0.8rem 1rem;
	font-size: 16px;
	line-height: 1.5;
	border-radius: 2px;
	font-weight: 700;
	color: #424242;
	font-family: lato, sans-serif;
	margin: 0 0 1rem 0;
	list-style-type: none;
	box-shadow: -10px 10px 20px 0 rgba( 30, 30, 30, 0.05 );

	a {
		color: darken( palette( blue, 500 ), 20% );

		&:hover {
			color: darken( palette( blue, 500 ), 10% );
		}
	}
}

.woocommerce-password-strength {
	&.short,
	&.bad {
		border-color: palette( error, 400 );
		background-color: palette( error, 200 );
	}

	&.good {
		border-color: palette( blue, 500 );
		background-color: palette( blue, 300 );
	}

	&.strong {
		border-color: palette( green, 500 );
		background-color: palette( green, 800 );
	}
}

.woocommerce-error {
	padding-left: 0;
	margin-bottom: 1.25rem;

	li {
		@extend .woocommerce-message;
		border-left-color: palette( error, 400 );
		background-color: palette( error, 200 );
		list-style-type: none;
	}
}
