.dbi-pricing__table {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: relative;
	max-width: $max-width;
	left: 50%;
	transform: translateX( -50% );
	margin-top: 1rem;
	margin-bottom: 3.75rem;
	padding-left: 5%;
	padding-right: 5%;

	@include bp(xl) {
		padding-left: 0;
		padding-right: 0;
	}

	&.dbi-pricing__table--carousel {
		display: block;
		margin-top: 1rem;

		.dbi-pricing__plan {
			margin-top: 2rem;

			&.dbi-pricing__plan--business {
				display: none;
			}
		}
	}

	h2 + & {
		margin-top: 3rem;
	}

	table {
		//table-layout: fixed;
		color: palette( grey, 200 );
		font-size: 0.7rem;

		colgroup {
			col {
				width: 16%;
				//border-radius: .3rem;
				&:nth-child(even) {
					background-color: #e8eeef;
				}

				&:nth-child(odd) {
					background-color: #d7e3e5;
				}

				&:first-child {
					background-color: transparent;
					width: $column-width * 2.5;
					min-width: 8.5rem;
				}
			}
		}

		tfoot,
		thead {
			tr {
				background-color: transparent;
			}

			th {
				background-color: #4a4d50;
				padding: 0.75rem 0;

				&:nth-child(2) {
					border-top-left-radius: 0.3rem;
				}

				&:last-child {
					border-top-right-radius: 0.3rem;
				}

				&:empty {
					background-color: transparent;
				}

				&.popular {
					&::before {
						display: none;
					}
				}
			}
		}

		thead {
			th {
				&.popular {
					background-color: palette();
				}
			}
		}

		tfoot {
			th {
				padding-bottom: 0.5rem;

				.dbi-btn {
					margin: 0.5rem 0 0;
					width: 90%;
				}

				&:nth-child(2) {
					border-radius: 0;
					border-bottom-left-radius: 0.3rem;
				}

				&:last-child {
					border-radius: 0;
					border-bottom-right-radius: 0.3rem;
				}

				&.popular {
					&::before {
						content: "";
					}
				}
			}
		}

		tbody {
			tr {
				border-top: 1px solid #cbd5de;

				&:first-child {
					border-top: 0;
				}

				th {
					padding: 0.5rem;
				}

				td {
					padding: 0.5rem 0.25rem;
				}
			}

			th {
				text-align: left;
				background-color: palette( slate, 800 );
				font-weight: 400;
			}

			tr:first-child th {
				border-top-left-radius: 0.15rem;
			}
		}

		.icon {
			width: 0.65rem;
			height: 0.55rem;
		}
	}
	// actual html table

	.owl-stage-outer {
		overflow: visible;
	}

	.owl-stage {
		display: flex !important;
		margin-left: auto;
		margin-right: auto;
	}

	.owl-item {
		display: flex;
		opacity: 0.05;
		transition: opacity 0.2s $transition;
		pointer-events: none;

		&.active {
			opacity: 1 !important;
			pointer-events: all;
		}
	}

	.owl-nav.disabled,
	.owl-dots.disabled {
		display: none;
	}

	.owl-next,
	.owl-prev {
		position: absolute;
		top: 50%;
		background-color: palette();
		border-radius: 50%;
		width: 1.5rem;
		height: 1.5rem;
		transform: translateY(-50%);
		cursor: pointer;

		.icon {
			position: absolute;
			top: 50%;
			width: 1rem;
			height: 1rem;
			fill: #fff;
			transform: translate(-50%, -50%);
		}

		&.disabled {
			pointer-events: none;
			opacity: 0.25;
		}
	}

	.owl-prev {
		left: -0.5rem;

		.icon {
			left: 45%;
		}
	}

	.owl-next {
		right: -0.5rem;

		.icon {
			left: 55%;
		}
	}

	@include bp(lg) {
		.owl-next,
		.owl-prev {
			width: 2rem;
			height: 2rem;

			.icon {
				width: 1.25rem;
				height: 1.25rem;
			}
		}

		.owl-prev {
			left: 0.75rem;
		}

		.owl-next {
			right: 0.75rem;
		}
	}

	@include bp(xl) {
		.owl-prev {
			left: -2.75rem;
		}

		.owl-next {
			right: -2.75rem;
		}
	}
}

.dbi-notice__auto-discount-notice {
	font-size: 17px;
	padding: 0.6rem 0.8rem;
	background-color: #fff;
	color: #4d4d4d;
	border-left: 5px solid #ed3b71;
	border-radius: 4px;
	font-weight: 600;
	font-family: Lato, sans-serif;
	box-shadow: 0 15px 20px rgba(0, 0, 0, 0.25);
	margin: 0 0 2rem 0;
	.wp-offload-media-pricing &, .wp-offload-ses-pricing & {
		margin-top: 2.5rem;
	}
	display: inline-block;
	//max-width: 790px;
	max-width: 34.5rem;
	@media (max-width: 450px) {
		max-width: 320px;
	}
	text-align: left;

	&.non-profit {
		//max-width: 1100px;
		max-width: 47.9rem;
		margin-bottom: 2.5rem;
		@media (max-width: 450px) {
			max-width: 320px;
		}
	}
	&.educational {
		//max-width: 1200px;
		max-width: 52.2rem;
		margin-bottom: 2.5rem;
		@media (max-width: 450px) {
			max-width: 320px;
		}
		@include bp(lg) {
			margin-left: 5%;
			margin-right: 5%;
		}
	}
}

.dbi-pricing__plan__header {
	background-color: palette(grey);
	color: #fff;
	text-transform: uppercase;
	border-top-left-radius: 0.15rem;
	border-top-right-radius: 0.15rem;
	padding: 1rem;
	position: relative;

	.popular {
		position: relative;

		// &::before {
		// 	content: 'Most Popular';
		// 	position: absolute;
		// 	top: -1.5rem;
		// 	left: 50%;
		// 	transform: translateX(-50%);
		// 	font-size: .7rem;
		// 	color: palette();
		// 	white-space: nowrap;
		// }
	}

	h3 {
		font-weight: 500;
		line-height: 1.2;
		margin-bottom: 0.5rem;
	}

	h4 {
		line-height: 1.2;
	}

	.dbi-pricing__plan--migrate-enterprise h3 {
		margin: 0;
	}

	.dbi-pricing__plan--migrate-enterprise h6 {
		margin: 12px 0;
	}

	.dbi-price__original {
		margin-right: 0.5rem;
		text-decoration: line-through;
		opacity: 0.6;
	}
}

.dbi-pricing__plan {
	display: flex;
	flex-direction: column;
	flex: 0 0 100%-$gutter-width;
	max-width: 100%-$gutter-width;
	background-color: palette(grey, 800);
	margin: 2rem $half-gutter 2.5rem;
	border-radius: 0.15rem;

	&:last-of-type {
		margin-bottom: 0;
	}

	@include bp(sm) {
		flex: 0 0 50%-$gutter-width;
		max-width: 50%-$gutter-width;

		&:nth-last-of-type(2) {
			margin-bottom: 0;
		}
	}

	@include bp(md) {
		flex: 0 0 33.333%-$gutter-width;
		max-width: 33.333%-$gutter-width;
		//margin-bottom: 2.5rem;
		&:nth-last-of-type(2) {
			margin-bottom: 2.5rem;
		}
	}

	@include bp(lg) {
		flex: 0 0 25%-$gutter-width;
		max-width: 25%-$gutter-width;
		margin-bottom: 0;

		&:nth-last-of-type(2) {
			margin-bottom: 0;
		}
	}

	.seat-price-breakdown {
		color: #7e7e7e;
		font-size: 0.62rem;
		font-weight: 700;
		margin: -0.5rem auto 1rem auto;
	}

	.dbi-pricing__plan__wrap {
		width: 100%;
		display: flex;
		justify-content: center;
		flex-wrap: wrap;

		> div {
			display: none;

			&:nth-last-child(2),
			&:nth-last-child(3) {
				display: flex;
			}

			@include bp(md) {
				&:last-child,
				&:nth-last-child(2),
				&:nth-last-child(3) {
					display: flex;
				}
			}

			@include bp(1220px) {
				&:last-child,
				&:nth-last-child(2),
				&:nth-last-child(3),
				&:nth-last-child(4) {
					display: flex;
				}
			}
		}
	}

	.dbi-pricing__plan__viewer {
		width: 100%;
		display: flex;
		//transform: translateX(-50%);
		transition: all 0.2s $transition;
	}

	.owl-item & {
		max-width: 100%;
		flex: 1;
		margin: 0 15px;
	}

	[data-tooltip]::before {
		width: 110%;
	}

	.dbi-pricing__plan__label {
		display: none;
	}

	&.dbi-pricing__plan--popular {
		.dbi-pricing__plan__header {
			background-color: palette();
		}

		.dbi-pricing__plan__label {
			display: inline-block;
			color: palette();
			text-transform: uppercase;
			position: absolute;
			top: -1.5rem;
			left: 50%;
			transform: translateX(-50%);
			white-space: nowrap;
		}
	}

	.dbi-pricing__plan__features {
		flex: 1;
		list-style: none;
		margin: 0;
		padding: 0;
		color: palette(grey, 200);

		li {
			margin: 0;
			padding: 0;
			border-bottom: 1px solid #d2d9dc;
			font-size: 0.75rem;
			line-height: 1.3846;

			a {
				color: palette(grey, 200);
				text-decoration: none;

				.dbi-site-limit-help {
					width: 16px;
					height: 16px;
					display: inline-block;
					margin: 0.2rem 0 0 0.4em;
					position: absolute;
				}
			}

			> a,
			> span {
				display: block;
				padding: 0.6rem 0.5rem 0.5rem;
				text-align: center;
			}
		}
	}

	.dbi-pricing__plan__action {
		padding: 1rem 0.5rem 0.5rem;

		.dbi-btn {
			width: 100%;
			border-radius: 0.3rem 0.3rem 0.3rem 0.3rem;
			margin-bottom: 0;
			letter-spacing: 0.015rem;
		}
	}
}

.dbi-pricing__plan--migrate-enterprise {
	.dbi-pricing__plan__header h3 {
		margin: 18px 0;
	}
}

.dbi-pricing__table-info {
	margin-top: 72px;

	a {
		color: #fff;
		text-decoration: underline;
	}
}

.dbi-pricing__guarantee {
	max-width: $max-width;
	position: relative;
	left: 50%;
	width: 100%;
	transform: translateX(-50%);
	padding: 2rem 5% 2.5rem;
	background-color: palette();
	text-align: left;
	color: #fff;

	&::after,
	&::before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		background-color: palette();
		display: block;
		width: 99rem;
		left: 100%;
	}

	&::after {
		left: auto;
		right: 100%;
	}

	h2 {
		text-align: center;
		color: #fff;
	}

	p {
		text-align: center;
		margin-bottom: 1rem;

		@include bp(md) {
			padding-left: $column-width * 2 - 5;
			padding-right: $column-width * 2 - 5;
		}
	}

	p:last-of-type {
		margin-bottom: 0;
	}

	.icon {
		display: none;
		position: absolute;
		fill: #d22d5f;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		width: $column-width;
		height: 3.5rem;

		&:first-of-type {
			right: auto;
			left: 0;
		}

		@include bp(md) {
			display: block;
		}
	}
}

.dbi-pricing__faq {
	display: flex;
	flex-wrap: wrap;
	padding: 1.5rem 5% 2.5rem;

	@include bp(md) {
		padding-top: 3.5rem;
		max-width: $narrow-max-width;
		position: relative;
		left: 50%;
		width: 100%;
		transform: translateX(-50%);
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	h2 {
		flex: 0 0 100%;
		text-align: center;

		@include section-title;

		@include bp(md) {
			margin-bottom: 2rem;
		}
	}

	h3 {
		flex: 0 0 100%;
		margin-bottom: 1rem;
		text-transform: uppercase;
		padding-left: $column-width * 2;
		font-size: 1.1rem;
		font-weight: 700;
	}

	.dbi-pricing__faq__section-wrap {
		display: flex;

		&.dbi-pricing__faq__section-wrap--closed {
			display: none;
		}
	}

	.dbi-pricing__faq__section {
		flex: 0 0 100%;
		margin-top: 1rem;

		@include bp(md) {
			flex: 0 0 50%;
			padding-left: $half-gutter;
			padding-right: $half-gutter;
		}
	}

	.dbi-pricing__faq__actions {
		max-width: $narrow-max-width; //$max-width;
		position: relative;
		left: 50%;
		width: 100%;
		transform: translateX(-50%);
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-top: 1px solid palette(grey, 700);
		padding-top: 1.25rem;
		padding-left: $half-gutter;
		padding-right: $half-gutter;

		@include bp(md) {
			padding-left: 0;
			padding-right: 0;
		}

		span {
			font-size: 0.9rem;
			letter-spacing: 0.03rem;
			color: #8c8c8c;
		}

		.dbi-btn {
			margin-right: 0;
		}

		.dbi-faq-read-more {
			margin: 0 auto;
		}
	}
}

// faq

.dbi-pricing__tweets {
	background: linear-gradient(to bottom right, #f1f5f6, #fff);
	padding-top: 3.5rem;
	padding-bottom: 0;

	@include bp(sm) {
		padding-bottom: 2.5rem;
	}

	.dbi-pricing__tweets__intro {
		font-size: 0.8rem;
		line-height: 1.5;
		text-align: center;
		color: palette(grey, 400);
		margin-bottom: 2rem;

		@include bp(md) {
			padding-left: $column-width;
			padding-right: $column-width;
		}

		@include bp(lg) {
			padding-left: $column-width * 2;
			padding-right: $column-width * 2;
		}
	}

	.dbi-container {
		display: flex;
		flex-wrap: wrap;
	}

	.dbi-media {
		flex: 0 0 100%;
		padding: 1rem;
		border-color: palette(blue, 700);
		color: palette(grey, 400);
		margin: 0 0 1.5rem;

		@include bp(md) {
			flex: 0 0 50% - $gutter-width;
			max-width: 50% - $gutter-width;
			margin: 0 $half-gutter 1.5rem;
		}

		@include bp(lg) {
			flex: 0 0 33.3333% - $gutter-width;
			max-width: 33.3333% - $gutter-width;
			margin: 0 $half-gutter 1.5rem;
		}

		.icon-twitter {
			top: 1.2rem;
			fill: map-get($social, twitter);
		}
	}

	h2 {
		flex: 0 0 100%;
		text-align: center;

		@include section-title;
		margin-bottom: 1rem;
	}
}

.dbi-notice__discount-notice {
	margin: 1rem 0;
	width: 100%;
	text-align: center;
	font-size: 0.8rem;
}

#os3-pricing-carousel,
#oses-pricing-carousel {
	.owl-item .dbi-pricing__plan {
		margin: 2rem 5px 0;
	}
}

.dbi-page-header--offload-ses p {
	color: #ccc;
	margin: 0;

	a {
		color: palette(oses, primary-alt);
	}
}

#oses-pricing-carousel {
	margin-bottom: 2rem;
}

//Migrate DB New Pricing Page Styling

body[class*="my-account-license-upgrade-"] .wp-migrate-db-pro-upgrade-table,
body.wp-migrate-db-pro-pricing {
	&.dbi-pricing__table--carousel {
		margin: 0;

		.dbi-pricing__plan {
			border-radius: 8px;
			background: #fff;
			box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
		}
	}

	.dbi-pricing__table.dbi-pricing__table--carousel {
		margin: 0;
	}

	.dbi-pricing__plan__header h3 {
		margin-bottom: 0;
	}

	.owl-item {
		opacity: 0.15;
	}

	.owl-dots .owl-dot span {
		background: #fff;
	}

	#mdb-business-pricing-carousel {
		display: none;
	}

	.mdb-license-toggle {
		display: none;

		@include bp(md) {
			display: block;
		}

		.tab-buttons {
			display: flex;
			align-items: stretch;
			justify-content: space-between;
			height: 2rem;
			a {
				flex-basis: 9rem;
			}
		}

		margin: 2.5rem auto;
		cursor: pointer;
		max-width: 39.2rem;
		padding: 6px;

		background: rgba(0, 0, 0, 0.25);
		border: 1px solid #464ca4;
		box-shadow: 0px 1px 1px rgba(6, 8, 28, 0.2);
		border-radius: 6px;

		@media (max-width: 380px) {
			width: auto;
		}

		a {
			margin: 0;
			font-size: 15px;
			font-family: ff("headings");
			font-weight: normal;
			color: rgba(255, 255, 255, 0.7);
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			padding: 0 1.2rem;
			@media ( min-width: 751px ) and ( max-width: 1000px ) {
				padding: 0 0.5rem;
			}
			z-index: 100;

			&.active {
				background: #0d68ff;
				box-shadow: 0px 2px 5px #00225a;
				border-radius: 6px;
				color: rgba(255, 255, 255, 1);
			}
		}
	}

	.mdb-license-select-wrapper {
		display: flex;

		@include bp(md) {
			display: none;
		}
		margin: 2.5rem auto;
		flex-direction: column;
		justify-content: left;
		@media (max-width: 450px) {
			max-width: 320px;
		}

		select {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			background: #ffffff url("../svg/caret-down.svg") no-repeat scroll
				100% center / 48px 16px !important;
			border: 1px solid #9f9f9f;
			border-radius: 6px;
			overflow: hidden;
			padding: 0px 12px 0px 24px !important;
			height: 2.5rem;
			width: auto;
			margin-top: 0.5rem;
			font-size: 0.8rem;
			cursor: pointer;
		}
		label {
			align-self: flex-start;
			font-size: 0.75rem;
			color: rgba(255, 255, 255, 0.7);
		}
	}

	.seats-required {
		padding: 0.5rem 1.25rem;
		width: 20rem;
		display: none;
		flex-direction: column;
		border: 1px solid;
		border-color: rgba(255, 255, 255, 0.2);
		border-radius: 6px;
		margin: 0 auto 1.5rem auto;
		align-items: center;
		justify-content: space-between;
		position: relative;

		&.show {
			display: flex;
			z-index: unset;
		}

		@media (max-width: 480px) {
			width: auto;
		}

		.title {
			top: -6px;
			position: absolute;
			padding: 0 0.5rem;
			align-items: center;
			background: #2c3095;
			height: 0.5rem;
			display: flex;
			flex-direction: row;

			p {
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.7);
				font-size: 13px;
				margin: 0;
			}

			div {
				display: inherit;
			}

			svg {
				width: 16px;
				height: 16px;
				opacity: 0.7;
				margin-left: 0.4rem;
				cursor: pointer;
			}
		}

		.seats-input {
			display: flex;
			flex-direction: row;
			align-items: center;
			width: 100%;
			justify-content: space-between;

			.seats-number-input {
				width: 18%;
				height: 1.75rem;
				display: flex;
				font-size: 20px;
				text-align: center;
				min-width: 2.5rem;
				padding: 0 0 0 13px;
				margin-left: 30px;

				@media (max-width: 480px) {
					margin-left: 1rem;
				}
			}
		}

		.seats-info {
			position: static;

			.tooltip-text {
				padding-bottom: 50px;
				visibility: hidden;
				position: absolute;
				text-align: center;
				font-size: 1rem;
				line-height: 1.4;
				z-index: 999;

				.tooltip {
					padding: 15px;
					background-color: #fff;
					border-radius: 0.15rem;
				}

				p {
					color: #3f3f3f;
					text-transform: none;
					text-align: left;

					&:nth-child(2) {
						margin: 0.6rem 0;
					}
				}

				&:hover {
					visibility: visible;
				}
			}

			&:hover {
				.tooltip-text {
					visibility: visible;
				}
			}
		}
	}

	.dbi-pricing__plan__header {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border: 2px solid white;
		background: #0968ff;
	}

	h3 {
		font-size: 18px;
	}

	.dbi-pricing__plan {
		background: #fff;
		box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
		border-radius: 8px;

		.dbi-pricing__plan__features {
			display: flex;
			flex-direction: column;

			li {
				border-bottom: none;
				font-size: 14px;
				line-height: 1.4;
				text-align: center;
				display: flex;
				align-items: center;
				position: relative;

				@for $i from 1 through 8 {
					&:nth-child(#{$i}) {
						order: #{$i};
					}
				}

				&:not(:first-child) {
					border-top: 1px solid #f5f5f5;
				}

				&:last-child {
					order: 6;
				}

				.dbi-site-limit-help {
					margin: 0.05rem 0 0 0.5rem;
				}

				> a,
				> span {
					text-align: left;
				}

				span {
					cursor: default;
				}
			}
		}

		.dbi-pricing__plan__action {
			padding: 1.6rem 0.5rem 1.1rem;

			.dbi-btn {
				width: 160px;
				margin: 0 auto;
				border-radius: 3px;
				background: #0968ff;
				padding: 0.65rem;
				font-size: 14px;
			}

			.dbi-btn--outlined {
				border: 1px solid #0968ff;
				background: transparent;
				color: #0968ff;
			}
		}
	}

	.dbi-pricing__plan--popular {
		.dbi-pricing__plan__action {
			.dbi-btn {
				background-color: #ee3b71;
			}
		}
	}

	.dbi-pricing__plan--migrate-enterprise {
		.dbi-pricing__plan__header h3 {
			margin: 0;
		}
	}

	.dbi-pricing__plan {
		h4 {
			font-size: 48px;
			font-weight: 600;
			color: #4d4d4d;
			margin: 0.5rem 0.4rem 0 0.2rem;
			display: flex;
			align-items: baseline;
			justify-content: center;

			span.dbi-price-currency {
				font-size: 24px;
				font-weight: 400;
				align-self: center;
				margin-top: -0.7rem;
			}

			.dbi-price__original {
				width: auto;
				margin: 0 20px 0 -80px;
				font-size: 24px;
				font-weight: 400;
				color: #a6a6a6;
				text-decoration: line-through;
				align-self: center;

				@include bp(md) {
					width: 30%;
					margin: 0 0 0 -80px;
				}

				span.dbi-price-currency {
					font-size: 14px;
					margin-top: 0.25rem;
					vertical-align: text-top;
				}
			}
		}

		.dbi-pricing__plan__features {
			li:hover {
				background: #def6ff;
			}
		}

		.dbi-pricing__plan__action {
			a:hover {
				opacity: 0.9;
				box-shadow: 0 10px 10px rgba(0, 0, 0, 0.3);
				transform: scale(1.03);
			}
		}

		.ribbon-wrapper {
			width: 105px;
			height: 105px;
			overflow: hidden;
			position: absolute;
			top: 0;
			right: 0;
		}

		.ribbon {
			transform: rotate(45deg);
			background-color: #fffefe;
			text-align: center;
			position: relative;
			padding: 3px 0;
			width: 105px;
			left: 26px;
			top: 18px;
			white-space: nowrap;
			font-size: 11px;
			color: #ee3a71;
			box-shadow: -2px 0 5px rgba(0, 0, 0, 0.6);
		}

		.mdb-pricing-check {
			width: 12px;
			height: 12px;
			position: relative;
			left: 7%;
			margin-right: 25px;
		}
	}
}

body[class*="my-account-license-upgrade-"] .wp-migrate-db-pro-upgrade-table {
	.dbi-pricing__plan {
		box-shadow: 0 0 10px rgba( 0, 0, 0, 0.05 );
	}
	.dbi-pricing__plan__action .dbi-btn {
		&[disabled] {
			background: #cacaca;
		}
	}

	.dbi-pricing__plan__active {
		h4 {
			color: #cacaca;
		}
	}

	.dbi-price__plan--cost {
		margin-top: 0;
	}
}

.discount-badge {
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: absolute;
	top: 48px;
	right: 46px;
	color: #fff;
	font-weight: 600;
	font-size: 0.55rem;

	&.show {
		display: flex;
	}

	.percentage {
		font-size: 0.75rem;
		margin-bottom: 0.1rem;
	}

	span {
		z-index: 1;
		line-height: 1;
	}

	.badge-icon {
		align-items: center;
		position: absolute;
		width: 2.75rem;
		height: 2.75rem;
	}
}
.non-profit-contact-us.modal {
	display: none; /* Hidden by default */
	position: fixed; /* Stay in place */
	z-index: 1; /* Sit on top */
	left: 0;
	top: 0;
	width: 100%; /* Full width */
	height: 100%; /* Full height */
	overflow: auto; /* Enable scroll if needed */
	background-color: rgb(0, 0, 0); /* Fallback color */
	background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

	/* Modal Content */
	.modal-content {
		font-size: 17px;
		padding: 0.6rem 0.8rem;
		background-color: #ffffff;
		color: #4d4d4d;
		border-radius: 4px;
		font-weight: 500;
		font-family: Lato, sans-serif;
		box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.25);

		display: inline-block;
		max-width: 790px;
		text-align: left;
		margin: 20% auto;
		width: 80%;

		/* The Close Button */
		.close {
			color: #aaa;
			float: right;
			font-size: 28px;
			font-weight: bold;

			&:hover,
			&:focus {
				color: black;
				text-decoration: none;
				cursor: pointer;
			}
		}

		p {
			margin: 1rem 1.5rem;
		}
	}
}

#mdb-info-educational {
	max-width: 50rem;

	@media (max-width: 450px) {
		max-width: 320px;
	}

	margin: auto;

	display: flex;
	flex-direction: column;

	.teachers-students {
		display: flex;
		flex-direction: column;
		max-width: 37.5rem;
		margin: auto;
		border-radius: 12px;
		background: rgba(0, 0, 0, 0.2);
		padding: 2rem;
		line-height: 1.6;
		margin: 0 auto 2.5rem auto;
		@include bp(md) {
			flex-direction: row;
			padding: 0.8rem;
		}

		.content {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 32px;

			@include bp(md) {
				flex-basis: 14.5rem;
				margin: 32px;
			}

			.icon {
				width: 36px;
				height: 36px;
				margin: 5px 0;
			}
			h3 {
				font-weight: 600;
				font-size: 22px;
				color: #62c4e8;
				margin: 5px 0;
			}
			p {
				margin: 0.8rem 0 0 0;
				text-align: center;
				@include bp(md) {
					text-align: left;
				}

				a {
					color: #ffffff;
					font-weight: 700;
					text-decoration: underline;

					&:hover {
						color: palette(brand, 500);
					}
				}
			}
		}
	}

	p {
		font-size: 15px;
	}
}

.dbi-price__plan--cost {
	margin: 1rem 0 .5rem;
	font-size: 14px;
	span {
		font-weight: bold;
	}
}

.dbi-pricing__plan__business {
	--upgrade-plan-background: rgba( 13, 104, 255, 0.07 );
	--upgrade-plan-color: #0d68ff;
	--upgrade-plan-border-color: rgba( 13, 104, 255, 0.5 );
}
.dbi__price__plan--type {
	margin: 10px 10px -20px;
	font-size: 14px;
	background: var( --upgrade-plan-background, rgba( 0, 158, 15, 0.07 ) );
	color: var( --upgrade-plan-color, #009e0f );
	border: 1.5px solid var( --upgrade-plan-border-color, rgba( 0, 158, 15, 0.4 ) );
	border-radius: 3px;
	padding: 3px 16px;
	margin: 12px 15px 0;
	font-weight: 600;
	font-size: 13px;
	letter-spacing: 0.4px;
	text-align: center;
}
