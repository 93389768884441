ul,
ol {
	margin: 0 0 1.5rem;
	padding: 0 0 0 1rem;

	ul,
	ol {
		margin-bottom: 0;
	}

	ul {
		list-style: square outside url( "data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==" );
	}
}

.terms {
	padding: 0;
	list-style-type: none;

	ol {
		margin: 0.5rem 0 0;
		padding: 0 0 0 1.5rem;
		list-style-type: decimal;

		ol {
			list-style-type: lower-alpha;
		}
	}

	li {
		margin: 0 0 0.5rem;
	}
}

ul.circle-checklist {
	list-style: none;
	margin: 0;
	padding: 0;

	li {
		padding: 12px 0;
		position: relative;
		display: flex;
		align-items: center;
		margin: 0;

		svg {
			background: var( --list-background, #d0e2ff );
			fill: var( --list-foreground, #0d68ff );
			width: 20px;
			height: 20px;
			border-radius: 50%;
			padding: 4px;
			margin-right: 20px;
			flex: 0 0 20px;
		}

		&:not(:last-of-type) {
			border-bottom: 1px solid rgba( 0, 0, 0, 0.04 );
		}
	}
}
