body.signin {
	.woocommerce {
		padding: 2rem 20px 2rem 20px;

		@include bp(sm) {
			padding: 4rem 0 6rem 0;
		}
	}

	form.login {
		label.inline {
			margin-bottom: 0;
		}

		input[type="checkbox"] {
			margin-right: 0.2rem;
		}

		input[type="submit"] {
			margin-top: 1rem;
		}

		.g-recaptcha {
			margin-bottom: 1rem;
		}
	}
}

.lost_reset_password {
	.g-recaptcha {
		margin-bottom: 1rem;
	}
}