.dbi-site__signup {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	background-color: palette( grey, 900 );
	flex-direction: column;
	padding: 1.25rem 5%;

	@include bp(md) {
		flex-direction: row;
		padding: 1.25rem 0;
	}

	input[type="email"] {
		@include bp(md) {
			min-width: 15rem;
		}
	}
}

.dbi-site__colophon {
	padding: 1rem 5%;

	a {
		transition: all 0.2s $transition;

		&:hover {
			text-decoration: none;
		}
	}

	@include bp(md) {
		align-items: center;
		max-width: $max-width;
		position: relative;
		left: 50%;
		transform: translateX( -50% );
		justify-content: space-between;
		padding: 2.5rem 5% 1rem;
	}

	@include bp(xl) {
		padding-left: 0;
		padding-right: 0;
	}

	p {
		text-transform: uppercase;
		font-size: 0.55rem;
		color: palette( grey );
		letter-spacing: 0.025rem;
		margin-bottom: 1rem;

		a {
			color: palette( grey );
			margin: 0 0.125rem;
			padding: 0 0.125rem;

			&:hover {
				color: palette();
			}
		}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;

		li {
			display: inline-block;
			margin: 0;
		}

		a {
			display: block;
			text-decoration: none;
			margin-right: 0.5rem;

			.icon {
				fill: palette( grey, 400 );
				width: 0.75rem;
				height: 0.75rem;
			}

			&:hover {
				.icon-github {
					fill: map-get( $social, github );
				}

				.icon-facebook {
					fill: map-get( $social, facebook );
				}

				.icon-twitter {
					fill: map-get( $social, twitter );
				}
			}
		}
	}

	&.dbi-site__colophon--checkout {
		display: block;

		p {
			text-align: center;
		}
	}
}

// footer
.dbi-site__footer {
	background: #131e2a;
	padding-top: 75px;
	padding-bottom: 55px;
	-webkit-font-smoothing: antialiased;

	@include bp(max md) {
		padding-top: 16px;
	}

	p, h1, h2, h3, h4, h5, h6, td, th {
		letter-spacing: 0;
	}

	p {
		margin: 0;
	}

	a {
		cursor: pointer;
		text-decoration: none;
	}

	a:hover {
		color: #ee3b71;
	}

	ul {
		list-style: none;

		p {
			font-size: 16px;
		}

		svg {
			box-sizing: border-box;
			vertical-align: middle;
		}

	}
}

//footer brand (logo + name)
.footer-brand {
	flex-direction: column;
	display: flex;
	flex-wrap: wrap;
	align-items: flex-end;

	@include bp(max lg) {
		flex: 0 1 100%;
		align-items: flex-start;
		margin: {
			top: 48px;
		};
	}

	a:hover {
		text-decoration: none;
	}

	.footer-logo {
		fill: #ee3b71;
		text-align: right;
		line-height: 24px;

		.icon-logo {
			display: inline-block;
			width: 60px;
			height: 72px;
			vertical-align: middle;
			position: relative;
			margin: {
				bottom: 20px;
			};
			fill: palette();

			@include bp(max lg) {
				display: block;
			}

		}

		p {
			color: #fff;
			font-weight: 600;
			font-size: 16px;
			line-height: 24px;
		}

		@include bp(max lg) {
			display: flex;

			.icon-logo {
				width: 24px;
				max-height: 27px;
				margin: {
					right: 16px;
					bottom: 0;
				};
			}

		}

	}
}

//footer social icons
.footer-social {
	display: flex;
	margin-left: 5px;
	margin-top: 16px;

	ul {
		display: flex;
	}

	li {
		padding: 10px;
	}

	a {
		.icon {
			fill: #fff;
			width: 0.85rem;
			height: 0.85rem;
		}

		&:hover {
			.icon-github {
				fill: map-get( $social, github );
			}

			.icon-facebook {
				fill: map-get( $social, facebook );
			}

			.icon-twitter {
				fill: map-get( $social, twitter );
			}
		}
	}

	@include bp(max md) {
		margin-top: 0;
		margin-left: auto;
	}
}

//footer nav links
.footer-links {
	display: flex;
	flex-wrap: wrap;
	flex: 1 1 auto;

	p {
		color: #fff;
		font-weight: 600;
		font-size: 16px;
	}

	ul {
		display: flex;
		flex-direction: column;
		margin: {
			top: 0;
			right: 112px;
			bottom: 0;
			left: 0;
		};
		padding: 0;

		@include bp(max lg) {
			flex: 0 1 calc(27.5% - 64px);
			margin: {
				top: 24px;
				right: 64px;
			}

			&.footer-products-list {
				flex: 0 1 45%;
			}
		}

		@media screen and (max-width: 640px) {
			flex: 0 1 calc(50% - 12px);
			margin: {
				top: 48px;
				right: 24px;
			}

			&.footer-products-list {
				flex: 0 1 100%;
				margin: {
					right: 0;
				}
			}
		}

		&:last-of-type {
			margin: {
				right: 0;
			};
		}

		li {
			min-height: 24px;
			font-weight: 500;
			font-size: 15px;
			margin-top: 15px;
			vertical-align: middle;
			line-height: 24px;

			&:first-of-type {
				margin: {
					top: 0;
					bottom: 16px;
				};
			}

			@include bp(max lg) {
				max-width: 300px;
			}

		}

		a {
			color: #898e94;
		}

		p {
			margin: 0;
		}
	}
}

//footer products list
.footer-products-list {

	@include bp(max lg) {
		flex: 1 0 100%;
	}

	li {
		display: flex;
		align-items: center;
		vertical-align: middle;
	}

	&__link {
		display: flex;
		align-items: center;
		flex: 1 1 auto;
	}

	&__twitter {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		margin: {
			left: 64px;
		};
		padding: {
			top: 1px;
		};
		background-color: #324762;
		border-radius: 4px;
		text-align: center;
		line-height: 24px;

		@include bp(max lg) {
			margin: {
				left: 0;
			};
		}

		&:hover {
			background-color: lighten(#324762, 12%);
		}

		&__icon {
			display: flex;
			justify-content: center;
			max-width: 14px;
			max-height: 14px;
			fill: #1da1f2;
		}
	}

	&__medallion {
		max-width: 24px;
		max-height: 24px;
		margin: {
			right: 16px;
		};
	}
}

//footer brand twitter
.footer-brand-twitter {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: {
		top: 16px;
	}
	color: #898e94;
	font-size: 16px;
	line-height: 24px;

	@include bp(max lg) {
		margin: {
			top: 8px;
			left: 40px;
		}
	}

	&__link {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 24px;
		height: 24px;
		margin: {
			left: 8px;
		};
		padding: {
			top: 1px;
		};
		background-color: #324762;
		border-radius: 4px;
		text-align: center;

		&:hover {
			background-color: lighten(#324762, 12%);
		}

		&__icon {
			display: flex;
			justify-content: center;
			max-width: 14px;
			max-height: 14px;
			fill: #1da1f2;
		}
	}
}

//footer horizontal rule
.footer-hr {
	margin-top: 48px;
	background-color: #2b343f;
	width: 100%;
	height: 1px;

	@include bp(max sm) {
		margin-top: 50px;
	}
}

//footer copyright and terms links
.footer-bottom {
	color: #6b7279;
	font-size: 11px;
	margin-top: 35px;
	display: flex;
	width: 100%;
	line-height: 16px;

	.footer-disclaimer {
    	margin-top: 15px;
		@include bp(lg) {
			width: 700px;
		}
	}

	p {
		margin: 0;
		text-transform: uppercase;
	}

	ul {
		list-style: none;
	}

	@include bp(max md) {
		flex-direction: column;
	}
}

//footer terms and privacy links
.terms-privacy-links {
	text-transform: uppercase;
	margin: 0;
	display: flex;
	margin-left: auto;

	@include bp(max md) {
		margin-left: 0;
		margin-top: 20px;
	}

	a {
		padding-left: 10px;
		align-items: center;
		color: #6b7279;

		@include bp(max md) {
			padding-left: 0;
			padding-right: 10px;
		}
	}

	span {
		padding-left: 10px;
		align-items: center;

		@include bp(max md) {
			padding-left: 0;
			padding-right: 10px;
		}
	}
}