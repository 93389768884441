body > svg, body .js-body-wrap > svg {
	display: none;
}

.dbi-container {
	position: relative;
	padding-left: 5%;
	padding-right: 5%;

	@include bp(md) {
		max-width: $max-width;
		width: 100%;
		left: 50%;
		transform: translateX(-50%);
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	&--center {
		align-items: center;
		flex-wrap: nowrap;
		z-index: 100;
	}

	&--narrow {
		max-width: $narrow-max-width;
	}

	&--medium {
		max-width: $medium-max-width;
	}

	&--large {
		max-width: $large-max-width;
	}

	&--xlarge {
		max-width: $xlarge-max-width;
	}

	&--no-flex {
		display: block;
		transform: none;
		left: auto;
		margin: 0 auto;
	}

	.left-third {
		@include bp(md) {
			flex: 0 0 33.333%;
			max-width: 33.333%;
		}
		//margin-right: $column-minus-gutter;
	}

	.left-half {
		@include bp(md) {
			flex: 0 0 50%;
			max-width: 50%;
			padding-right: $column-width;
		}

		h3 {
			text-transform: uppercase;
			font-weight: 700;
			line-height: 1.333;
			margin-bottom: 1.75rem;
		}
		.dbi-btn {
			margin-top: 1rem;
		}
		//padding-right: $half-gutter;
	}
}


.greyscale {
	filter: grayscale(100%);
	border-radius: 50%;
}

#hs-beacon {
	iframe {
		z-index: 1000 !important;
	}
}

body.footer-sticky-alert-bar {
	#hs-beacon {
		iframe {
			bottom: 72px !important;
		}
	}
}

// This fixes an issue with the adroll icon being positioned incorrectly
#adroll_consent_persistent_icon_href,
#adroll_nextroll_logo,
.adroll_persistent_icon_wrapper,
.adroll_persistent_icon_wrapper > span {
	display: block !important;
}
