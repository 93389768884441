.dbi-callout {
	background: url( ../images/bg-callout.jpg ) no-repeat center center;
	background-size: cover;
	color: #fff;

	.dbi-callout__content {
		position: relative;
		padding: 4rem 5%;
		text-align: center;

		@include bp(1220px) {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.dbi-callout__message {
		font-weight: 700;
		display: block;
		text-transform: none;
		line-height: 1.2;
		letter-spacing: 0.025rem;
		color: #fff;
		margin: 0 0 2rem;
		font-size: 1.25rem;

		@include bp(md) {
			display: inline-block;
			margin: 0 1.5rem 0 0;
		}
	}

	.dbi-btn {
		margin: 0;

		@include bp(md) {
			margin: -12px 0 0;
		}
	}
}
