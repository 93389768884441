/**
 * Block.
 */

.table {
	table-layout: fixed;
	margin-bottom: 1.5rem;

	th,
	td {
		padding: 0.5rem;
		border-bottom: 1px solid palette( grey, 800 );

		@include bp(sm) {
			padding: 1rem;
		}

		& > :last-child {
			margin-bottom: 0;
		}
	}

	h3 {
		line-height: 1.2;
		margin: 0 0 0.5rem;
	}
}

/**
 * Elements.
 */

.table__cell {
	text-align: left;

	&--center {
		text-align: center;
	}
}

/**
 * Modifiers.
 */

.table--striped {
	tbody tr:nth-child(odd) {
		background: palette( grey, 900 );
	}
}
