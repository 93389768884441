.dbi-post-card {
	background: #fff;
	display: block;
	border-radius: 8px;
	overflow: hidden;
	box-shadow: 0 5px 35px rgba( 0, 0, 0, 0.15 );

	.dbi-post-card__graphic img {
		width: 100%;
	}

	&:focus,
	&:hover {
		text-decoration: none;
	}

	&:hover {
		.dbi-post-card__graphic img {
			transform: scale3d( 1.08, 1.08, 1 );
		}
	}
}

.dbi-post-card__content {
	padding: 1.35rem;
	display: flex;
	flex-direction: column;
}

.dbi-post-card__title {
	@extend h2;
	display: block;
	font-size: 0.95rem;
	font-weight: 600;
	text-transform: none;
	line-height: 1.3;

	&:hover {
		color: palette();
		text-decoration: none;
	}
}

.dbi-post-card__author {
	white-space: nowrap;
}

.dbi-post-card__graphic {
	display: block;
	overflow: hidden;

	img {
		transition: transform 1s cubic-bezier( 0.165, 0.84, 0.44, 1 );
	}
}
