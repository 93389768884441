.dbi-features {
	background: #fdfefe;
	overflow: hidden;

	@include bp($max-width) {
		max-width: $max-width;
		margin: 0 auto;
		border-radius: 5px;
		box-shadow: 0 10px 70px rgba( 0, 0, 0, 0.15 );
	}

	.dbi-features__header {
		height: 95px;
		padding: 0 5%;
		background: #fff;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;

		h2 {
			font-family: ff( "headings" );
			font-size: 24px;
			font-weight: 500;
			line-height: 95px;
			color: #002040;
			margin: 0;
		}

		@include bp(md) {
			padding: 0 40px;
		}
	}

	.dbi-features__content {
		background: #fff;
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.dbi-features__feature {
		padding: 2rem 5%;
		overflow: hidden;

		@include bp(md) {
			padding: 40px;
		}

		.dbi-features__feature__content {
			position: relative;

			@include bp(md) {
				width: 300px;
			}

			h3 {
				font-family: ff( "headings" );
				font-size: 0.7rem;
				font-weight: 600;
				line-height: 1.2;
				text-transform: uppercase;
				color: #002040;
				margin: 0 0 0.2rem;
				letter-spacing: 0.025rem;

				@include bp(md) {
					margin: 0 0 0.5rem;
				}

			}

			p {
				font-size: 0.7rem;
				color: #787878;
				margin-bottom: 1rem;

				@include bp(md) {
					margin: 0;
				}

				&.lead {
					font-size: 12px;
					font-style: italic;
					color: #002040;
					margin-bottom: 0.5rem;
				}
			}

			a {
				color: #787878;
				text-decoration: underline;

				&:hover {
					color: palette( brand, 400 );
				}
			}

			.dbi-features__feature__content__image {
				display: block;
				max-width: 100%;

				@include bp(md) {
					position: absolute;
					top: 0;
					left: 360px;
					z-index: 0;
					max-width: 730px;
				}
			}

			.dbi-features__feature__content__image-large {
				display: none;
				box-shadow: -10px 10px 50px rgba( 0, 0, 0, 0.2 );

				@include bp(md) {
					display: block;
					position: absolute;
					top: 0;
					left: 757px;
					z-index: 1;
					max-width: 375px;
				}
			}

			.dbi-features__feature__content__logo {
				display: block;
				margin-bottom: 0.7rem;
			}
		}
	}
}
