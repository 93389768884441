.dbi-career {
	display: block;
	text-decoration: none;
	display: flex;
	flex-direction: column;
	color: palette( grey, 400 );
	text-align: left;
	padding: 1.25rem 5%;

	@include bp(md) {
		flex-direction: row;
		justify-content: space-between;
		padding: 1.25rem $column-width;
	}

	&:not(:first-child) {
		border-top: 1px solid palette( grey, 800 );
	}

	.dbi-career__title {
		font-size: 0.9rem;
		line-height: 1.1;
		letter-spacing: 0.025rem;
		margin-bottom: 0.5rem;

		@include bp(md) {
			margin-bottom: 0;
		}

		span {
			display: inline-block;
			transition: padding 0.25s $transition;
		}
	}

	&:hover {
		text-decoration: none;
		color: palette();

		.career__title span {
			padding-left: 0.5rem;
		}
	}
}
