.dbi-site__header {
	position: relative;
	padding: 0 1rem;
	background-color: rgba( palette( grey, 100 ), 0.8 );
	z-index: 1100;
	display: flex;
	flex-wrap: wrap;
	transform: translateY( 0 );
	margin-bottom: -3rem;
	height: 3rem;

	@include bp(lg) {
		top: 0;
		left: 0;
		right: 0;
		margin-bottom: -2.5rem;
		height: 2.5rem;
	}

	.error404 & {
		position: relative;
		background-color: palette( grey, 100 );
	}

	.home & {
		background-color: transparent;

		@include bp(max mx) {
			background-color: rgba( palette( grey, 100 ), 0.8 );
		}

		.icon-logo {
			@include bp(max mx) {
				display: none;
			}
		}
	}

	@include bp(max mx) {
		&.nav-up {
			transform: translateY( -2.5rem );

			.js-nav-active & {
				transform: translateY( 0 );
			}
		}
	}

	&.dbi-site__header--blank {
		background-color: transparent;
	}

	.icon-logo {
		width: 1.1rem;
		height: 1.2rem;
		vertical-align: middle;
		position: relative;
		top: -2px;
		margin: 0 0.5rem 0 0;
		fill: palette();
	}

	&.dbi-site__header--checkout {
		.icon-logo {
			display: block;
			top: 0.4rem;
			margin: 0 auto;
		}
	}
}

.dbi-site__header--checkout {
	padding-top: 10px;
}

.js-nav-active {
	.dbi-site__header {
		background-color: rgba( 0, 0, 0, 0.9 );
	}
}

// header

.dbi-site__mobile-links {
	line-height: 3rem;
	padding-right: 1rem;
	text-transform: uppercase;
	display: flex;

	a {
		color: #fff;

		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
			color: palette();
		}
	}

	@include bp(md) {
		display: 2rem;
	}

	@include bp(mx) {
		display: none;
	}

	.icon:not(.icon-logo) {
		width: 1.8rem;
		height: 3rem;
		box-shadow: none;
	}

	.product-title {
		font-size: 0.75rem;
		font-weight: 700;
		font-family: ff( headings );
		display: flex;

		svg {
			margin-right: 0.6rem;
		}
	}

	.mdb {
		color: palette( mdb, secondary-alt );

		&:hover {
			color: #95cde2 !important;
		}
	}

	.oses {
		color: palette( oses, secondary-alt );

		&:hover {
			color: #e9dfae !important;
		}
	}

	.os3 {
		color: palette( os3, secondary-alt );

		&:hover {
			color: #ebb589 !important;
		}
	}
}

.dbi-site__content--checkout {
	background-color: #f6f6f6;
}
