.dbi-plugin-intro {
	position: relative;
	//padding-bottom: 3rem;
	padding-top: 2.5rem;

	background: #14273a no-repeat;

	h2 {
		margin-top: 2rem;
	}

	@include bp(md) {
		background-color: #14273a;
		background-size: contain;
	}

	&.dbi-plugin-intro--migrate {
		background-image: url( ../images/bg-migrate2.jpg );
		background-position: 50% 0;
		display: flex;
		flex-direction: column;

		h1,
		h2,
		h3 {
			color: palette( mdb, secondary );
		}

		&::before {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
			background: linear-gradient( to bottom, rgba( #14273a, 0.9 ), #14273a );
		}

		@include bp(md) {
			background-image: none;
		}
	}

	&.dbi-plugin-intro--offload {
		background-color: #49263a;
		background-image: url( ../images/bg-offload2.jpg );
		background-position: 33% 0;
		display: flex;
		flex-direction: column;

		h1,
		h2,
		h3 {
			color: palette( os3, primary-alt );
		}

		&::before {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
			background: linear-gradient( to bottom, rgba( #49263a, 0.9 ), #49263a );
		}

		@include bp(md) {
			background-image: none;
		}

		.dbi-plugin-intro__video::before {
			background: linear-gradient( to bottom, rgba( #49263a, 0.9 ), rgba( #49263a, 0.98 ) ) no-repeat;
		}

		.dbi-media.dbi-media--tweet {
			border-color: #4c5861;
		}
	}

	&.dbi-plugin-intro--offload-ses {
		background-color: #3f3a52;
		display: flex;
		flex-direction: column;

		h1,
		h2,
		h3 {
			color: palette( oses, primary-alt );
		}

		h2 {
			margin-top: 4rem;
		}

		&::before {
			display: block;
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
			background: linear-gradient( to bottom, #23263f 0%, #3f3a52 52%, #584b63 100% );
		}

		@include bp(md) {
			background-image: none;
		}

		.dbi-plugin-intro__video::before {
			background: linear-gradient( to bottom, rgba( #564463, 0.9 ), rgba( #564463, 0.98 ) ) no-repeat;
		}

		.dbi-media.dbi-media--tweet {
			border-color: #846d96;
		}
	}

	.dbi-plugin-intro__video {
		position: absolute;
		top: 53px;
		right: 0;
		bottom: 0;
		left: 0;
		overflow: hidden;
		display: none;
		max-height: 800px;

		@include bp(md) {
			display: block;
		}

		@include bp(mx) {
			top: 55px; }

		@include bp(xl) {
			top: 58px; }

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			//background-color: rgba(palette(slate,300),0.8);
			background: linear-gradient( to bottom, rgba( #14273a, 0.9 ), rgba( #14273a, 0.98 ) ) no-repeat;
		}

		video {
			min-width: 100%;
			min-height: 100%;
			z-index: 1;
		}
	}

	.dbi-plugin-intro__content {
		position: relative;
		padding: 2.5rem 5% 0 5%;
		color: #fff;
		display: flex;
		flex-direction: column;
		z-index: 100;

		@include bp(md) {
			left: 50%;
			width: 100%;
			max-width: $max-width;
			transform: translateX( -50% );
			padding-top: 3rem;
		}

		@include bp(mx) {
			padding-top: 5rem;
		}

		@include bp(lg) {
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
		}

		@include bp(1220px) {
			padding-left: 0;
			padding-right: 0;
		}

		.dbi-plugin-intro__content__text {
			text-align: center;

			h2,
			h3 {
				margin: 1rem 0;
			}
		}

		.dbi-plugin-intro__content__text {
			@include bp(md) {
				text-align: left;

				h2 {
					margin: 0;
				}

				h3 {
					margin-top: 0.2rem;
				}
			}
		}

		.dbi-plugin-intro__content--desktop {
			display: none;

			@include bp(md) {
				display: block;
			}
		}

		.dbi-plugin-intro__content--mobile {
			margin: 0;
			display: flex;
			align-self: center;

			@include bp(md) {
				display: none;
			}

			> .icon {
				display: block;
				margin: 1.5rem 0 0;
				fill: #fff;
				width: 100px;
				height: 100px;
			}

			> div {
				> a:nth-of-type(2) {
					margin-right: auto;

					@include bp(sm) {
						margin-right: 0;
					}
				}
			}

			.dbi-plugin-intro__content--mobile__buttons {
				margin: 0.5rem auto 0 auto;
				display: flex;
				flex-direction: column;

				@include bp(sm) {
					flex-direction: row;
				}

				a {
					margin: 0 auto 0.75rem auto;
					text-align: center;
					width: 100%;

					@include bp(sm) {
						width: initial;
						margin: 0 1rem;
						margin-left: 0;
					}
				}
			}
		}

		.menu {
			order: 2;

			@include bp(md) {
				order: 1;
			}
		}

	} // content

	h1,
	h2,
	h3 {
		order: 1;
		font-weight: 400;

		@include bp(lg) {
			padding-right: $column-width*2;
		}
	}

	h1 {
		font-size: 1.2rem;
		text-transform: uppercase;
		margin-top: 1rem;

		@include bp(sm) {
			margin-top: 2rem;
		}

		@include bp(lg) {
			font-size: 1.6rem;
			//margin-top: 12.5rem;
			//margin-top: 7.5rem;
		}
	}

	h2 {
		font-size: 1.4rem;
		margin-bottom: 0;
		font-weight: 700;

		@include bp(lg) {
			font-size: 1.8rem;
		}
	}

	h3 {
		font-size: 1.1rem;
		font-weight: 400;
		line-height: 1.3;

		@include bp(lg) {
			font-size: 1.02rem;
		}
	}

} // intro

.dbi-plugin-intro__play {
	background: rgba( black, 0.25 );
	width: 5.5rem;
	height: 5.5rem;
	border-radius: 50%;
	display: block;
	margin: 2.5rem auto 3rem auto;
	position: relative;

	&:hover {
		background-color: palette();
	}

	@include bp(lg) {
		margin-top: 5rem;
		margin-bottom: 3rem;
	}

	.icon {
		position: absolute;
		top: 50%;
		left: 52%;
		transform: translate( -50%, -50% );
		width: 2.5rem;
		height: 2.5rem;
		fill: #fff;
	}

} // play

.lity-close--bottom {
	display: none;
}

.lity-iframe .lity-container,
.lity-youtube .lity-container {
	max-width: 1106px;
}

.lity-iframe-container {
	padding-top: 56%; /* 16:9 ratio */
}
