.dbi-latest-post {
	border-top: 1px solid palette( grey, 700 );
	position: relative;
	padding: 1.5rem 0 1.5rem 0;
	max-width: 42%;
	width: 100%;

	.dbi-latest-post__header {
		display: flex;
		justify-content: space-between;
	}

	.dbi-latest-post__title {
		font-size: 0.8rem;
		line-height: 1.5625;
		margin-bottom: 0.5rem;

		a {
			color: palette( slate, 300 );

			&:hover {
				color: palette( slate );
			}
		}
	}

	.dbi-latest-post__meta {
		flex: 0 0 4rem;
		text-align: right;
	}

	&::before {
		content: "";
		position: absolute;
		top: -1px;
		left: -99rem;
		width: 999rem;
		height: 1px;
		background-color: palette( grey, 700 );
	}

	.dbi-blog__post__comment-count {
		width: 2rem;
		height: 2rem;
		line-height: 2rem;
	}

	.greyscale {
		width: 1.5rem;
		height: 1.5rem;
	}

	.author {
		span {
			font-size: 0.6rem;
			margin-left: 0.5rem;
			color: palette( grey, 400 );
		}
	}
}

@include bp(max sm) {
	.dbi-latest-post {
		max-width: 100%;
	}
}
