/**
 * Block.
 */
.dbi-plugin-card {
	display: flex;
	overflow: hidden;
	margin: 4rem auto 0 auto;
	padding-top: 3.25rem;
	min-height: 380px;
	width: 100%;
	border-radius: 1rem;
	background-color: #2c2a37;
	position: relative;

	&:first-of-type {
		margin-top: 0;
	}

	@media ( max-width: $large-max-width ) {
		flex-direction: column;
		min-height: initial;
	}

	@media ( max-width: $small-max-width ) {
		margin-top: 3rem;
	}

	&__content {
		padding: 0 0 2.1rem 3.25rem;
		display: flex;
		flex-direction: column;
		font-family: "Sofia Pro";
		max-width: 540px;

		@media ( max-width: 1100px ) {
			max-width: 420px;
		}

		@media ( max-width: $large-max-width ) {
			max-width: 100%;
			margin: 0 auto;
			padding-right: 3.25rem;
		}

		@media ( max-width: $small-max-width ) {
			text-align: center;
			padding: 0 1.25rem 1rem 1.25rem;
		}
	}

	&__title {
		display: block;
		font-size: 1.2rem;
		font-weight: 600;
		line-height: 1.3;
		text-transform: none;
		color: #fff;
		padding-bottom: 1.5rem;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	&__desc {
		font-size: 17px;
		font-weight: 500;
		color: #c2dcf4;
	}

	&__link {
		margin-top: 0.8rem;
		max-width: 192px;
		border-radius: 6px;
		color: #fff;
		font-size: 18px;
		text-align: center;
		text-decoration: none;
		text-transform: none;

		&:hover,
		&:focus {
			text-decoration: none;
		}

		@media ( max-width: $small-max-width ) {
			margin: 0.8rem auto;
		}
	}

	.logo {
		height: 3.25rem;
		width: 3.25rem;
		position: absolute;
		bottom: 24px;
		right: 24px;
		filter: drop-shadow( 0 2px 15px rgba( 0, 0, 0, 0.25 ) );

		@media ( max-width: $large-max-width ) {
			bottom: 32px;
			right: 32px;
		}

		@media ( max-width: $small-max-width ) {
			position: relative;
			right: 0;
			margin: 0 auto;
			order: -1;
			width: 2.75rem;
			height: 2.75rem;
		}
	}

	.image {
		border-radius: 8px 0 0 0;
		position: absolute;
		right: 0;
		bottom: 0;
		max-width: 450px;
		box-shadow: -5px 2px 15px 0 rgba( 0, 0, 0, 0.25 );

		@media ( max-width: 1100px ) {
			max-width: 420px;
		}

		@media ( max-width: $large-max-width ) {
			display: none;
		}
	}
}

/**
 * Elements.
 */

/**
 * Modifiers.
 */
