*,
*::after,
*::before {
	box-sizing: border-box;
}

::-moz-selection {
	background: palette();
	color: #fff;
	text-shadow: none;
}

::selection {
	background: palette();
	color: #fff;
	text-shadow: none;
}

html {
	min-height: 100%;
	font-size: nth( $sizes, 1 ) * 0.1 * 62.5%;
	background-color: palette( slate, 800 );

	// Loop through columnpoints.
	@for $i from 2 through length($breakpoints) {
		@include bp(nth($points, $i)) {
			font-size: nth( $sizes, $i ) * 0.1 * 62.5%;
		}
	}
}

body {
	font-family: ff( "body" );
	font-size: 0.7rem;
	line-height: 1.42857;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-feature-settings: "kern" 1, "onum" 1, "liga" 1;
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	color: palette( slate, 600 );
	background-color: #fff;

	// max-width: 1600px;
	// margin: 0 auto;
}

body,
html {
	position: relative;
	overflow-x: hidden;
}

/**
 * Added these styles to cope with the fact that body is position: relative,
 * which messes up the admin bar positioning when logged in and on a screen
 * width of < 600px
 */
@media (max-width: 600px) {
	html {
		margin-top: 0 !important;
	}
	body.admin-bar {
		padding-top: 46px;
	}
}

a {
	text-decoration: none;
	color: palette();
	transition: color 0.2s $transition;

	&:focus,
	&:hover {
		color: darken( palette(), 15% );
	}

	&:hover {
		text-decoration: underline;
	}
}

p {
	margin: 0 0 1.5rem;

	&.intro {
		font-size: 0.9rem;
		line-height: 1.4444;
		color: palette( grey, 400 );
	}
}

blockquote {
	margin: 0;

	p {
		font-size: 0.8rem;

		@include bp(md) {
			font-size: 0.9rem;
		}
	}

	cite {
		font-style: normal;
		font-size: 0.7rem;
		line-height: 1.2;
	}
}

hr {
	background-color: palette( grey, 700 );
	border: 0;
	height: 1px;
	margin: 1.5rem 0;
}

abbr[title] {
	text-decoration: none;
}

code {
	background-color: #ebebec;
	border-radius: 6px;
	color: #373636;
	padding: 4px 6px;
	font-size: 0.625rem;
}
