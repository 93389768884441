/*
	Tried using flexbox to get equal heights
	but then had rendering problems with the border
	being hidden on the left-most edge of the carousel.
	Fixed equal heights with JS instead.
*/

.dbi-plugin-tweets {
	margin-top: 2rem;
	order: 1;

	@include bp(sm) {
		margin-top: 4rem;
	}
}

.dbi-testimonials .dbi-plugin-tweets {
	margin-top: 0;
}

.dbi-plugin-tweets--grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.dbi-media {
		margin: 0 0 1.5rem;
		padding: 1rem 1.5rem 1rem 1rem;
		width: 100%;

		@include bp(md) {
			width: 48.5%;
			width: calc( 50% - 0.75rem );
		}
	}
}

.dbi-plugin-tweets-section {
	background: url( ../images/bg_welcome.jpg );
	background-size: cover;
	padding: 2.5rem 0;
	color: #fff;

	.dbi-section-title {
		text-align: center;
		flex: 0 0 100%;
		margin-bottom: 1rem;
		color: #fff;

		@include section-title;

		&::after {
			background-color: #fff;
		}
	}

	.dbi-stars {
		text-align: center;
	}

	.dbi-plugin-tweets {
		margin-top: 2rem;
	}

	.dbi-plugin-tweets-section__cta {
		margin: 1rem auto 0;
	}
}
