$mdb-bg-color: #bbe5f5;

.dbi-features-addons--migrate {
	background: $mdb-bg-color url( "../images/mdb/brand-footer.svg" ) no-repeat;
	background-position: 50% 100%;
	background-size: 613px 197px;

	@include bp(md) {
		background-size: 2453px 789px;
	}

	.dbi-features-testimonial {
		color: #002040;

		.dbi-features-testimonial__view-pricing .dbi-btn {
			background-color: #081f3d;
			display: inline-flex;
			align-items: center;

			&:hover {
				background-color: darken( #081f3d, 10% ); }
		}
	}
}

.dbi-plugin-hero-migrate {
	position: relative;

	.dbi-plugin-hero-migrate__background__left {
		background: #7fb9ce url( "../images/mdb/birds.png" ) no-repeat 150% 50%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 0;
		width: 70%;
		display: none;

		@include bp($max-width) {
			display: block;
		}
	}

	.dbi-plugin-hero-migrate__background__right {
		background: $mdb-bg-color;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 1;
		width: 20%;
		display: none;

		@include bp($max-width) {
			display: block;
		}
	}

	.dbi-plugin-hero-migrate__content {
		position: relative;
		z-index: 2;

		@include bp($max-width) {
			display: flex;
			justify-content: space-between;
			max-width: $max-width;
			margin: 0 auto;
		}
	}

	.dbi-plugin-hero-migrate__headline {
		background: #79b5cb url( "../images/mdb/birds.png" ) no-repeat;
		background-position: right center;
		background-size: cover;
		width: 100%;
		padding: 2rem 5%;

		@include bp(md) {
			padding: 40px;
		}

		@include bp($max-width) {
			background: transparent;
			padding: 100px 50px 170px 40px;
		}

		@include bp(1250px) {
			padding-left: 0;
		}

		h3 {
			font-family: ff( "headings" );
			font-size: 1.2rem;
			font-weight: 600;
			line-height: 1.3;
			text-transform: uppercase;
			color: #002040;
			margin-bottom: 1rem;
		}

		ul {
			font-family: ff( "headings" );
			font-weight: 500;
			font-size: 0.9rem;
			color: #fff;
			margin: 0;
			padding: 0;
			text-shadow: 0 0 2px #6fa5b9;
			padding-left: 1.3rem;

			li {
				line-height: 1.2;
				margin-bottom: 1rem;

				&:last-of-type {
					margin-bottom: 0.3rem;
				}
			}

			@include bp($max-width) {
				list-style: none;
				padding-left: 0;

				li::before {
					content: "\2022";
					font-size: 35px;
					line-height: 1;
					padding: 0 14px;
				}
			}
		}
	}

	.dbi-features-testimonial {
		padding: 100px 40px 40px;
		background: $mdb-bg-color;

		@include bp($max-width) {
			width: 550px;
			padding: 130px 40px 130px 50px;
		}

		@include bp(1250px) {
			padding-right: 0;
		}
	}
}

@media ( min-width: 2000px ) {
	.dbi-plugin-hero-migrate .dbi-plugin-hero-migrate__background__left {
		width: 60%;}
}

.dbi-features--migrate {
	position: relative;
	z-index: 2;

	@include bp($max-width) {
		transform: translateY( -95px );
	}

	.dbi-features__content {
		background: linear-gradient( to bottom, #f0f8fc 0%, #fff 370px, #fff 100% );
		position: relative;

		.dbi-features__content__preview {
			max-width: 360px;
			position: absolute;
			top: -50px;
			right: 0;
			z-index: 1;
			box-shadow: -10px 10px 50px rgba( 0, 0, 0, 0.2 );
			display: none;

			@include bp($max-width) {
				display: block;
			}
		}
	}

	@include bp(md) {
		.dbi-features__feature.dbi-features__feature--backup .dbi-features__feature__content {
			margin-bottom: 62px; }

		.dbi-features__feature.dbi-features__feature--progress .dbi-features__feature__content {
			margin-bottom: 255px;

			.dbi-features__feature__content__image {
				max-width: 770px;
				border-top-left-radius: 5px;
			}
		}
	}

	@include bp($max-width) {
		.dbi-features__feature--push-pull,
		.dbi-features__feature--find-replace,
		.dbi-features__feature--backup {
			.dbi-features__feature__content__image:hover {
				z-index: 2;
				box-shadow: -7px 7px 30px rgba( 0, 0, 0, 0.2 );
			}
		}
	}

	&.dbi-features--addons {
		transform: none;

		.dbi-features__feature {
			padding: 2rem 5%;

			@include bp(md) {
				padding: 40px;
			}
		}

		.dbi-features__feature__content {
			h3 {
				margin-bottom: 0; }

			.dbi-features__feature__content__image {
				max-width: 100%;

				@include bp(md) {
					max-width: 770px;
				}
			}
		}

		.dbi-features__feature--mf-addon {
			.dbi-features__feature__content__logo {
				max-width: 60px; }

			@include bp(md) {
				.dbi-features__feature__content__image {
					max-width: 460px;
					top: 80px;
				}

				.dbi-features__feature__content {
					margin-bottom: 148px; }
			}
		}

		.dbi-features__feature--cli-addon {
			.dbi-features__feature__content__logo {
				max-width: 60px; }

			.dbi-features__feature__content__image-wrap {
				overflow: hidden;
				padding-bottom: 75%;
				position: relative;
			}

			.dbi-features__feature__content__image {
				max-width: none;
				width: 200%;
				position: absolute;
				top: 0;
				left: 0;
			}

			@include bp(md) {
				padding-bottom: 0;
				margin-bottom: 70px;

				.dbi-features__feature__content__image-wrap {
					overflow: visible;
					max-height: none;
					padding-bottom: 0;
					position: static;
				}

				.dbi-features__feature__content__image {
					max-width: 770px;
					width: 770px;
					left: 360px;
				}

				.dbi-features__feature__content {
					margin-bottom: 153px; }
			}
		}

		.dbi-features__feature--mst-addon {
			.dbi-features__feature__content__logo {
				max-width: 50px; }

			@include bp(md) {
				.dbi-features__feature__content__image {
					max-width: 460px;
					top: 80px;
				}

				.dbi-features__feature__content {
					margin-bottom: 120px; }
			}
		}

		.dbi-features__feature--tpf-addon {
			.dbi-features__feature__content__logo {
				max-width: 50px; }

			@include bp(md) {
				.dbi-features__feature__content__image {
					max-width: 460px;
					top: 80px;
				}

				.dbi-features__feature__content {
					margin-bottom: 120px; }
			}
		}
	}
}

.dbi-hosting-partners {
	padding: 2rem 0 3rem;

	h2 {
		text-align: center;
		flex: 0 0 100%;
		margin-bottom: 1rem;

		@include section-title;
	}

	@include bp(md) {
		padding: 4rem 0 3rem;

		h2 {
			margin-bottom: 3rem;
		}
	}

	.dbi-hosting-partners__intro {
		font-size: 0.8rem;
		margin: 0 auto 2rem;
		max-width: 930px;
		text-align: center;
	}

	.dbi-hosting-partners__logos {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		max-width: $max-width;
		margin: 0 auto;
	}

	.dbi-hosting-partners__logo {
		padding-left: 5%;
		padding-right: 5%;
		margin-bottom: 2rem;
		text-align: center;

		img {
			width: 100%;
			max-width: 163px;
		}

		@include bp(300px) {
			flex: 0 0 50%;
			max-width: 50%;
			padding-left: $gutter;
			padding-right: $gutter;
		}

		@include bp(500px) {
			flex: 0 0 33.333%;
			max-width: 33.333%;
		}

		@include bp(lg) {
			img {
				max-width: 200px;
			}
		}
	}
}

body.wp-migrate-db-pro .dbi-testimonials,
body.wp-migrate-db-pro .dbi-plugin-tweets-section {
	.dbi-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

.dbi-more-features {
	background: linear-gradient( to bottom, #f0f8fc 0%, #f0f8fc 205px, #fff 580px, #fff 100% );

	.dbi-more-features__header {
		background: #fff;
		padding: 40px 0;

		h2 {
			text-align: center;
			margin: 1rem 0 0;
			font-family: ff( "headings" );
			font-weight: 500;
			color: #4d5b67;
			text-transform: uppercase;

			@include section-title;
		}
	}

	.dbi-more-features__content {
		max-width: $max-width;
		position: relative;
		left: 50%;
		transform: translateX( -50% );
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		padding: 4rem 5% 1rem;
		justify-content: center;

		@include bp(1220px) {
			padding-left: 0;
			padding-right: 0;
		}

		.dbi-feature {
			text-align: center;
			color: #062240;
			padding-left: $gutter-width;
			padding-right: $gutter-width;
			margin-bottom: 3rem;

			@include bp(375px) {
				flex: 0 0 50%;
				max-width: 50%;
			}

			@include bp(md) {
				flex: 0 0 33.333%;
				max-width: 33.333%;
			}

			.dbi-feature__icon__wrap {
				background: #8ebcd2;
				border-radius: 100%;
				display: inline-block;
				width: 75px;
				height: 75px;
				position: relative;
				margin-bottom: 1rem;
				text-align: center;

				@include bp(md) {
					width: 125px;
					height: 125px;
				}
			}

			.dbi-feature__icon {
				width: 30px;
				height: 75px;
				fill: #c5e5f5;

				@include bp(md) {
					width: 53px;
					height: 125px;
				}
			}

			p {
				margin-bottom: 0; }

			.dbi-feature__title {
				font-size: 0.8rem;
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: 0.6rem;
			}
		}
	}
}

.beta-label {
	background: $mdb-bg-color;
	text-transform: uppercase;
	border-radius: 4px;
	position: absolute;
	margin: -0.1rem 0 0 0.3rem;
	padding: 0.2rem 0.3rem;
	font-size: 0.55rem;
}
