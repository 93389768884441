.dbi-videos {
	.dbi-video-viewer-wrap {
		max-width: 980px;
		margin: 6em auto;

		@media ( max-width: 1024px ) {
			margin: 3em 5%;
		}
	}

	.dbi-video-viewer {
		position: relative;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.dbi-video {
		overflow: hidden;
		position: relative;
		border-bottom: 1px solid #ddd;
		padding-bottom: 6em;
		margin-top: 6em;

		&:first-of-type {
			border-top: 1px solid #ddd;
			padding-top: 6em;
		}

		&:last-of-type {
			border: 0;
		}

		@media ( max-width: 1024px ) {
			padding-bottom: 3em;
			margin-top: 3em;

			&:first-of-type {
				padding-top: 3em;
			}
		}

		.dbi-video--wrap {
			max-width: 980px;
			margin: 0 auto;

			@media ( max-width: 1024px ) {
				margin-left: 5%;
				margin-right: 5%;
			}
		}

		.dbi-video--screenshot {
			width: 380px;
			margin-right: 4em;
			float: left;

			a {
				display: block;
				position: relative;
				height: 0;
				overflow: hidden;
				padding-bottom: 56.25%;
				background-repeat: no-repeat;
				background-position: center center;
				background-size: cover;
			}
		}

		p {
			margin-bottom: 0; }

		@media ( max-width: 720px ) {
			.dbi-video--screenshot {
				width: 100%;
				float: none;
				margin-bottom: 2em;
			}
		}
	}

	.dbi-videos--btn-wrap {
		max-width: 980px;
		margin: 0 auto 6em;

		@media ( max-width: 1024px ) {
			margin: 0 5% 3em;
		}
	}
}

.page-template-page-product-videos-mdb .dbi-videos .dbi-video .dbi-video--screenshot {
	width: 500px;
}

.dbi-header__video {
	position: relative;
	padding: 2rem 0 0 0;
	color: #fff;
	display: flex;
	flex-direction: column;
	z-index: 100;

	@include bp(sm) {
		padding: 2rem 5% 0 5%;
	}

	@include bp(md) {
		left: 50%;
		width: 100%;
		max-width: $max-width;
		transform: translateX( -50% );
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}
}

.dbi-header__video-wrapper {
	position: relative;

	*::selection {
		background: transparent;
	}

	.dbi-header__video-image-wrap {
		position: relative;
		height: 100%;

		.dbi-header__video-image {
			width: 180%;
			margin-left: -23%;

			@include bp(sm) {
				width: 100%;
				margin: 0;
			}
		}
	}

	.dbi-plugin-intro__play {
		background: rgba( 36, 38, 43, 0.5 );
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -1.25rem 0 0 -1.25rem;
		height: 4.478rem;
		width: 4.478rem;

		&:hover {
			background: palette( brand, 500 ); }

		.icon {
			height: 2rem;
			width: 2rem;
		}
	}

	.dbi-header__video-title {
		color: #fff;
		position: absolute;
		box-shadow: 0 3.565rem 2.304rem 0 rgba( 0, 0, 0, 0.78 ) inset;
		height: 100%;
		top: 0;
		width: 100%;
		font-size: 0.6957rem;
		line-height: 1.5;
		padding: 0.5rem 1rem;
		vertical-align: middle;
		text-align: left;

		.icon-logo-wrap {
			float: left;
			background: #24262b;
			border-radius: 50%;
			height: 1.4rem;
			width: 1.4rem;
			margin: 0 0.8rem 2rem 0;
			text-align: center;
			display: inline-block;

			.icon-logo {
				width: 0.8rem;
				height: 0.8rem;
				vertical-align: text-bottom;
				margin: 0 auto;
				fill: palette();
			}
		}

		.dbi-header__video-title-text {
			float: left;
			width: 80%;
		}

		@include bp(sm) {
			width: 100%;
			line-height: 2;
		}
	}
}
