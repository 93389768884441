.dbi-faq {
	position: relative;
	z-index: 10;
	padding-left: 5%;
	padding-right: 5%;

	@include bp(md) {
		left: 50%;
		width: 100%;
		max-width: $max-width;
		transform: translateX( -50% );
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	&__anchor {
		position: absolute;
		top: -100px; // To account for fixed header
	}

	&__question {
		font-family: ff( "body" );
		font-size: 0.7rem;
		font-weight: bold;
		letter-spacing: 0;
		margin: 0 0 0.8rem;
		color: palette( grey, 200 );
		line-height: 1.7;

		span {
			transition: background-color 0.5s cubic-bezier( 0.165, 0.84, 0.44, 1 );
			position: relative;
		}

		.dbi-faq__question__anchor {
			display: none;
			position: absolute;
			bottom: -4px;
			right: -18px;
		}

		&:hover {
			.dbi-faq__question__anchor {
				display: inline;

				&:hover {
					text-decoration: none; }
			}
		}
	}

	&__answer {
		color: palette( grey, 200 );
		line-height: 1.5;
		margin-bottom: 2rem;

		p:not(:last-child) {
			margin-bottom: 0.8rem;
		}

		small {
			font-size: 90%;
			line-height: 1.5;
		}
	}

	&__pair--hidden {
		display: none;
	}

	&--show-hidden {
		.dbi-faq__pair--hidden {
			display: block;
		}
	}

	&__pair--highlight &__question span {
		background-color: rgb( 255, 248, 185 );
		border-radius: 3px;
	}
}

.dbi-faq-cta {
	background: url( ../images/bg_welcome.jpg );
	background-size: cover;
	padding: 2rem 0;
	color: #fff;

	p {
		font-family: "Sofia Pro", sans-serif;
		text-align: center;
		margin: 0 auto;
		font-size: 1.2rem;
		font-size: 1.15rem;
		margin-top: -5px;
		line-height: 1;
	}
}

.page-template-page-wp-offload-media-pricing,
.page-template-page-wp-offload-ses-pricing {
	.dbi-faq-cta {
		background: palette() url( "../images/bg-pink-texture.jpg" ) center center no-repeat;
		background-size: cover;
		color: #fff;

		a {
			color: #f9d0af;
		}
	}
}
