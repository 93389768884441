$sofia_font_path: "../fonts/" !default;

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-UltraLight.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-UltraLight.woff" ) format( "woff" );
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-UltraLight-Italic.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-UltraLight-Italic.woff" ) format( "woff" );
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-ExtraLight.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-ExtraLight.woff" ) format( "woff" );
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-ExtraLight-Italic.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-ExtraLight-Italic.woff" ) format( "woff" );
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Light.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Light.woff" ) format( "woff" );
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Light-Italic.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Light-Italic.woff" ) format( "woff" );
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Regular.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Regular.woff" ) format( "woff" );
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Regular-Italic.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Regular-Italic.woff" ) format( "woff" );
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Medium.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Medium.woff" ) format( "woff" );
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Medium-Italic.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Medium-Italic.woff" ) format( "woff" );
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-SemiBold.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-SemiBold.woff" ) format( "woff" );
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-SemiBold-Italic.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-SemiBold-Italic.woff" ) format( "woff" );
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Bold.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Bold.woff" ) format( "woff" );
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Bold-Italic.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Bold-Italic.woff" ) format( "woff" );
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Black.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Black.woff" ) format( "woff" );
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Sofia Pro";
	src:
		url( "#{$sofia_font_path}SofiaPro-Black-Italic.woff2" ) format( "woff2" ),
		url( "#{$sofia_font_path}SofiaPro-Black-Italic.woff" ) format( "woff" );
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}
