.dbi-stars {
	padding-right: $gutter-width;
	padding-bottom: 0.5rem;
	width: 100%;
	text-align: right;

	@include bp(md) {
		padding-bottom: 1.5rem;
	}

	.icon {
		width: 0.7rem;
		height: 0.7rem;
		fill: palette( slate, 600 );
		margin-left: 0.125rem;
	}
}
