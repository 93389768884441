.wcss-LicenceActivations {
	background-color: #fff;
	margin-bottom: 1.25rem;
	padding: 1.5rem 1.75rem 1.25rem;
	border-radius: 8px;
	box-shadow: -10px 10px 20px 0 rgba( 30, 30, 30, 0.05 );
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	h1,
	h2 {
		font-family: Lato, sans-serif;
		font-size: 20px;
		font-weight: 700;
		line-height: 1rem;
		margin-bottom: 1.75rem;
		text-transform: none;
		color: #424242;
		letter-spacing: 0;
	}

	h2 {
		margin-bottom: 0.5rem;
	}
}

.wcss-LicenceActivations-return-link {
	margin-top: 1rem;
	margin-bottom: 0;
}

.wcss-LicenceActivationsTable-wrapper {
	margin-top: 0.5rem;
}

.wcss-LicenceActivationsTable {
	.wcss-LicenceActivationsTable-media-items-header {
		text-align: center;
	}

	.wcss-LicenceActivationsTable-media-items {
		text-align: center;
	}

	.wcss-LicenceActivationsTable-last-api-request {
		white-space: nowrap;
	}

	tr.activation.inactive {
		color: #bbb3b9;
	}

	td:last-child,
	th:last-child {
		text-align: right;
	}
}

.sites-limit-change-notice {
	font-weight: normal;
}

.licence-info {
	margin: 0 0 1rem;

	p {
		margin: 0;
	}

	.-title {
		font-weight: 600;
		margin-bottom: 0.3rem;
	}

	.licence-key {
		font-family: "Courier New", monospace;
		font-weight: 600;
		font-size: 0.7rem;
		display: inline;
	}
}

.woocommerce form.wcss-exempt-domain {
	margin-top: 1.5rem;

	.wcss-exempt-domain-help,
	a {
		margin-left: 6px;
	}

	svg {
		width: 21px;
		height: 21px;
		vertical-align: sub;
	}

	label {
		color: inherit;
		display: inline-block;
		font-weight: 600;
	}

	.wcss-exempt-domain-edit {
		display: flex;

		input[type="text"] {
			width: 80%;
		}

		.button {
			font-weight: normal;
			padding: 0.8em 1rem;
			margin-left: 10px;
		}
	}
}

.woocommerce-message {
	&.my-account-notice {
		font-weight: normal;
	}
}
