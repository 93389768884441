$os3-bg-color: #f9d5b6;

body.wp-offload-media {
	.dbi-testimonials .dbi-btn {
		margin-top: 3rem;

		&.dbi-btn--os3 {
			background-color: #f9d0af;
			border-color: #f9d0af;
			display: flex;
			align-items: center;

			&:hover,
			&:focus,
			&:active {
				background-color: #f4a668;
				border-color: #f4a668;
			}
		}
	}

	.dbi-spacer-testimonial.dbi-spacer-testimonial--bottom {
		background: $os3-bg-color url( "../images/offload/brand-footer.svg" ) no-repeat;
		background-position: -143px 101%;
		background-size: 613px 197px;

		@include bp(md) {
			background-position-x: -290px;
			background-size: 1226px 394px;
		}

		@include bp(lg) {
			background-position: -445px 110%;
			background-size: 1839px 591px;
			margin: 0 auto;
			padding: 0 0 600px;
		}

		@include bp($max-width) {
			background: none;
			margin-top: -90px;
		}

		.dbi-features-testimonial {
			padding-left: 0;

			@include bp(md) {
				padding-left: 10%;
				padding-right: 10%;
				margin-bottom: 100px;
			}

			@include bp(lg) {
				max-width: 500px;
				padding: 0;
				margin: 0;
				position: absolute;
				bottom: 130px;
				right: 50px;
			}
		}
	}
}

.dbi-features-addons--offload {
	background: $os3-bg-color url( "../images/offload/brand-footer.svg" ) no-repeat;
	background-position: -600px 101%;
	background-size: 2453px 789px;

	.dbi-features-testimonial {
		color: #4f0733;

		.dbi-features-testimonial__content::before {
			background-image: url( "../images/offload/quote.png" );
		}

		.dbi-features-testimonial__view-pricing .dbi-btn {
			background-color: #4f0733;

			&:hover {
				background-color: darken( #4f0733, 10% ); }
		}
	}

	@include bp($max-width) {
		.dbi-spacer-testimonial--bottom {
			margin-top: -90px;
		}
	}
}

.dbi-plugin-hero-offload {
	.dbi-plugin-hero-offload__headline {
		padding: 2rem 5%;

		@include bp(md) {
			padding: 80px 40px;
			max-width: $max-width;
			margin: 0 auto;
		}

		.dbi-plugin-hero-offload__speedometer {
			max-width: 120px;
			margin: 0 0 1rem;

			@include bp(md) {
				max-width: 160px;
				margin: 0;
				float: left;
				transform: translate( -8px, -10px );
			}

			svg path {
				fill: #ea705e !important;
			}
		}

		.dbi-plugin-hero-offload__summary {
			font-family: ff( "headings" );
			color: #4f0733;
			font-size: 20px;
			line-height: 1.4;
			margin: 0;

			@include bp(md) {
				font-size: 1rem;
				text-align: left;
				margin: 0 0 0 210px;
			}
		}
	}

	.dbi-plugin-hero-offload__content {
		background: #e26857 url( "../images/offload/hero-bg.jpg" ) no-repeat;
		background-size: cover;
		color: #fff;

		.dbi-plugin-hero-offload__content__inner {
			padding: 2rem 5% 1.5rem;

			@include bp($max-width) {
				max-width: $max-width;
				margin: 0 auto;
				padding: 80px 40px 265px;
			}
		}

		.dbi-plugin-hero-offload__content__row {
			margin: 0 0 1.5rem;
			position: relative;

			@include bp(md) {
				display: flex;
				flex-wrap: wrap;
				margin: 0 0 40px 210px;
			}

			&:first-of-type {
				padding-bottom: 1.5rem;
				border-bottom: 1px solid rgba( 255, 255, 255, 0.1 );

				@include bp(md) {
					padding-bottom: 40px;
				}
			}

			&:last-of-type {
				margin-bottom: 0; }
		}

		.dbi-plugin-hero-offload__content__icon {
			max-width: 130px;
			margin-bottom: 1rem;

			@include bp(md) {
				position: absolute;
				top: 0;
				left: -210px;
				margin-bottom: 2rem;
				max-width: 150px;
			}

			svg path {
				fill: #7b233d !important;
			}
		}

		.dbi-plugin-hero-offload__content__header {
			width: 100%;

			h4 {
				font-family: ff( "headings" );
				font-weight: bold;
				color: $os3-bg-color;
				font-size: 28px;
				line-height: 1.4rem;
				text-transform: uppercase;
				margin: 0 0 1rem;
				max-width: 600px;

				@include bp(md) {
					font-size: 32px;
					margin: 0 0 40px;
				}
			}
		}

		.dbi-plugin-hero-offload__content__item {
			margin-bottom: 1rem;

			&:last-of-type {
				margin-bottom: 0; }

			@include bp(md) {
				flex: 1;
				width: 50%;
				margin-bottom: 0;

				&:last-of-type {
					margin-left: 60px; }
			}

			h5 {
				font-family: ff( "headings" );
				font-weight: bold;
				font-size: 16px;
				line-height: 1.6rem;
				text-transform: uppercase;
				margin-bottom: 0;

				@include bp(md) {
					margin-bottom: 0.5rem;
				}
			}

			p {
				font-size: 15px;
				margin-bottom: 0;
			}
		}
	}
}

.dbi-features--offload {
	position: relative;
	z-index: 2;
	overflow: visible;

	@include bp($max-width) {
		transform: translateY( -175px );
	}

	.dbi-features__content {
		background: linear-gradient( to bottom, #fdf4ed 0%, #fff 370px, #fff 100% );
	}

	.dbi-features__header h2 {
		color: #4f0733; }

	.dbi-features__feature {
		.dbi-features__feature__content {
			h3,
			p.lead {
				color: #4f0733; }

			@include bp(md) {
				.dbi-features__feature__content__image {
					left: 400px;
				}
			}
		}

		&.dbi-features__feature--assets h3 {
			margin-bottom: 0; }

		&.dbi-features__feature--media-library {
			padding-top: 73px;

			@extend %cf;

			.owl-carousel,
			.owl-dots {
				display: none; }

			.owl-carousel:not(.owl-loaded) {
				opacity: 0;
			}

			@include bp($max-width) {
				padding-right: 0;
				overflow: visible;

				.dbi-features__feature__content {
					float: left; }

				.dbi-features__feature__content__image {
					display: none; }

				.owl-carousel {
					display: block;
					float: right;
					width: 730px;

					.owl-item img {
						width: 100%;
						height: auto;
					}
				}

				.owl-dots {
					display: block;
					text-align: left;

					.owl-dot {
						cursor: pointer; }
				}

				.owl-prev,
				.owl-next {
					position: absolute;
					top: 236px;
					left: -29px;
					width: 58px;
					height: 58px;
					line-height: 58px;
					transform: translateY( -50% );
					background: #ea705e;
					text-align: center;
					border-radius: 100%;
					box-shadow: 0 10px 70px rgba( 0, 0, 0, 0.15 );

					svg {
						width: 20px;
						height: 58px;
						fill: #fff;
					}
				}

				.owl-next {
					left: auto;
					right: -29px;
				}
			}
		}

		@include bp(md) {
			&.dbi-features__feature--upload {
				padding-top: 133px;

				.dbi-features__feature__content {
					margin-bottom: 150px; }

				.dbi-features__feature__content__image {
					max-width: 331px; }

				.dbi-features__feature__content__image-large {
					max-width: 442px;
					top: -20px;
					left: 689px;
				}
			}

			&.dbi-features__feature--private-media {
				padding-top: 121px;

				.dbi-features__feature__content {
					margin-bottom: 220px; }
			}

			&.dbi-features__feature--remove {
				padding-top: 133px;

				.dbi-features__feature__content {
					margin-bottom: 110px; }

				.dbi-features__feature__content__image {
					max-width: 331px; }

				.dbi-features__feature__content__image-large {
					max-width: 442px;
					top: -20px;
					left: 689px;
				}
			}

			&.dbi-features__feature--download-remove {
				padding-top: 133px;

				.dbi-features__feature__content {
					margin-bottom: 120px; }

				.dbi-features__feature__content__image {
					max-width: 331px; }

				.dbi-features__feature__content__image-large {
					max-width: 442px;
					top: -20px;
					left: 689px;
				}
			}

			&.dbi-features__feature--copy {
				padding-top: 130px;

				.dbi-features__feature__content {
					margin-bottom: 85px; }

				.dbi-features__feature__content__image {
					max-width: 331px; }

				.dbi-features__feature__content__image-large {
					max-width: 442px;
					top: -20px;
					left: 689px;
				}
			}

			&.dbi-features__feature--move {
				padding-top: 130px;

				.dbi-features__feature__content {
					margin-bottom: 120px; }

				.dbi-features__feature__content__image {
					max-width: 331px; }

				.dbi-features__feature__content__image-large {
					max-width: 442px;
					top: -20px;
					left: 689px;
				}
			}

			&.dbi-features__feature--metadata-tool {
				padding-top: 130px;

				.dbi-features__feature__content {
					margin-bottom: 30px; }

				.dbi-features__feature__content__image {
					max-width: 331px; }

				.dbi-features__feature__content__image-large {
					max-width: 442px;
					top: -20px;
					left: 689px;
				}
			}

			&.dbi-features__feature--assets {
				padding-top: 130px;

				.dbi-features__feature__content {
					margin-bottom: 14px; }

				.dbi-features__feature__content__image {
					max-width: 415px;
					margin-top: 25px;
					top: 10px;
				}

				.dbi-features__feature__content__image-large {
					max-width: 340px;
					left: 791px;
				}
			}
		}
	}
}

.dbi-storage-providers {
	background: linear-gradient( to bottom, #fdf4ed 0%, #fdf4ed 205px, #fff 580px, #fff 100% );

	.dbi-storage-providers__header {
		padding: 40px;
		font-family: ff( "headings" );
		text-align: center;

		h2 {
			margin: 1rem 0 0.5rem;
			font-size: 27px;
			font-weight: bold;
			color: #4f0733;
			text-transform: uppercase;
		}

		p {
			font-size: 0.8rem;
			font-weight: 500;
			color: #666;
			margin: 0 0 1rem;
		}
	}

	.dbi-storage-providers__content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: $max-width;
		margin: 0 auto;
		padding: 20px 20px 0;
	}

	.dbi-provider {
		position: relative;
		margin: 0 $half-gutter 1rem;
		flex: 0 0 50%-$gutter-width;
		max-width: 50%-$gutter-width;
		text-align: center;
		padding: 0 0 1.5rem;

		@include bp(600px) {
			flex: 0 0 33.333%-$gutter-width;
			max-width: 33.333%-$gutter-width;
		}

		@include bp(lg) {
			flex: 0 0 20%-$gutter-width;
			max-width: 20%-$gutter-width;
		}

		&:hover,
		&:focus {
			text-decoration: none;
		}

		.dbi-provider__icon__wrap {
			display: block;
			width: 100px;
			height: 100px;
			border-radius: 100%;
			margin: 0 auto 1rem;
		}

		&.dbi-provider--amazon .dbi-provider__icon__wrap {
			background-color: #f7a80d;
		}

		&.dbi-provider--digitalocean .dbi-provider__icon__wrap {
			background-color: #0080ff;

			.dbi-provider__icon {
				width: 2.5rem;
			}
		}

		&.dbi-provider--google-cloud .dbi-provider__icon__wrap {
			background-color: #fff;

			.dbi-provider__icon {
				width: 4rem;
			}
		}

		.dbi-provider__icon {
			fill: #fff;
			width: 3rem;
			height: 100px;
		}

		h5 {
			color: #444d54;
			text-transform: uppercase;
			font-size: 0.6rem;
			font-weight: bold;
			line-height: 1.2;
		}

		& + .dbi-btn {
			margin-top: 1.5rem;

			@include bp(515px 600px) {
				flex-basis: 51%;
				text-align: center;
			}

			@include bp(820px lg) {
				flex-basis: 42%;
				text-align: center;
			}
		}
	}
}

@include bp(md) {
	.dbi-storage-providers .dbi-provider {
		.dbi-provider__icon__wrap {
			width: 150px;
			height: 150px;

			.dbi-provider__icon {
				height: 150px;
				width: 4rem;
			}
		}

		&.dbi-provider--digitalocean .dbi-provider__icon__wrap .dbi-provider__icon {
			width: 3.5rem;
		}

		&.dbi-provider--google-cloud .dbi-provider__icon__wrap .dbi-provider__icon {
			width: 5rem;
		}
	}
}

.dbi-integrations {
	background: #fdf4ed;
	padding-bottom: 4rem;

	.dbi-ome-integrations {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		margin-bottom: -30px; // To offset the bottom margin on all items

		> a {
			display: flex;
			width: 100%;
			margin-bottom: 30px;
			text-decoration: none;
			color: currentColor;
			background: #fff;
			border: 1px solid #eff0f0;
			box-shadow: 0 1px 4px 0 rgba( 0, 0, 0, 0.05 );
			border-radius: 14px;
			padding: 0.65rem;
			cursor: pointer;
			transition: all 0.3s ease-in-out;

			&:hover {
				box-shadow: 0 5px 12px 0 rgba( 0, 0, 0, 0.2 );
			}

			@include bp(md) {
				width: calc( ( 100% - 60px ) / 3 );

				&:nth-child(3n - 1) {
					margin-left: 30px;
					margin-right: 30px;
				}

				/*
				 * This applies to the 2nd item in the final row if it's the last item and
				 * catches the case of two items on the last row where no right-margin is needed.
				 */
				&:nth-child(3n-2) + a:last-child {
					margin-right: 0;
				}
			}
		}

	}

	.dbi-ome-integration__title {
		display: block;
		text-align: center;
		line-height: 1.2;
		margin: 0 0 0.75rem;
		font-weight: 700;
		text-transform: uppercase;
		color: #4e5c67;

		&:hover {
			color: #ee3b71;
		}
	}

	.dbi-plugin-card__title {
		font-size: 0.75rem;
	}

	.dbi-ome-integration {
		flex: 1;
		margin: 5px;
		width: 25%;

		p {
			text-align: center;
			width: 85%;
			margin: 0 auto;
		}

		.dbi-ome-integration__img {
			text-align: center;
			margin-bottom: 0.5rem;
			height: 5rem;
			justify-content: center;
			display: flex;
			align-items: center;

			img {
				max-height: 80px;
			}

		}
	}

	.dbi-ome-integration--wpml {
		img {
			width: 60%;}
	}

	.dbi-integrations__header {
		padding: 2rem;
		font-family: ff( "headings" );
		text-align: center;

		h2 {
			margin: 1rem 0;
			font-size: 27px;
			font-weight: bold;
			color: #4f0733;
			text-transform: uppercase;
		}

		p {
			font-size: 0.8rem;
			font-weight: 500;
			color: #666;
			margin: 0 0 1rem;
		}
	}

	.dbi-integrations__content {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-width: $max-width;
		margin: 0 auto;
		padding: 80px 20px 0;
	}

	.dbi-addon {
		position: relative;
		margin: 0 $half-gutter 1rem;
		flex: 0 0 50%-$gutter-width;
		max-width: 50%-$gutter-width;
		text-align: center;
		padding: 0 1.5rem 1.5rem;

		@include bp(600px) {
			flex: 0 0 33.333%-$gutter-width;
			max-width: 33.333%-$gutter-width;
		}

		@include bp(lg) {
			flex: 0 0 20%-$gutter-width;
			max-width: 20%-$gutter-width;
		}

		&:hover,
		&:focus {
			text-decoration: none;
		}

		.dbi-addon__icon__wrap {
			display: block;
			width: 125px;
			height: 125px;
			border-radius: 100%;
			margin: 0 auto 1rem;
		}

		&.dbi-addon--woocommerce .dbi-addon__icon__wrap {
			background-color: #a46497;
		}

		&.dbi-addon--easy-digital-downloads .dbi-addon__icon__wrap {
			background-color: #1d2428;
		}

		&.dbi-addon--wpml .dbi-addon__icon__wrap {
			background-color: #277f91;
		}

		&.dbi-addon--enable-media-replace .dbi-addon__icon__wrap {
			background-color: #3c6;
		}

		&.dbi-addon--meta-slider .dbi-addon__icon__wrap {
			background-color: #03b0d8;
		}

		.dbi-addon__icon {
			fill: #fff;
			width: 2rem;
			height: 125px;
		}

		h5 {
			color: #444d54;
			text-transform: uppercase;
			font-size: 0.6rem;
			font-weight: bold;
			line-height: 1.2;
		}

		& + .dbi-btn {
			margin-top: 1.5rem;

			@include bp(515px 600px) {
				flex-basis: 51%;
				text-align: center;
			}

			@include bp(820px lg) {
				flex-basis: 42%;
				text-align: center;
			}
		}
	}
}
