$oses-bg-color: #fff7d1;
$oses-light-bg: #f7f1fe;
$oses-purple: #34203a;
$oses-purple-lighter: #4b2b54;
$oses-dark-yellow-bg: #d19c1b;

body.wp-offload-ses,
body.page-template-page-wp-offload-ses-upgrade,
body.page-template-page-wp-ses-landing {
	.dbi-testimonials .dbi-btn {
		margin-top: 3rem;
	}

	.dbi-plugin-intro__content__text {
		@include bp(lg) {
			margin-top: 5rem;
		}

		h1 {
			font-size: 1.7rem;
			margin-bottom: 0.3rem;

			@include bp(sm) {
				font-size: 2.3rem;
			}
		}

		h2 {
			text-transform: none;
			font-weight: normal;
			font-size: 1.2rem;
			margin-bottom: 0.3rem;

			@include bp(sm) {
				font-size: 1.7rem;
			}
		}

		h3 {
			text-transform: uppercase;
			font-weight: 700;
			font-size: 1.3rem;

			@include bp(sm) {
				font-size: 1.8rem;
			}
		}
	}

	.dbi-spacer-testimonial.dbi-spacer-testimonial--bottom {
		background: $oses-bg-color url( "../images/offload-ses/brand-footer.svg" ) no-repeat;
		background-position: -143px 101%;
		background-size: 613px 197px;

		@include bp(md) {
			background-position-x: -290px;
			background-size: 1026px 294px;
		}

		@include bp(lg) {
			background-position: -545px 0;
			background-size: 1639px 680px;
			margin: 0 auto;
			padding: 0 0 600px;
		}

		@include bp($max-width) {
			background: none;
			margin-top: -90px;
		}

		.dbi-features-testimonial {
			padding-left: 0;

			@include bp(md) {
				padding-left: 10%;
				padding-right: 10%;
				margin-bottom: 100px;
			}

			@include bp(lg) {
				max-width: 500px;
				padding: 0;
				margin: 0;
				position: absolute;
				bottom: 130px;
				right: 50px;
			}
		}
	}

	.dbi-features {
		position: relative;
		z-index: 2;
		overflow: visible;

		@include bp($max-width) {
			transform: translateY( -175px );
		}

		.dbi-features__content {
			background: linear-gradient( to bottom, $oses-light-bg 0%, #fff 370px, #fff 100% );
		}

		.dbi-features__header {
			padding: 1.5rem 5%;
			height: inherit;

			h2 {
				color: $oses-purple;
				line-height: 1.2;
			}
		}

		.dbi-features__feature {
			.dbi-features__feature__content {
				h3,
				p.lead {
					color: $oses-purple; }

				@include bp(md) {
					.dbi-features__feature__content__image {
						left: 400px;
					}
				}
			}

			&.dbi-features__feature--slider {
				@extend %cf;

				.owl-carousel,
				.owl-dots {
					display: none; }

				.owl-carousel:not(.owl-loaded) {
					opacity: 0;
				}

				@include bp($max-width) {
					padding-right: 0;
					overflow: visible;

					.dbi-features__feature__content {
						float: left; }

					.dbi-features__feature__content__image {
						display: none; }

					.owl-carousel {
						display: block;
						float: right;
						width: 730px;

						.owl-item img {
							width: 100%;
							height: auto;
						}
					}

					.owl-dots {
						display: block;
						text-align: left;

						.owl-dot {
							cursor: pointer; }
					}

					.owl-prev,
					.owl-next {
						position: absolute;
						top: 236px;
						left: -29px;
						width: 58px;
						height: 58px;
						line-height: 58px;
						transform: translateY( -50% );
						background: $oses-dark-yellow-bg;
						text-align: center;
						border-radius: 100%;
						box-shadow: 0 10px 70px rgba( 0, 0, 0, 0.15 );

						svg {
							width: 20px;
							height: 58px;
							fill: #fff;
						}
					}

					.owl-next {
						left: auto;
						right: -29px;
					}
				}
			}

			@include bp(md) {
				&.dbi-features__feature--upload {
					.dbi-features__feature__content {
						margin-bottom: 21px; }
				}

				&.dbi-features__feature--setup {
					.dbi-features__feature__content {
						margin-bottom: 255px; }
				}

				&.dbi-features__feature--tracking {
					padding-top: 80px;
				}

				&.dbi-features__feature--support {
					padding-top: 133px;

					.dbi-features__feature__content {
						margin-bottom: 65px; }

					.dbi-features__feature__content__image {
						max-width: 490px; }

					.dbi-features__feature__content__image-large {
						max-width: 430px;
						top: -50px;
						left: 709px;
					}
				}

				&.dbi-features__feature--queuing {
					.dbi-features__feature__content {
						margin-bottom: 91px; }
				}

				&.dbi-features__feature--manual-retry {
					padding-top: 100px;
				}

				&.dbi-features__feature--search {
					.dbi-features__feature__content {
						margin-bottom: 150px; }
				}

				&.dbi-features__feature--view {
					padding-top: 100px;

					.dbi-features__feature__content {
						margin-bottom: 220px; }
				}

				&.dbi-features__feature--engagement {
					padding-top: 100px;

					.dbi-features__feature__content {
						margin-bottom: 200px; }
				}

				&.dbi-features__feature--reporting {
					.dbi-features__feature__content {
						margin-bottom: 140px; }
				}

				&.dbi-features__feature--verify {
					padding-top: 80px;

					.dbi-features__feature__content {
						margin-bottom: 120px; }
				}

				&.dbi-features__feature--logging {
					padding-top: 120px;
				}

				&.dbi-features__feature--multisite {
					padding-top: 120px;

					.dbi-features__feature__content {
						margin-bottom: 310px; }
				}
			}
		}
	}

	.dbi-more-features {
		background: linear-gradient( to bottom, $oses-light-bg 0%, $oses-light-bg 205px, #fff 580px, #fff 100% );
		padding-bottom: 2.5rem;

		.dbi-feature {
			max-width: 100%;
			flex: none;

			@include bp(md) {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}

		.dbi-feature--full {
			margin-bottom: 1rem;

			a {
				font-weight: bold;
				color: $oses-purple;

				&:hover {
					color: $oses-purple-lighter;
				}
			}

			@include bp(md) {
				max-width: 100%;
				flex: none;
			}
		}

		.dbi-more-features__content .dbi-feature .dbi-feature__title {
			line-height: 1.4;
			margin-bottom: 0.3rem;
		}
	}

	.dbi-features-addons {
		background: $oses-bg-color url( "../images/offload-ses/brand-footer.svg" ) no-repeat;
		background-position: -700px 101%;
		background-size: 2053px 589px;

		.dbi-features-testimonial {
			color: $oses-purple;

			.dbi-features-testimonial__content::before {
				background-image: url( "../images/offload-ses/quote.png" );
			}

			.dbi-features-testimonial__view-pricing .dbi-btn {
				background-color: $oses-purple;

				&:hover {
					background-color: $oses-purple-lighter; }
			}
		}

		@include bp($max-width) {
			.dbi-spacer-testimonial--bottom {
				margin-top: -90px;
			}
		}

		.dbi-plugin-hero {
			.dbi-plugin-hero__headline {
				padding: 2rem 5%;

				@include bp(md) {
					padding: 80px 40px 105px;
					max-width: $max-width;
					margin: 0 auto;
				}

				.dbi-plugin-hero__paper-plane {
					max-width: 120px;
					margin: 0 0 1rem;

					@include bp(md) {
						max-width: 150px;
						margin: 0;
						float: left;
						transform: translate( -8px, -10px );
					}

					svg path {
						fill: $oses-purple !important;
					}
				}

				.dbi-plugin-hero__summary {
					font-family: ff( "headings" );
					color: $oses-purple;
					font-size: 20px;
					line-height: 1.4;
					margin: 0 0 1rem;

					@include bp(md) {
						font-size: 22px;
						text-align: left;
						margin: 0 0 1rem 210px;
					}

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.dbi-plugin-hero__content {
				background: $oses-dark-yellow-bg url( ../images/offload-ses/hero-bg.jpg ) no-repeat;
				background-size: cover;
				color: #fff;

				.dbi-plugin-hero__content__inner {
					padding: 2rem 5% 1.5rem;

					@include bp($max-width) {
						max-width: $max-width;
						margin: 0 auto;
						padding: 80px 40px 265px;
					}
				}

				.dbi-plugin-hero__content__row {
					margin: 0 0 1.5rem;
					position: relative;

					@include bp(md) {
						display: flex;
						flex-wrap: wrap;
						margin: 0 0 40px 210px;
					}

					&:first-of-type {
						padding-bottom: 1.5rem;
						border-bottom: 1px solid rgba( 255, 255, 255, 0.1 );

						@include bp(md) {
							padding-bottom: 40px;
						}
					}

					&:last-of-type {
						margin-bottom: 0; }
				}

				.dbi-plugin-hero__content__icon {
					max-width: 130px;
					margin-bottom: 1rem;
					opacity: 0.8;

					@include bp(md) {
						position: absolute;
						top: 0;
						left: -210px;
						margin-bottom: 2rem;
						max-width: 150px;
					}

					svg path {
						fill: $oses-purple !important;
					}
				}

				.dbi-plugin-hero__content__header {
					width: 100%;

					h4 {
						font-family: ff( "headings" );
						font-weight: bold;
						color: $oses-bg-color;
						font-size: 28px;
						line-height: 1.4;
						text-transform: uppercase;
						margin: 0 0 1rem;
						max-width: 650px;

						@include bp(md) {
							font-size: 32px;
							margin: 0 0 40px;
						}
					}
				}

				.dbi-plugin-hero__content__item {
					margin-bottom: 1rem;

					&:last-of-type {
						margin-bottom: 0; }

					@include bp(md) {
						flex: 1;
						width: 50%;
						margin-bottom: 0;

						&:last-of-type {
							margin-left: 60px; }
					}

					h5 {
						font-family: ff( "headings" );
						font-weight: bold;
						font-size: 16px;
						line-height: 1.2;
						text-transform: uppercase;
						margin-bottom: 0;

						@include bp(md) {
							margin-bottom: 0.5rem;
						}
					}

					p {
						font-size: 15px;
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.dbi-hosting-partners {
		.dbi-hosting-partners__logo {
			text-align: center;

			&.dbi-hosting-partners__logo--gravity-forms {
				img {
					max-width: 130px;
				}
			}

			&.dbi-hosting-partners__logo--caldera-forms {
				img {
					max-width: 140px;
				}
			}

		}

		.dbi-button-container {
			margin-top: 2rem;
		}
	}

	.dbi-button-container {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		a {
			margin: 0 auto;
			background-color: $oses-purple;

			&:hover {
				background-color: $oses-purple-lighter;
			}
		}
	}

	.dbi-oses-integrations {
		width: 100%;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-column-gap: 24px;
		grid-row-gap: 24px;
		align-items: center;

		@include bp(md) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include bp(lg) {
			grid-template-columns: repeat(3, 1fr);
		}

		&__logo {
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			border-radius: 6px;

			@include bp(sm) {
				height: 96px;
			}

			@include bp(md) {
				height: 160px;
			}

			svg {
				max-width: 220px;
				max-height: 80px;
				shape-rendering: geometricPrecision;
			}

			&--affiliatewp { background-color: #EB847B; }
			&--contact-form-7 { background-color: #4C62BF; }
			&--easy-digital-downloads { background-color: #66ACDF ; }
			&--email-templates { background-color: #CCE8F5; }
			&--formidable-forms { background-color: #FFD1C5; }
			&--gravity-forms { background-color: #7186A0; }
			&--icegram { background-color: #D6CCEA; }
			&--jetpack { background-color: #CCF1CC; }
			&--ninja-forms { background-color: #F28082; }
			&--restricted-content-pro { background-color: #AA88B5; }
			&--the-newsletter-plugin { background-color: #FFDBDE; }
			&--woocommerce { background-color: #DFD5E8; }
			&--wpforms { background-color: #F2DBCF; }
			&--wp-html-email { background-color: #DCE7EB; }
			&--wp-user-manager { background-color: #6E9FFB; }
		}
	}
}

body.page-template-page-wp-offload-ses-upgrade,
body.page-template-page-wp-ses-landing {
	.dbi-features-addons .dbi-plugin-hero .dbi-plugin-hero__headline {
		@include bp($max-width) {
			padding-bottom: 255px;
		}
	}

	.section--upgrade-view-pricing .dbi-btn {
		background-color: $oses-purple;
		color: #fff;

		&:hover {
			background-color: $oses-purple-lighter;
		}
	}

	.dbi-features-addons .dbi-plugin-hero .dbi-plugin-hero__headline .dbi-plugin-hero__paper-plane {
		margin: 1rem 0 0;
	}

	.dbi-page-header.dbi-page-header--offload-ses {
		padding-bottom: 0;

		.dbi-page-header__title + h2 {
			font-weight: normal;
			text-transform: none;
			margin: 0.5rem 0 1rem;
			font-size: 1rem;
		}
	}

	@include bp(md) {
		.dbi-features .dbi-features__feature.dbi-features__feature--engagement {
			padding-top: 0;

			.dbi-features__feature__content {
				margin-bottom: 120px; }
		}
	}
}
