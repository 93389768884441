.clear {
	margin-left: 0;
	clear: both;
}

%cf {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}

%ir {
	text-indent: 120%;
	overflow: hidden;
	white-space: nowrap;
}

%btn {
	box-sizing: border-box;
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;

	text-decoration: none;
	font-family: ff( "headings" );
	text-transform: uppercase;
	font-size: 14px; // 16px at 100%
	font-weight: 600;
	line-height: 1.3;
	letter-spacing: 0.015rem;
	border: 0;
	border-radius: 0.15rem;
	margin: 0;
	padding: 0.7rem 1rem;

	&:hover,
	&:active,
	&:focus,
	&:visited {
		text-decoration: none;
	}

	&:active,
	&:focus {
		outline: none;
	}
}

%btn-outline {
	border: 1px solid #ee3a71;
	color: #ee3a71;
	background-color: transparent;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	line-height: 1.3;
	align-self: flex-start;

	&:hover {
		color: #fff;
		background-color: #ee3a71;
		border-color: #ee3a71;
	}
}

/*
 * Hide from both screenreaders and browsers: h5bp.com/u
 */

%hidden {
	display: none !important;
	visibility: hidden;
}

/*
 * Hide only visually, but have it available for screenreaders: h5bp.com/v
 */

%visuallyhidden {
	border: 0;
	clip: rect( 0 0 0 0 );
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;

	/*
	 * Extends the .visuallyhidden class to allow the element to be focusable
	 * when navigated to via the keyboard: h5bp.com/p
	 */

	&.focusable:active,
	&.focusable:focus {
		clip: auto;
		height: auto;
		margin: 0;
		overflow: visible;
		position: static;
		width: auto;
	}
}



/*
 * Hide visually and from screenreaders, but maintain layout
 */

%invisible {
	visibility: hidden;
}

.chromeframe {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}
