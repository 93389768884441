.dbi-media {
	@include bp(sm) {
		display: flex;
		align-items: flex-start;
		//flex-direction: column;
		flex-direction: row;
	}

	&.dbi-media--center {
		align-items: center;
		margin-bottom: 1rem;
	}

	.dbi-media__img {
		height: auto;
		margin-bottom: 0.5rem;
		max-width: 90%;
		width: auto;
		margin-right: $gutter-width;

		@include bp(md) {
			width: $column-width;
			margin-bottom: 0;
		}
	}

	.dbi-media__body {
		flex: 1;

		h2 {
			text-transform: capitalize;
		}
	}

	&.dbi-media--tweet {
		padding: 1rem $column-width 1rem 6%;
		border: 1px solid palette( slate, 300 );
		border-radius: 3px;
		display: block;
		position: relative;

		.icon-twitter {
			fill: #fff;
			width: 0.7rem;
			height: 0.6rem;
			position: absolute;
			top: 1.1rem;
			right: calc( #{$column-width} - 0.7rem );
		}

		.dbi-media__img {
			width: 2rem;
			border-radius: 50%;
			margin-right: 1rem;
			height: auto;
			float: left;
		}

		.dbi-media__body {
			padding-left: 60px;
			display: block;
			flex: auto;

			@include bp(sm) {
				padding-left: 70px;
			}
		}

		p {
			margin-bottom: 0;
		}
	} // tweet

	&.dbi-media--tweet-alt {
		border-color: palette( slate, 700 );

		.icon-twitter {
			fill: map-get( $social, twitter );
		}
	}

	&.dbi-media--buzz-tweet {
		border: 0;
		max-width: 100%;
		align-items: center;
		position: relative;

		.dbi-tweet-body {
			font-size: 1.3rem;
			line-height: 1.2;
			color: palette( grey, 400 );

			@include bp(md) {
				font-size: 1.6rem;
			}
		}

		.dbi-media--buzz-tweet__body {
			@include bp(sm) {
				padding-right: $column-width*2;
			}
		}

		.dbi-media--buzz-tweet__img {
			border-radius: 50%;
			width: 25%;

			@include bp(md) {
				width: $column-width;//4.8rem;
				margin-right: $column-width;
				margin-left: $column-width;
			}
		}

		.icon-twitter {
			fill: map-get( $social, twitter );
			width: 2.5rem;
			height: 2rem;
			position: absolute;
			top: 3rem;
			right: $column-width;
		}
	} // buzz tweet

}

.dbi-tweet-by {
	color: palette( grey );
	font-family: ff( body );
	line-height: 1.2;
	margin-bottom: 0.5rem;
}

.dbi-tweet-body {
	a {
		color: palette( blue );
	}
}

.wp-caption {
	position: relative;

	&.alignleft {
		float: left;
		margin: 0 2.7rem 2rem 0;

		@include bp( sm ) {
			float: none;
		}
	}

	&.alignright {
		float: right;
		margin: 0 0 2rem 2.7rem;

		@include bp( sm ) {
			float: none;
		}
	}

	.wp-caption-text {
		background: #f2f2f2;
		border-top: 1px solid #fff;
		padding: 1.3rem 2rem;
		color: #7a7a7a;
	}
}

.wp-caption {
	outline: none;
}

.wp-caption-text {
	outline: none;
}

.sticky {
	outline: none;
}

.gallery-caption {
	outline: none;
}

.bypostauthor {
	outline: none;
}

/**
 * Responsive embed.
 */

.dbi-responsive-embed {
	position: relative;
	height: 0;
	overflow: hidden;
	margin: 1.5rem 0;
	width: 100%;

	&--16x9 {
		padding-bottom: 56.25%;
	}

	iframe,
	object {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
