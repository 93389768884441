.dbi-subscribe-form {
	width: 100%;

	@include bp(md) {
		width: 50%;
		margin: 0 auto;
	}

	.dbi-errors {
		border-left: 3px solid #ee3a71;
		background-color: #fad3e1;
		padding: 0.8rem 1rem;
		font-size: 0.9rem;
		font-weight: bold;
		color: #4e5c67;
		margin: 0 0 1rem 0;

		p,
		ul {
			margin: 0;
		}
	}

	.dbi-field {
		margin-bottom: 1rem;

		input {
			width: 100%;
		}
	}

	button {
		margin-bottom: 1rem;
		width: 100%;
	}

	p.disclaimer {
		text-align: center;

		small {
			font-size: 0.8em;
		}
	}
}
