.dbi-related {
	margin-bottom: 2rem;
	width: 100%;
	padding: 0 5%;

	@include bp(md) {
		max-width: $max-width;
		position: relative;
		left: 50%;
		transform: translateX( -50% );
	}

	@include bp(xl) {
		padding-right: 0;
		padding-left: 0;
	}

	.dbi-related__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 1rem;
		margin-left: -$gutter-width-px*0.5;
		margin-right: -$gutter-width-px*0.5;
		flex-direction: column;

		@include bp(md) {
			flex-direction: row;
		}
	}

	.dbi-related__title {
		margin-left: $gutter-width-px*0.5;
		text-transform: uppercase;
		font-weight: 700;
	}

	.dbi-related__search {
		padding: 0 $gutter-width-px*0.5;

		@include bp(md) {
			flex: 0 0 33.333%;
		}
	}

	.dbi-related__posts {
		display: flex;

		flex-direction: column;

		@include bp(md) {
			flex-direction: row;
			margin-left: -$gutter-width-px*0.5;
			margin-right: -$gutter-width-px*0.5;
		}
	}

	.dbi-related__post {
		// flex: 0 0 100%;
		// max-width: 100%;
		margin-bottom: 1.5rem;

		@include bp(md) {
			flex: 0 0 33.333%;
			max-width: 33.333%;
			margin-bottom: 0;
		}

		&:hover {
			h4 {
				color: palette();
			}
		}

		figure {
			padding: 0 $gutter-width-px*0.5;//$gutter-width;
		}

		img {
			max-width: 100%;
			width: 100%;
			height: auto;
		}

		h4 {
			text-transform: uppercase;
			line-height: 1.714;
			font-size: 0.7rem;
			color: palette( slate );
			font-weight: 700;
			margin-top: 1rem;
		}

	} // post
}
