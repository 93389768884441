.page-template-page-press-resources {
	.dbi-press {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		padding: 4.5rem 1rem;
		justify-content: center;
		text-align: center;
		position: relative;
		margin: 0 auto;
		color: #3f4e5a;
		align-content: center;

		p {
			font-size: 0.75rem;
			max-width: 800px;
			text-align: left;
			line-height: 1.6;
			color: #3f4e5a;

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		a {
			font-weight: 600;
		}

		a:hover {
			color: #ee3b71;
			border-bottom: 1px dashed #ee3b71;
			text-decoration: none;
		}

		a:focus,
		a:hover {
			text-decoration: none;
		}

		&.dbi-press-intro {
			a {
				color: #3f4e5a;
				&:hover {
						border-bottom: 1px dashed #3f4e5a;
				}
			}
		}
		
		a.btn {
			background: palette();

			&:hover {
				text-decoration: none !important;
				border-bottom: none;
				color: #fff;
				background-color: darken( palette(), 15% );
			}

			&:focus {
				color: #fff;
			}
		}

		.container {
			background: #fff;
			border: 1px solid #eff0f0;
			box-shadow: 0 1px 4px 0 rgba( 0, 0, 0, 0.05 );
			border-radius: 14px;
			padding: 0.65rem;
			transition: all 0.2s ease-in-out;
			margin-bottom: 2.75rem;
			transition: all 0.3s ease-in-out;
		}
	}

	.bg-gray {
		background: #f4f4f4;
	}

	.bg-black {
		background: #292d32;
	}

	.brand-assets,
	.product-shots,
	.products,
	.testimonials {
		h2 {
			margin-bottom: 2rem;
		}

		h2,
		h3 {
			font-size: 1rem;
			line-height: 1.3;
			font-weight: 600;
			letter-spacing: 0;
		}
	}

	h3 {
		font-size: 0.8rem !important;
	}

	.medallion {
		height: 56px;
		width: 56px;
		min-width: 56px;
		box-shadow: 0 2px 5px 0 rgba( 0, 0, 0, 0.3 );
		border-radius: 100%;
	}

	.products {
		.product-item {
			display: flex;
			flex-direction: row;
			padding: 1rem;
			max-width: 800px;
			margin-top: 1.2rem;
			margin-bottom: 0;

			&:last-of-type {
				margin-bottom: 0;
			}

			.text {
				display: flex;
				flex-direction: column;
				margin-left: 1.25rem;

				.heading {
					display: flex;
					flex-direction: row;
					align-items: flex-start;

					@media ( max-width: 480px ) {
						flex-direction: column;
					}

					a {
						margin-left: auto;

						@media ( max-width: 480px ) {
							margin-left: 0;
							margin-top: 0.5rem;
						}
					}
				}

				p {
					margin-top: 0.6rem;
					font-size: 0.7rem;
					color: #7f7f7f;
					width: auto;
				}
			}
		}
	}

	.brand-assets {
		display: flex;

		.row {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;
			margin-bottom: 2.25rem;

			.assets-item {
				margin: 1.25rem 0.75rem;
				padding: 1.25rem 0;
				width: 235px;

				h3 {
					margin: 1rem 0 2rem;
				}

				a.btn {
					padding: 0.6rem 1rem;
					font-size: 0.6rem;
					margin: 0;
				}
			}
		}

		p {
			text-align: center !important;
			margin: 0 auto;
		}
	}

	.product-shots,
	.testimonials {
		h3 {
			font-weight: 600;
			font-size: 0.6rem !important;
			color: #7182a3;
			text-align: center;
			text-transform: uppercase;
			padding: 0.4rem 0.8rem;
			width: max-content;
			margin: 0 auto;
			border-radius: 20px;
			border: 1px solid #7182a3;
			margin-top: 2rem;
			line-height: 1.3;

			&:first-of-type {
				margin-top: 1rem;
			}
		}

		.dbi-plugin-tweets {
			margin-top: 2rem;
		}

		.tweets {
			max-width: 1100px;

			.dbi-tweet-by {
				text-align: left;
			}
		}

		.product-images {
			display: flex;
			flex-wrap: wrap;
			margin: 1.5rem auto 2rem;
			justify-content: center;
			max-width: 1200px;

			img {
				padding: 1rem;

				@media ( max-width: 480px ) {
					width: 100%;
					display: flex;
				}
			}
		}
	}

	.email-cta {
		p {
			text-align: center;
			max-width: 650px;
			color: #fff;
		}
	}
}

.product-shots {
	//hide until ready to launch
	display: none !important;

	a {
		width: max-content;
		margin: 0 auto 2rem;

		&:last-of-type {
			margin-bottom: 0;
		}
	}
}
