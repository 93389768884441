body.woocommerce-checkout {
	.woocommerce {
		.woocommerce-privacy-policy-text p {
			margin-bottom: 0.5rem;
		}

		ul {
			margin: 0;
		}

		li {
			list-style-type: none;
		}

		ul.woocommerce-error {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		> .woocommerce-error,
		> .woocommerce-message {
			width: 100%;
		}

		> .woocommerce-info {
			margin-bottom: 1rem;
		}

		> .cols {
			position: relative;

			@include bp(md) {
				min-height: 1200px;
			}

			> .col-1,
			> .col-2 {
				width: 100%;
				max-width: 670px;
				margin: 0 auto;
			}

			> .col-2 {
				@include bp(md) {
					max-width: 440px;
				}
			}

			@include bp(md) {
				display: flex;
				justify-content: space-between;

				> .col-1,
				> .col-2 {
					margin: 0; }

				> .col-1 {
					margin-right: 20px; }
			}

			.block {
				background-color: #fff;
				padding: 1.5rem;
				margin-bottom: 1rem;
				border-radius: 0.15rem;
			}

			h3 {
				font-size: 1.2rem;
				margin-bottom: 1.5rem;
				font-weight: normal;
				line-height: 1;
			}

			.form-row {
				margin-bottom: 1rem;
			}

			form.login {
				margin-bottom: 1rem;
				padding: 0 1rem 1rem 1rem;

				.form-row {
					width: 100%;
					margin-bottom: 0.5rem;
				}

				> p:first-child {
					display: none;
				}

				.form-row-last {
					margin-bottom: 1rem;
				}

				p:nth-of-type(4) {
					margin-bottom: 0;
					float: left;
				}

				.button {
					float: left;
					margin-right: 1rem;
					display: inline-block;
				}

				label[for="rememberme"] {
					float: left;
					margin-top: 1rem;

					input {
						margin-right: 0.2rem;
					}
				}

				.lost_password {
					float: right;
					margin: 1rem 0 0 0;
					padding-right: 0.5rem;
					font-size: 0.7rem;
					font-style: italic;
				}
			}

			.test-credit-cards {
				border-radius: 3px;
				margin-bottom: 1rem;
				padding: 1rem;
				line-height: 1.4;
				background-color: #eee;
			}

			$paypal_width: 94px;
			$paypal_height: 24px;
			$cc_width: 110px;
			$cc_height: 24px;

			/*
			 * Old, custom payment options
			 */
			.block.payment-options {
				position: relative;

				.logo {
					display: inline-block;
					background-color: #fff;
					padding: 0.5rem;
					border-radius: 0.15rem;
				}

				ul {
					li {
						padding: 0.5rem;
						cursor: pointer;
						border: 2px solid lighten( palette( grey ), 20% );
						border-radius: 0.15rem;
						background: #fff no-repeat center center;
						transition: all 0.2s $transition;
					}

					li:hover {
						border-color: palette( grey );
					}

					li:focus,
					li.selected {
						color: #fff;
						background-color: palette( grey );
						border-color: palette( grey );
					}
				}

				.existing-cards {
					.logo {
						margin-right: 0.8rem;
					}

					ul {
						padding: 0;

						li {
							display: flex;
							align-items: center;
							margin-bottom: 0.5rem;
							background-position: 0.8rem center;
							box-sizing: content-box;
							-moz-box-sizing: content-box;
							-webkit-box-sizing: content-box;
						}

						li:last-child {
							margin-bottom: 0;
						}

						.saved-method {
							position: relative;

							&.credit-card {
								.logo {
									img {
										width: 36px;
										height: 24px;
									}
								}

								span {
									font-family: "Courier New", monospace;
									font-weight: bold;
									letter-spacing: 1px;
								}
							}

							&.paypal {
								overflow: hidden;

								.logo {
									float: left;
								}

								span {
									float: left;
									font-weight: bold;
									white-space: nowrap;
									text-overflow: ellipsis;
									overflow: hidden;
									display: block;
									margin-right: 25px;
									line-height: 44px;
								}
							}

							.delete-method {
								text-decoration: none;
								font-size: 1.2rem;
								position: absolute;
								top: 50%;
								transform: translateY( -50% );
								line-height: 1;
								right: 1rem;
								color: palette( grey );

								&:hover {
									color: lighten( palette( grey ), 10% );
								}
							}
						}

						li:focus,
						li.selected {
							.delete-method {
								color: #fff;

								&:hover {
									color: lighten( palette( grey ), 20% );
								}
							}
						}
					}
				}

				.new-payment {
					ul {
						padding: 0;

						&::after {
							content: "";
							clear: both;
							display: block;
							height: 0;
						}

						li {
							float: left;
							margin: 0 1rem 1rem 0;
						}
					}
				}

				.button {
					margin: 1rem 0 0 0;
					text-align: center;
				}

				.paypal .logo img {
					width: $paypal_width;
					height: $paypal_height;
				}

				.credit-card .logo img {
					width: $cc_width;
					height: $cc_height;
				}
			}

			/*
			 * New, standard payment options
			 */
			form.checkout {
				.test-credit-cards {
					font-family: "Courier New", monospace;
					font-weight: bold;
					letter-spacing: 1px;
				}

				#payment {
					position: relative;

					.logo {
						display: inline-block;
						background-color: #fff;
						padding: 0.5rem;
						border-radius: 0.15rem;
					}

					ul.payment-methods {
						margin-bottom: 1rem;
					}

					ul.wc-saved-payment-methods {
						padding: 0;
						list-style-type: none;
						list-style-image: none;

						li {
							display: flex;
							align-items: center;
							margin-bottom: 0.5rem;
							cursor: pointer;
							border: 2px solid lighten(palette(grey), 20%);
							border-radius: 0.15rem;
							background: #fff no-repeat center center;
							transition: all 0.2s $transition;
						}

						li:last-child {
							margin-bottom: 0;
						}

						li:hover {
							border-color: palette(grey);
						}

						li:focus,
						li.selected {
							color: #fff;
							background-color: palette(grey);
							border-color: palette(grey);
						}

						.logo {
							margin-right: 0.8rem;

							img {
								width: 36px;
								height: 24px;
							}
						}

						#wc-stripe-payment-token-new + .logo {
							img {
								width: $cc_width;
								height: $cc_height;
							}
						}

						/*
						 * The buttons here are used to create click events. State is reflected
						 * in the hidden radio inputs. I'd love to use :has here, but support
						 * wasn't good enough at time of writing.
						 */
						button {
							width: 100%;
							padding: 0.5rem;
							border: none;
							background: none;
							outline: none;
							text-align: left;
							display: block;
						}

						input[type=radio] {
							display: none;
						}

					}

					//.new-payment {
					//	ul {
					//		padding: 0;
					//
					//		&::after {
					//			content: "";
					//			clear: both;
					//			display: block;
					//			height: 0;
					//		}
					//
					//		li {
					//			float: left;
					//			margin: 0 1rem 1rem 0;
					//		}
					//	}
					//}

					.button {
						margin: 1rem 0 0 0;
						text-align: center;
					}
				}
			}

			.required {
				border: 0;
			}

			.woocommerce-billing-fields {
				p:last-of-type {
					margin-bottom: 0;
				}
			}

			.billing-details-summary {
				font-size: 0.8rem;
				line-height: 1.4;
				padding: 0.8rem 1rem;
				position: relative;
				border: 2px solid #ddd;
				border-radius: 0.15rem;

				address {
					font-style: normal;
				}

				.show-billing-fields {
					position: absolute;
					top: 0.8rem;
					right: 1rem;
				}
			}

			.form-row,
			#billing_postcode_field {
				width: 7.5rem;
			}

			.form-row-wide,
			#billing_last_name_field,
			.woocommerce-SavedPaymentMethods-saveNew,
			.woocommerce-terms-and-conditions-wrapper .form-row,
			.place-order {
				width: 100%;
			}

			#billing_first_name_field,
			#billing_phone_field {
				width: 12.5rem;
			}

			#billing_address_1_field {
				margin-bottom: 0.5rem;
			}

			.input-checkbox {
				display: inline;
				margin: -0.1rem 0.25rem 0 0;
				vertical-align: middle;
			}

			label.checkbox {
				display: inline;
			}

			.order-summary.payment-details {
				padding-bottom: 1rem;

				.shop_table {
					margin-bottom: 2rem;
				}
			}

			form.checkout {
				padding-bottom: 0;

				@include bp(sm) {
					padding-bottom: 1rem;
				}

				.shop_table {
					margin-bottom: 2rem;
				}
			}

			.shop_table {
				border-spacing: 0;
				width: 100%;

				td,
				th {
					text-align: left;
				}

				.product-total {
					text-align: right;
				}

				tbody {
					th,
					td {
						font-size: 0.9rem;
						padding: 0.8rem 0.2rem;
					}

					tr:first-child {
						th,
						td {
							border-top: 2px solid palette( grey );
						}
					}
				}

				thead {
					display: none;
				}

				tfoot {
					td,
					th {
						font-size: 0.8rem;
						text-align: right;
						padding: 0.25rem 0.3rem;
					}

					th {
						width: 100%;
					}

					td {
						white-space: nowrap;
						padding-left: 1rem;
					}

					tr:first-child {
						td,
						th {
							border-top: 2px solid palette( grey );
							padding-top: 0.8rem;
						}
					}

					tr:nth-last-child(2) {
						td,
						th {
							padding-bottom: 0.8rem;
							border-bottom: 2px solid palette( grey );
						}
					}
				}

				.order-total {
					td,
					th {
						font-size: 1.1rem;
						padding-bottom: 0;
						padding: 0.8rem 0.2rem 0 0.2rem;
					}

					td {
						padding-left: 1rem;
					}
				}
			}

			.form-row.wc-terms-and-conditions {
				margin-bottom: 1rem;
				width: 60%;

				@include bp(400px) {
					width: 100%;
				}
			}

			.place-order {
				margin: 0;
			}

			.place-order-button {
				width: 100%;
				padding: 1rem 0;
				font-size: 1rem;
			}

			.woocommerce-checkout-review-order {
				position: relative;

				.wc_payment_methods {
					padding: 0; }

				.wc-credit-card-form {
					margin-top: 1rem;
					margin-bottom: 1rem;
					.form-row-last {
						margin-bottom: 0;
					}
				}

				.wc-stripe-elements-field {
					@extend .dbi-base-input;
				}

				.cart-quantity .quantity {
					input {
						width: 80px;

						&::-webkit-inner-spin-button,
						&::-webkit-outer-spin-button {
							opacity: 1;
						}
					}

					label {
						display: none;
					}
				}
			}

			.credit-card-details {
				position: relative;
				display: flex;
				justify-content: space-between;
			}

			.dbi-credit-cards {
				img {
					width: 120px;
				}
			}
		}

		form.checkout {
			.col-1 {
				float: none;
				margin-bottom: 3rem;
			}

			.col-2 {
				display: none;
			}
		}

		.guarantee {
			width: 100%;

			.seal {
				float: none;
				margin: 0 auto 1rem auto;
			}

			p {
				text-align: center;
				padding: 0 1rem;
			}
		}

		.tweets {
			margin-bottom: 1rem;
			padding: 0;
			border-radius: 0.15rem;

			h5 {
				line-height: 1.2;
			}
		}

		.dbi-media--tweet {
			margin: 0 0 1rem 0;
			border: 1px solid lighten( palette( grey ), 20% );

			&:last-of-type {
				margin-bottom: 0;
			}

			.icon-twitter {
				fill: #aaa;
				top: 1.2rem;
			}
		}

		.dbi-pricing__guarantee--checkout {
			position: relative;
			padding: 1.5rem 1rem 0.5rem;
			margin: 0 0 1rem 0;
			border-radius: 0.15rem;
			border: 1px solid lighten( palette( grey ), 20% );

			h2 {
				font-size: 1rem;
				line-height: 1rem;
				color: #666;
				text-align: center;
			}

			p {
				margin-bottom: 1rem;
				text-align: center;
			}

			.icon {
				display: none;
			}
		}

		#stripe-security {
			svg {
				width: 16px;
				height: 20px;
				margin-right: 0.25rem;
				vertical-align: baseline;
				fill: #666;
				position: relative;
				top: 1px;
			}
		}
	}
}

body.woocommerce-checkout.paypal-express-checkout {
	.woocommerce {
		.order-summary-top,
		.payment-options {
			display: none;
		}
	}
}

.dbi-checkout-complete {
	background: #0b122a;
	position: relative;
	background-size: auto;
	text-align: center;
	color: #fff;
	padding: 3.5rem 5% 2rem;

	@include bp(lg) {
		padding-left: 0;
		padding-right: 0;
	}

	.checkout-emoji {
		width: 102px;
		margin: 0 auto;
	}

	h1 {
		font-family: ff( "body" );
		font-weight: 700;
		font-size: 1rem;
		margin-top: 1rem;
		letter-spacing: 0;
	}

	p {
		margin-top: 0.5rem;
		font-size: 0.75rem;
		color: #cdced3;
	}

	a {
		color: #cdced3;
		text-decoration: underline;
	}
}

.btn-proceed-account {
	display: flex;
	width: max-content;
	margin: 0 auto;
}
