.dbi-pagination {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	margin: 0 0 2.5rem 0;
	font-size: 0.9rem;
	text-transform: uppercase;
	flex-wrap: wrap;
	flex-direction: row;
	//flex-direction: column;

	// @include bp(md) {
	// }

	.dbi-pagination__next,
	.dbi-pagination__prev {
		margin-top: 0.3rem;
		-webkit-transition: all 0.2s cubic-bezier( 0.46, 0.03, 0.52, 0.96 );
		transition: all 0.2s cubic-bezier( 0.46, 0.03, 0.52, 0.96 );
		font-size: 0.65rem;
		font-weight: 600;
		line-height: 1.3;
		letter-spacing: 0.015rem;
		border-radius: 0.15rem;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.dbi-pagination__prev {
		text-align: left;
		align-self: flex-start;
	}

	.dbi-pagination__next {
		text-align: right;
		align-self: flex-start;
	}

	&.dbi-pagination--blog {
		//flex-direction: column;
		margin: 2rem 0;
		padding: 0 5%;

		@include bp(md) {
			max-width: $max-width;
			position: relative;
			left: 50%;
			transform: translateX( -50% );
			margin-top: 0;
			//flex-direction: row;
			margin: 1rem 0 2.5rem 0;
		}
		//flex-wrap: wrap;
	}

	a {
		color: palette( slate );

		&:hover {
			text-decoration: none;
		}
	}

	.dbi-disabled-link,
	a {
		span {
			vertical-align: middle;

			& + .icon {
				margin-left: 0.5rem;
			}
		}

		.icon {
			fill: currentColor;
			width: 0.65rem;
			height: 0.65rem;
			vertical-align: middle;

			& + span {
				margin-left: 0.5rem;
			}
		}
	}

	.dbi-disabled-link {
		opacity: 25%;
	}

	.dbi-pagination__page {
		font-family: ff( "body" );
		width: 1.4rem;
		height: 1.4rem;
		text-align: center;
		line-height: 2;
		display: inline-flex;
		align-items: center;
		border-radius: 50%;
		font-size: 0.75rem;
		justify-content: center;
		margin: 0 0.15rem;

		&.active {
			background-color: palette( slate );
			color: #fff;

			&:hover {
				color: #fff;
			}
		}
	}

	a.dbi-pagination__page:hover {
		background-color: palette( grey );
		color: #fff;
	}

	.docs & {
		padding-left: 5%;
		padding-right: 5%;

		@include bp(lg) {
			margin-left: $column-width*4 - $half-gutter;
		}

		@include bp(1220px) {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
