.dbi-features-testimonial {
	font-family: ff( "headings" );
	line-height: 1.6rem;

	.dbi-features-testimonial__content {
		font-size: 21px;
		font-weight: 500;
		line-height: 1.4;
		position: relative;

		&::before {
			display: block;
			content: "";
			width: 116px;
			height: 81px;
			position: absolute;
			left: 0;
			top: -50px;
			z-index: 0;
			background: url( "../images/mdb/quote.png" ) no-repeat 50% 50%;
			background-size: contain;
		}

		p {
			position: relative;
			z-index: 1;
		}
	}

	.dbi-features-testimonial__author {
		font-size: 16px;
		line-height: 25px;

		img {
			display: block;
			width: 50px;
			float: left;
			border-radius: 100%;
			margin-right: 1rem;
		}

		p {
			overflow: auto; }
	}
}

.dbi-spacer-testimonial {
	max-width: $max-width;
	margin: 90px auto 40px;
	padding: 0 40px;

	@include bp(md) {
		margin: 100px auto;
	}

	@include bp($max-width) {
		margin: 5px auto 100px;
		padding: 0;
	}

	.dbi-features-testimonial {
		max-width: 900px;
		margin: 0 auto;

		@include bp(md) {
			padding-left: 200px;
		}
	}

	@include bp(md) {
		.dbi-features-testimonial__content::before {
			top: 34px;
			left: -200px;
		}
	}

	&.dbi-spacer-testimonial--bottom {
		max-width: $max-width;
		margin: 0;
		padding: 90px 40px 150px;
		position: relative;

		@include bp(md) {
			padding: 150px 40px;
		}

		@include bp($max-width) {
			margin: 100px auto 0;
			padding: 0 0 600px;
		}

		.dbi-features-testimonial {
			padding-left: 0;

			@include bp(md) {
				padding-left: 200px;
			}

			@include bp($max-width) {
				max-width: 500px;
				padding-left: 0;
				margin: 0;
				position: absolute;
				bottom: 130px;
				right: 50px;
			}
		}

		.dbi-features-testimonial__content::before {
			left: 0;

			@include bp(md) {
				top: -50px; }

			@include bp($max-width) {
				top: -150px; }
		}
	}
}
