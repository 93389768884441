:root {
	--brain-link: #ee3b71;
}

body.doc-template-default,
body.tax-doc_category {
	background: #f4f4f4;
}

.dbi-docs {
	position: relative;
	z-index: 10;
	left: 50%;
	width: 100%;
	max-width: 1440px;
	transform: translateX( -50% );
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	.docs-category-title {
		font-family: ff( "body" );
		color: #3f4e5a;
		font-size: 0.9rem;
		margin-bottom: 2rem;
		text-transform: none;

		&.single {
			margin: 0 2rem 1.3rem;

			@include bp(md) {
				margin: 0 0 1.3rem 3rem;
			}
		}
	}

	.dbi-docs__search {
		flex: 0 0 90%;
		max-width: 100%;
		margin: 1.25rem 5%;

		@include bp(md) {
			flex: 0 0 100-( ($column-width*4 - $half-gutter)*2 );
			max-width: 100-( ($column-width*4 - $half-gutter)*2 );
			margin: 1.25rem $column-width*4 - $half-gutter;
		}

		#swpquery {
			margin-bottom: 0.5rem;

			@include bp(md) {
				margin-bottom: 0;
			}
		}
	}

	.doc-links {
		max-width: 440px;
		width: 100%;
		padding: 3rem 1rem 1rem;

		@include bp(sm) {
			width: 35%;
		}
	}

	.dbi-docs__categories {
		list-style: none;
		width: 100%;
		margin-left: auto;

		@include bp(sm) {
			text-align: left;
		}

		@include bp(md) {
			width: 260px;
		}

		a:hover {
			text-decoration: none;
		}

		.dbi-docs__category {
			margin-top: 1rem;

			a,
			span {
				display: inline-flex;
				font-size: 0.7rem;
				margin-bottom: 0.45rem;
				color: #6b7e90;
				display: flex;
				align-items: center;

				&:active,
				&:focus {
					text-decoration: none;
				}

				&:hover {
					color: palette();

					svg {
						stroke: var( --brain-link );
					}
				}

				svg {
					stroke: #a5a5a5;
				}
			}

			&.current {
				font-weight: 700;

				a {
					color: #ee3b71 !important;
				}

				a::after {
					content: " \2192";
					white-space: pre;
				}

				svg {
					stroke: var( --brain-link );
				}
			}

			&.doc-search-results {
				cursor: default;
			}
		}
	}

	.dbi-docs__topics {
		width: 100%;
		background: #fff;

		@include bp(sm) {
			max-width: 1000px;
			background: #fff;
			border: 1px solid #eff0f0;
			box-shadow: 0 0 4px 0 rgba( 0, 0, 0, 0.05 );
			display: flex;
			flex-direction: column;
			display: block;
		}

		@include bp(sm) {
			width: 65%;
		}

		.dbi-doc-list {
			margin-top: 3rem;
			list-style: none;
			padding: 0 0.2rem;

			li {
				margin: 0;
			}

			.no-results-img {
				border-radius: 6px;
				margin: 0.8rem 2rem 0.6rem 2rem;

				@include bp(sm) {
					margin: 0.8rem 1rem 0.6rem 3rem;
				}
			}
		}

		.dbi-docs__topic {
			a {
				display: block;
				color: palette( slate, 400 );
				padding: 0.7rem 2rem;
				font-size: 0.675rem;
				color: #3f4e5a;

				@include bp(md) {
					padding: 0.7rem 1rem 0.7rem 3rem;
				}

				&:hover {
					background-color: palette( blue, 800 );
					text-decoration: none;
				}
			}

			&:nth-of-type(2n) a {
				background-color: #f4f9fd;

				&:hover {
					background-color: palette( blue, 800 );
				}
			}
		}

		.dbi-docs__no-results {
			padding: 0.6rem 2rem 0.6rem 2rem;

			@include bp(sm) {
				padding: 0.6rem 1rem 0.6rem 3rem;
			}
		}

		.dbi-blog__post--single {
			padding: 1.25rem 5% 2rem;

			@include bp(sm) {
				padding: 1.25rem 2rem 2rem;
			}

			@include bp(lg) {
				padding: 1.25rem 3rem 2rem;
			}

			.dbi-blog__post__entry {
				max-width: 800px;
				margin-bottom: 0;
				border-bottom: none;
			}

			section.cta {
				max-width: $blog-article-max-width;
			}

			.dbi-blog__post__meta {
				max-width: 100%;
				text-align: left;
				color: #7182a3;
				margin-bottom: 2rem;
				font-size: 0.65rem;
			}
		}
	}

	.dbi-pagination--docs {
		padding: 0 2rem;

		@include bp(md) {
			margin: auto 0 2rem auto;
			padding: 0 3.2rem;
		}

		.dbi-pagination__next {
			@include bp(xl) {
				margin-right: 0;
			}
		}
	}

	.icon-docs {
		width: 20px;
		height: 20px;
		margin-right: 1.3rem;
	}
}
