body.woocommerce-lost-password {
	.woocommerce {
		@include bp(sm) {
			padding-bottom: 6rem;
		}
	}

	form.lost_reset_password {
		label {
			display: block;
			margin-bottom: 0.3rem;
		}

		input[type="text"] {
			width: 100%;
		}
	}
}
