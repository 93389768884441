.section {
	width: 100%;
}

.section--dark {
	background-color: palette( grey, 100 );
}

.section--padding-top {
	padding-top: 2rem;
}

.section--padding-bottom {
	padding-bottom: 2rem;
}
