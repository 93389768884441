.dbi-buzz__carousel {
	position: relative;
	z-index: 10;
	padding: 3.5rem 5% 4rem;

	.dbi-media {
		display: block;
		text-align: center;
	}

	.dbi-media__img {
		display: block;
		margin: 0 auto 1rem;
		max-width: 150px;
		border-radius: 100%;
	}

	.dbi-media__body {
		.icon-twitter {
			display: none; }
	}

	@include bp(md) {
		left: 50%;
		width: 100%;
		max-width: $max-width;
		transform: translateX( -50% );
		display: flex;
		flex-direction: row;

		.dbi-media {
			display: flex;
			text-align: left;
		}

		.dbi-media__img {
			margin-left: calc( #{$column-width} - 0.7rem );
			margin-right: 8%;
			margin-bottom: 0;
		}

		.dbi-media__body {
			margin-right: 20%;

			.icon-twitter {
				display: inline; }
		}
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	.owl-pagination {
		justify-content: center;
		bottom: 2.5rem;

		.active {
			background-color: palette();
		}
	}
}

// tweet

.dbi-buzz__interviews {
	background: linear-gradient( to bottom right, #e3ebed, #ededed );
	padding: 4rem 5% 0;

	@include bp(sm) {
		padding: 4rem 5%;
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	.dbi-buzz__interviews__inner {
		max-width: $max-width;
		margin: 0 auto;
	}

	h2 {
		text-align: center;
		margin-bottom: 3rem;

		@include section-title;
	}

	.dbi-buzz__video-player-wrap {
		display: none;
		position: relative;
		height: 0;
		overflow: hidden;
		padding-bottom: 56.25%;

		&.dbi-buzz__video-player-wrap--open {
			display: block;
			margin-bottom: 4rem;
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.dbi-buzz__interview-thumbs {
		justify-content: center;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
	}
}

// interviews

.dbi-buzz__interview {
	padding: 0 $half-gutter;
	margin-bottom: 2rem;
	position: relative;
	display: block;
	flex: 0 0 100%;

	@include bp(md) {
		flex: 0 0 33.333%;
		max-width: 33.333%;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	.dbi-buzz__interview__icon {
		position: absolute;
		background-color: rgba( black, 0.25 );
		border-radius: 50%;
		width: 5.5rem;
		height: 5.5rem;
		top: 50%;
		left: 50%;
		transform: translate( -50%, -50% );
		transition: all 0.2s $transition;

		.icon {
			fill: #fff;
			width: 2rem;
			height: 2rem;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate( -50%, -50% );
		}
	}

	&:hover {
		.buzz__interview__icon {
			background: rgba( black, 0.5 );
		}
	}
}

// interview

.dbi-buzz__tweets {
	position: relative;
	z-index: 10;
	left: 50%;
	width: 100%;
	max-width: $max-width;
	transform: translateX( -50% );
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 4rem 5% 0;

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	h2 {
		flex: 0 0 100%;
		margin-bottom: 3rem;
		text-align: center;

		@include section-title;
	}

	.dbi-media {
		margin: 0 $half-gutter 1.5rem;
		padding: 1rem;
		border-color: palette( blue, 700 );
		color: palette( grey, 400 );
		flex: 0 0 100%;
		align-items: flex-start;

		@include bp(md) {
			flex: 0 0 50% - $gutter-width;
			max-width: 50% - $gutter-width;
			margin: 0 $half-gutter 1.5rem;
		}

		@include bp(lg) {
			flex: 0 0 33.3333% - $gutter-width;
			max-width: 33.3333% - $gutter-width;
			margin: 0 $half-gutter 1.5rem;
		}

		.icon-twitter {
			top: 1rem;
			fill: map-get( $social, twitter );
		}
	}

	.dbi-buzz__tweets__more {
		display: block;
		text-align: center;
		font-size: 1.2rem;
		font-family: ff( headings );
		font-weight: 700;
		text-transform: uppercase;
		flex: 0 0 100%;
		margin-bottom: 4rem;

		&:hover {
			text-decoration: underline;
		}
	}

	&.dbi-buzz__tweets--expanded {
		display: none;
		padding-top: 0;
		padding-bottom: 4rem;
	}

	&.dbi-buzz__tweets--offload {
		margin-bottom: 0;

		@include bp(sm) {
			margin-bottom: 3rem;
		}
	}

}

// tweets
