h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: ff( headings );
	margin: 0;
	font-weight: 400;
	font-feature-settings: "dlig" 1, "liga" 1, "lnum" 1, "kern" 1;
	letter-spacing: -0.01rem;

	a {
		text-decoration: none;
	}
}

h1 {
	font-size: 1.7rem;
	line-height: 1.2;
	margin: 0;
	font-weight: 300;
}

h2 {
	font-size: 1.35rem;
	line-height: 1.2;
	margin: 0 0 0.75rem;
	font-weight: 600;
	text-transform: uppercase;
	color: palette( slate );
}

h3 {
	font-size: 1.2rem;
	line-height: 2;
	margin: 0;
}

h4 {
	font-size: 1.1rem;
	line-height: 2;
	margin: 0;
}

h5 {
	font-size: 0.95rem;
	line-height: 2.558;
	margin: 0;
}

h6 {
	font-size: 0.75rem;
	text-transform: uppercase!important;
	font-weight: 600;
	line-height: 1.5;
	margin: 0;
}
