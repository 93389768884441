body.single-post {
	.dbi-site__content {
		position: relative;
	}
}

.dbi-blog__list {
	//display: flex;
	max-width: $max-width;
	position: relative;
	left: 50%;
	transform: translateX( -50% );
	padding: 2rem 5%;

	@include bp(md) {
	}

	@include bp(lg) {
		padding: 3rem 10.666%; //$column-width*2;
	}

	@include bp(xl) {
		padding: 3rem 200px;
	}
}

//list

.dbi-blog__post__comment-count {
	display: inline-block;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 50%;
	background-color: #8e97a0;
	text-align: center;
	line-height: 1.5rem;
	font-family: ff( body );
	font-size: 0.8rem;
	color: #fff;
	overflow: hidden;
	text-decoration: none;
	transition: background-color 0.2s cubic-bezier( 0.46, 0.03, 0.52, 0.96 );

	&:hover {
		text-decoration: none;
		color: #fff;
		background-color: palette();
	}

	@include bp(md) {
		width: 2.5rem;
		height: 2.5rem;
		line-height: 2.5rem;
		font-size: 0.8rem;
	}
}

.dbi-blog__post {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	border-bottom: 1px solid palette( grey, 700 );
	color: #3f4e5a;
	padding-bottom: 1rem;
	margin-bottom: 1.25rem;
	font-size: 0.85rem;
	line-height: 1.6;

	@include bp(md) {
		flex-direction: row;
	}

	&.dbi-blog__post--single {
		display: block;
		max-width: $max-width;
		margin: 0 auto;
		padding: 2rem 5%;

		@include bp(lg) {
			padding: 2rem 10.666%;
		}

		@include bp(xl) {
			padding: 2rem 200px;
		}
	}

	&:last-of-type {
		border-bottom: 0;
		margin-bottom: 0;
	}

	.dbi-blog__post__feature__link {
		width: 100%;
	}

	.dbi-blog__post__feature {
		width: 100%;
		max-width: 100%;
		height: auto;
		margin-bottom: 1rem;
	}

	.dbi-blog__post__body {
		line-height: 1.2857;

		@include bp(md) {
			flex: 0 0 87.5%;
			max-width: 87.5%;
		}

		p,
		ul,
		ol {
			margin-bottom: 1rem;
		}

		time {
			font-family: ff( body );
			color: #7182a3;
			margin-bottom: 2rem;
			font-size: 0.65rem;
		}
	}

	.dbi-blog__post__title {
		text-transform: none;
		font-family: ff( "headings" );
		font-size: 1rem;
		line-height: 1.3;

		a {
			color: palette( slate );

			&:hover {
				color: palette();
				text-decoration: none;
			}
		}
	}

	.dbi-blog__post__excerpt {
		//padding-right: 12.5%;//$column-width + $gutter-width;
	}

	.dbi-blog__post__meta {
		@include bp(md) {
			margin-top: 0;
			text-align: right;
			flex: 0 0 12.5%;
			max-width: 12.5%;
		}

		a {
			display: inline-block;
			float: left;

			@include bp(md) {
				float: right;
				clear: both;
			}
		}

		img {
			width: 1.5rem;
			height: 1.5rem;
			margin-right: 0.5rem;
			display: block;
			vertical-align: baseline;

			@include bp(md) {
				margin-right: 0;
				margin-bottom: 0.5rem;
				width: 2.5rem;
				height: 2.5rem;
			}
		}
	}
	// meta

	.dbi-blog__post__entry {
		border-bottom: 1px solid palette( grey, 700 );
		margin-bottom: 1rem;

		p,
		ul,
		ol {
			margin-bottom: 1.25rem;
		}

		pre,
		blockquote,
		aside,
		.featured,
		.wp-video {
			margin-top: 1.35rem;
			margin-bottom: 1.75rem;
		}

		pre {
			&.diagnostics code {
				background: #ebebec;
				color: #323232;

				span {
					color: #323232;
				}
			}
		}

		ul li ul {
			margin-bottom: 0;
		}

		a:not(.dbi-image-anchor, .dbi-blog__post__entry__heading__anchor) {
			text-decoration: none;
			transition: none;

			&:hover {
				color: #ee3b71;
				border-bottom: 1px dashed #ee3b71;
			}
		}

		img {
			height: auto;
			max-width: 100%;
			border: 1px solid #ddd;


			//allow blog images to break outside of $container
			//lg = 1024px
			&.lg {
				@media ( min-width: 1024px ) {
					width: 90vw;
					max-width: 1024px;
					margin-left: 50%;
					transform: translateX(-50%);
				}
			}

			//xl = 1280px
			&.xl {
				@media ( min-width: 1024px ) {
					width: 90vw;
					max-width: 1280px;
					margin-left: 50%;
					transform: translateX( -50% );
				}
			}

			//xxl = 1440px
			&.xxl {
				@media ( min-width: 1024px ) {
					width: 90vw;
					max-width: 1440px;
					margin-left: 50%;
					transform: translateX( -50% );
				}
			}

			&.featured {
				width: 100%;
				border: none;
			}
		}

		iframe {
			max-width: 100%;
		}

		code {
			font-family: Consolas, Monaco, Lucida Console, monospace;
			font-size: 0.585rem;
			white-space: nowrap;
			margin: 0 0.2rem;
			vertical-align: middle;
		}

		.hljs {
			background: palette( grey, 100 );
			margin: 0 !important;
			padding-right: 4rem;
		}

		pre code {
			padding: 0.5rem 1rem 0.35rem 1rem;
			font-size: 0.6rem;
			line-height: 2;
			white-space: inherit;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 1.75rem 0 0.85rem 0;
			font-family: ff( "headings" );
			color: #3f4e5a;
			text-transform: inherit;
			font-weight: 600;
			line-height: 1.3;
		}

		h1.title {
			font-size: 1.35rem;
			font-family: ff( "headings" );
			text-transform: none;
			color: #3f4e5a;
			margin: 1.5rem 0 1rem 0 !important;
		}

		h2 {
			text-transform: none;
		}

		blockquote {
			position: relative;
			padding: 1.3rem 1.3rem 0.5rem 4rem;
			border: 1px dotted #8dbce4;
			border-radius: 6px;
			font-family: ff( "body" );
			color: #1a65a2;
			line-height: 1.6;
			background: #f8fcff;
			box-shadow: 0 2px 4px 0 rgba( 157, 161, 164, 0.2 );

			&::before {
				position: absolute;
				top: 2.6rem;
				left: 1.5rem;
				content: "\201C";
				font-family: Georgia, serif;
				font-size: 3rem;
				line-height: 0;
				color: #b0cee7;
			}

			p {
				font-size: 0.85rem;
				font-style: italic;
				margin-bottom: 0.75rem;
			}

			&.pull-left,
			&.pull-right {
				padding-left: 0;

				&::before {
					content: none;
				}
			}

			cite {
				display: block;
				margin: 1rem 0;
			}
		}

		aside {
			font-size: 0.8rem;
			border: 1px solid #ffbd97;
			padding: 1rem;
			line-height: 1.5;
			border-radius: 6px;
			background: #fff2e6;
		}

		.pull-right {
			text-align: right;
		}

		.post-series-box {
			padding: 1rem;
			margin-bottom: 1rem;
			border: 1px solid palette( grey, 700 );

			.post-series-name {
				font-weight: bold;
			}

			ol,
			ul {
				margin: 0 1.25rem;
			}
		}

		.dbi-blog__post__entry__heading {
			span {
				position: relative;

				a {
					font-size: 0.975rem;
					letter-spacing: 0.01rem;
				}

				&.copy-status {
					top: -2rem;
					font-size: small;
					color: palette( slate, 600 );
					background: #fff;
					border: 1px solid #d3d3d3;
					z-index: 9999;
					padding: 4px 12px;
					border-radius: 0.15rem;
					box-shadow: 0 1px 3px rgba( 0, 0, 0, 0.1 );
				}
			}

			.dbi-blog__post__entry__heading__anchor {
				opacity: 0;
				pointer-events: none;
				text-decoration: none;
				padding-left: 2rem;
				padding-top: 2rem;
				padding-bottom: 2rem;
				color: #ccc;

				&:hover {
					color: #aaa;
				}

				@media (max-width: 1023px) {
					display: none;
				}
			}

			&:hover {
				@include bp(lg) {
					.dbi-blog__post__entry__heading__anchor {
						opacity: 1;
						pointer-events: auto;
						text-decoration: none;

						/*	span.spaces{
							border-bottom: none;
						}*/
					}
				}
			}
		}

		@include bp(md) {
			.pull-left,
			.pull-right {
				float: left;
				margin: 0 5% 0 0;
				width: 32%;
			}

			.pull-right {
				float: right;
				margin: 0 0 0 5%;
			}
		}
	}

	.dbi-blog__post__author {
		margin-bottom: 2rem;

		h3 {
			text-transform: uppercase;
			font-size: 0.8rem;
			color: palette( slate );
			margin-bottom: 0.5rem;
		}

		.dbi-media__img {
			width: 14%;
			max-width: 120px;
		}
	}

	.dbi-blog__post__comments {
		margin-top: 1.5rem;
		width: 100%;
	}

	img.border {
		border: 1px solid #ccc;
	}

}

// post

.dbi-blog__nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: palette( grey, 900 );
	width: 100%;
	padding: 0.6rem 5%;
	max-width: $max-width;
	position: relative;
	left: 50%;
	transform: translateX( -50% );
	flex-direction: column;

	@include bp(md) {
		flex-direction: row;
	}

	&::before,
	&::after {
		content: "";
		background-color: palette( grey, 900 );
		position: absolute;
		top: 0;
		bottom: 0;
		left: 100%;
		width: 99rem;
	}

	&::after {
		left: auto !important;
		right: 100%;
	}

	ul {
		margin: 0 0 1rem;
		padding: 0;
		list-style: none;

		@include bp(md) {
			margin: 0;
		}

		li {
			display: inline-block;
			font-size: 0.6rem;
			font-family: ff( headings );
			font-weight: 500;
			text-transform: uppercase;
			letter-spacing: 0.015rem;

			@include bp(md) {
				font-size: 0.7rem;
			}

			&.label {
				margin-right: 0.75rem;
				color: palette( grey, 400 );
				display: none;

				@include bp(lg) {
					display: inline-block;
				}
			}

			&.active a {
				color: #fff;
				background-color: palette( grey );
			}

			a {
				display: block;
				color: palette( grey, 400 );
				text-decoration: none;
				padding: 0.5rem;
				line-height: 1;
				border-radius: 0.15rem;
				transition: all 0.1s $transition;

				@include bp(md) {
					padding: 0.7rem 0.75rem;
				}

				&:hover {
					background-color: palette( grey );
					color: #fff;
				}
			}
		}
	}

}

// nav

.dbi-blog__search {
	width: 100%;
	margin: 0 auto;

	@include bp(md) {
		width: 50%;
	}

	@include bp(lg) {
		width: auto;
	}

	.screen-reader-text {
		display: none;
	}

	input[type="search"] {
		margin-bottom: 0.5rem;

		@include bp(md) {
			margin-bottom: 0;
		}

		@include bp(lg) {
			min-width: 14rem;
		}
	}
}

.dbi-blog__post__tags {
	margin-bottom: 1rem;
	border-bottom: 1px solid palette( grey, 700 );
	font-size: 0.7rem;
	font-style: italic;
	color: palette( grey, 500 );

	p {
		margin-bottom: 1rem;
	}

	a {
		color: palette( grey, 500 );
	}
}

.changelog {
	li p {
		display: inline;
	}
}

ol {
	list-style: none;
	counter-reset: ol-li-counter;
	margin-left: 20px;
}

ol li {
	counter-increment: ol-li-counter;
	position: relative;
	margin: 0.65rem 0;
	line-height: 1.6;
}

ol li::before {
	content: counter( ol-li-counter );
	background: #e2f2ff;
	border: 1px solid #aadcff;
	width: 28px;
	height: 28px;
	--size: 32px;
	left: calc( -1 * var( --size ) - 16px );
	border-radius: 50%;
	color: #234f93;
	font-size: 0.6rem;
	display: inline-block;
	text-align: center;
	padding-top: 2px;
	position: absolute;
	margin-top: -1px;
}

ol li::after {
	margin-left: 40px;
}

ol li {
	line-height: 1.6;
	margin: 0.55rem 0;
}

.dbi-blog__post {
	.copy-code {
		border-radius: 6px;
		background: #252728;
	}
}

.dbi-blog__post,
.woocommerce-account {
	.code-block {
		position: relative;
		&.shell {
			background: #1c3954;
			border-radius: 10px;
			color: #fff;
			width: 100%;
			max-width: 100%;
			tab-size: 4;
			display: grid;
			margin: 1.35rem 0;

			.copy-code {
				background: transparent;
			}

			pre {
				margin-top: 0;
				margin-bottom: 0;
				padding: 15px 15px 15px 25px;
			}
			&:before {
				content: '';
				height: 48px;
				background: rgba(255,255,255,.07);
				width: calc(100% + 60px);
			}

			&:after {
				content: '';
				position: absolute;
				top: 10px;
				left: 10px;
				width: 10px;
				height: 10px;
				box-shadow: 10px 10px 0 0 #ff5b52,
				26px 10px 0 0 #e6c02b,
				42px 10px 0 0 #52c12a;
				border-radius: 50%;
			}
		}

		pre {
			font-size: 0.6rem;
			font-family: Consolas, Monaco, Lucida Console, monospace;
		}

		.copy-code {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 75px;
			height: 30px;
			font-family: "Open Sans", sans-serif;
			padding: 5px;
			cursor: pointer;
			color: rgba( 255, 255, 255, 0.5 );
			font-size: 13px;
			font-weight: 600;
			position: absolute;
			top: 8px;
			right: 0;

			&:hover {
				color: #fff;
				text-decoration: underline;
			}

			&::after {
				content: "Copy";
			}

			&.copied::after {
				content: "Copied";
			}
		}
	}
}

.ytplaceholder {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;


    picture {
		pointer-events: none;/*needed to pass through allow mouseevents to the ytplaceholder div*/
		width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
		img {
			object-fit: cover;
		}
	}

	button.ytcover-button {
		pointer-events: none;/*needed to pass through allow mouseevents to the ytplaceholder div*/
        position: absolute;
        left: 50%;
        top: 50%;
        width: 68px;
        height: 48px;
        margin-left: -34px;
        margin-top: -24px;
        -webkit-transition: opacity .25s cubic-bezier(0,0,0.2,1);
        transition: opacity .25s cubic-bezier(0,0,0.2,1);
        z-index: 64;
        border: none;
        background-color: transparent;
        padding: 0;
        color: inherit;
        text-align: inherit;
        font-size: 100%;
        font-family: inherit;
        cursor: default;
        line-height: inherit;
        svg {
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
        }
        svg path.yt-cover-large-play-button-bg {
            transition: fill .1s cubic-bezier(0.4,0,1,1),fill-opacity .1s cubic-bezier(0.4,0,1,1);
            fill: #212121;
            fill-opacity: .8;
        }
    }

    &:hover {
        button.ytcover-button {
            svg path.yt-cover-large-play-button-bg {
                -webkit-transition: fill .1s cubic-bezier(0,0,0.2,1),fill-opacity .1s cubic-bezier(0,0,0.2,1);
                transition: fill .1s cubic-bezier(0,0,0.2,1),fill-opacity .1s cubic-bezier(0,0,0.2,1);
                fill: #f00;
                fill-opacity: 1;
            }

        }
    }

    iframe {
		pointer-events: none;/*needed to pass through allow mouseevents to the ytplaceholder div*/
		position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
        opacity: 0;
        transition: opacity 0.2s $transition;
    }
}
