.wcss-Settings {
	padding: 1.5rem 1.75rem;
	background-color: #fff;
	margin-bottom: 1.25rem;
	border-radius: 8px;
	box-shadow: -10px 10px 20px 0 rgba( 30, 30, 30, 0.05 );

	h2 {
		font-family: ff( body );
		font-size: 20px;
		font-weight: 700;
		line-height: 1rem;
		margin-bottom: 1.75rem;
		text-transform: none;
		color: #424242;

		.dbi-more-info {
			margin-left: 10px;
			display: inline-flex;
			width: 22px;
			height: 22px;
			vertical-align: bottom;
		}
	}

	.btn-link,
	button {
		display: inline;
		padding: 0;
		margin: 0;
		border: none;
		background: transparent;
		color: palette();
		font-size: 0.7rem;

		&:focus {
			outline: none;
		}

		&:focus,
		&:hover {
			color: darken( palette(), 15% );
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.wcss-Settings-content {
		margin-bottom: 1.5rem;
		background: #fff;

		table {
			&#composer-keys {
				width: 100%;
			}

			tr {
				border-bottom: 1px solid #eee;
				display: flex;
				align-items: center;
				justify-content: space-between;

				@media ( max-width: 720px ) {
					flex-wrap: wrap;
				}

				&.no-divider:first-child {
					td {
						padding-left: 0;
						margin-top: 20px;
					}
				}

				&.no-divider {
					border: none;

					td {
						padding-bottom: 0;
					}
				}

				&.no-divider:not(:first-child) {
					td {
						padding-top: 0.9rem;
						padding-left: 0;

					}
				}

				&.composer-key-label {
					td {
						padding-top: 0;
						color: #787b7d;
					}
				}

				&:first-child {
					td {
						padding-top: 0;
					}
				}

				input {
					display: none;
				}

				&.expanded {
					input {
						display: block;
						width: 100%;
						font-size: 0.6rem;
						padding: 0.5rem 0.65rem;
						color: #333;
						border: 1px solid #adacad;
						font-size: 0.65rem;
						line-height: 1;
						border-radius: 0.15rem;
						-webkit-appearance: none;
					}

					td[colspan]:not([colspan="1"]) {
						min-width: inherit;

						@include bp(sm) {
							min-width: 300px;
						}

						@include bp(md) {
							min-width: 400px;
						}

						@include bp(lg) {
							min-width: 500px;
						}
					}

					td.composer-actions {
						display: flex;
					}
				}

				&.divider {
					td {
						padding-bottom: 0.9rem;
					}
				}

				&:last-child {
					border-bottom: none;

					td {
						padding-bottom: 0;
					}
				}
			}

			td {
				padding: 0.8rem 0.8rem 0.8rem 0;

				&.composer-actions {
					vertical-align: top;
					padding: 0.2rem 0.4rem 0;
				}

				&:first-child {
					width: 100%;
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}

				strong {
					margin-right: 0.5rem;
					margin-bottom: 0.5rem !important;
				}

				small {
					font-size: 0.6rem;
				}
			}

		}
	}

	&.wcss-composer-keys {
		.cancel-composer-key,
		.save-composer-key {
			display: none;
		}

		button.view-composer-details {
			font-size: 0.7rem;
			margin-top: 0.2rem;
			float: right;
		}

		#view-composer-json-details {
			pre {
				max-width: 100%;
			}
		}

		div.key {
			font-family: Courier New, Courier, monospace;
			font-weight: bold;
			color: #454748;
			display: inline-block;
			font-size: 0.6rem;
		}

		span.label {
			min-width: 75px;
			margin-right: 1rem;
			display: inline-block;
			color: #787b7d;
		}

		.wcss-Settings-content table tr.expanded {
			.cancel-composer-key,
			.save-composer-key {
				display: inline;
			}

			.delete-composer-key,
			.edit-composer-key,
			.view-composer-key {
				display: none;
			}
		}

		div.view-composer-details {
			display: none;
			font-size: 85%;
			position: relative;

			pre {
				overflow: auto;
			}

			a.download-composer-file {
				position: absolute;
				bottom: 0;
				right: 0;
				margin: 0.1rem 0.2rem;

				svg {
					fill: #fff;
					height: 1rem;
					width: 1.5rem;
				}
			}
		}
	}

	&.wcss-Settings-closed {
		.wcss-Settings-content-wrap {
			display: none;
		}

		h2 {
			margin-bottom: 0;
		}
	}

	&.wcss-email-subscriptions {
		table {
			width: 100%;

			tr td:first-child {
				width: 80%;
			}

			.wcss-Switch {
				float: right;
			}

			p {
				margin: 0.4rem 0 0;
			}
		}

		.wcss-Settings-toggle {
			float: right;
			font-size: 0.7rem;
			width: 100px;
			text-align: right;
			margin-top: 0.1rem;
		}

		.wcss-email-subscriptions-email {
			font-size: 0.8rem;
			color: #8d8d8d;
			float: right;
		}

		.email-subscriptions-actions {
			display: inline-block;
			width: 100%;
			margin-top: 1rem;
			display: flex;
			justify-content: space-between;
			margin-top: 1.5rem;

			#save-subscriptions {
				float: right;
				order: 2;
			}

			#unsubscribe-from-all {
				float: left;
				margin-top: 0.5rem;
				order: 1;
			}

			button:disabled {
				cursor: not-allowed;
				pointer-events: all !important;
				opacity: 0.6;
			}
		}
	}
}
