.dbi-featured-posts {
	background: #fff;
	padding: 3rem 0;
	border-bottom: 1px solid #d8d8d8;

	@include bp(sm) {
		padding: 6rem 0;
	}

	&__header {
		margin: 0 auto 2.5rem auto;
		position: relative;
	}

	&__heading {
		font-weight: 400;
		font-size: 1rem;
	}

	.tooltip {
		display: inline-block;
	}

	.tooltip .tooltip-gif {
		visibility: hidden;
		width: 160px;
		height: auto;
		background-color: #555;
		color: #fff;
		text-align: center;
		border-radius: 6px;
		padding: 5px 0;
		position: absolute;
		z-index: 1;
		bottom: 140%;
		left: 13%;
		margin-left: -60px;
		opacity: 0;
		transition: opacity 0.3s;
	}

	.tooltip .tooltip-gif::after {
		content: "";
		position: absolute;
		top: 100%;
		left: 13%;
		margin-left: -5px;
		border-width: 5px;
		border-style: solid;
		border-color: #555 transparent transparent transparent;
	}

	@media ( min-width: 769px ) {
		.tooltip {
			border-bottom: 1px dotted black;
			cursor: pointer;
		}

		.tooltip:hover .tooltip-gif {
			visibility: visible;
			opacity: 1;
		}
	}

	&__posts {
		margin: 0;

		@include bp(sm) {
			width: 103%;
			margin: 0 -1.5%;
		}
	}

	.dbi-post-card {
		width: 100%;
		margin: 0 auto 3rem;

		@include bp(md) {
			width: 47%;
			margin: 0 1.5% 3%;
			float: left;
		}

		@include bp(md) {
			width: 47%;
			margin-bottom: 0;
		}
	}

	.dbi-post-row {
		margin-bottom: 0;

		&:last-of-type {
			margin-bottom: 0;
		}

		@include bp(md) {
			margin-bottom: 2.8rem;
		}
	}

	.dbi-post-card__postdesc {
		font-size: 0.60869565rem;
		color: palette( grey, 400 );
		margin: 0;
		width: 100%;
		line-height: 1.5;

		p {
			margin: 0 0 1rem;
		}

		&:hover {
			text-decoration: none;
		}
	}

	.dbi-post-card .author {
		width: 1.3rem;
		height: 1.3rem;
		margin-right: 10px;
	}

	.dbi-post-card__author {
		font-size: 0.55rem;
		font-weight: 900;
		color: palette( grey, 400 );
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-grow: 1;
	}

	.comment-count {
		display: flex;
		flex-grow: 1;
		align-self: flex-end;
		justify-content: flex-end;

		p {
			position: absolute;
			text-align: center;
			width: 30px;
			margin-top: 1px;
			color: #fff;
		}
	}
}
