.dbi-tooltip {
	display: none;
	position: absolute;
	padding: 15px;
	max-width: 320px;
	border-radius: 0.15rem;
	background-color: rgba( 0, 0, 0, 0.9 );
	color: #eee;
	text-align: center;
	font-size: 0.7rem;
	line-height: 1.5;
	z-index: 999;


	/* Triangle hack to make tooltip look like a speech bubble */
	&::after {
		content: "";
		position: absolute;
		bottom: -7px;
		left: 50%;
		margin-left: -7px;
		width: 0;
		border-top: 7px solid rgba( 0, 0, 0, 0.9 );
		border-right: 7px solid transparent;
		border-left: 7px solid transparent;
		content: " ";
		font-size: 0;
		line-height: 0;
	}
}

body[class*="my-account-license-upgrade-"] .dbi-tooltip,
body.page-template-page-wp-migrate-db-pro-pricing .dbi-tooltip {
	max-width: 290px;
	border-radius: 4px;
	background-color: rgba( 255, 255, 255, 1 );
	box-shadow: 0 0 50px rgba( 0, 0, 0, 0.4 );
	font-size: 14px;
	line-height: 1.5;
	margin-top: -8px;
	color: #3f3f3f;
	text-align: left;

	i {
		font-style: italic;
		font-weight: 600;
	}

	&::after {
		content: none;
	}
}
