.dbi-modal-open {
	overflow: hidden;
	height: 100%;
}
.dbi-modal {
	border: 0 none;
	max-width: 95vw;
	max-height: 95vh;
	padding: 0;
	overflow: hidden;
	position: fixed;
	overscroll-behavior: contain;

	@include bp(md) {
		//width: 90vw;
		max-width: 896px;
		//height: 90vh;
		max-height: 690px;
		border-radius: 16px;
	}

	&::backdrop {
		backdrop-filter: blur(3px);
		background-color: hsla(0, 0%, 10%, 0.5);

		@supports not (backdrop-filter: blur(3px)) {
			background-color: hsla(0, 0%, 10%, 0.8);
		}
	}
}

.dbi-modal__scroll-container {
	max-width: min(95vw, 896px);
	max-height: min(95vh, 690px);
	overflow-y: auto;
	overscroll-behavior: contain;

	@include bp(md) {
		border-radius: 16px;
	}
}

/* This needs to be nested to get some specificity */
.dbi-modal .dbi-modal__inner {
	padding: 32px 0 32px 32px;
	position: relative;
	overflow: auto;

	@include bp(md) {
		padding: 50px;
	}

	/* This is equivalent to the headings in a .dbi-blog__post__entry */
	h2, h3 {
		padding-right: 32px;

		font-size: 1.2rem;
		margin: 1.75rem 0 0.85rem 0;
		font-family: ff( "headings" );
		color: #3f4e5a;
		text-transform: inherit;
		font-weight: 700;
		line-height: 1.3;

		@include bp(md) {
			padding-right: 0;
		}
	}
}

.dbi-modal__content {
	h2, h3 {
		&:first-child {
			margin-top: 0;
		}
	}
}

/*
 * This is needed to make sure that the close button is in the right place regardless of the presence of a scrollbar.
 * Note position: sticky, which lets the container be full width; respects the scrollbar width, and stays at the top
 * when scrolling.
 */
.dbi-modal__close-button-container {
	height: 0;
	position: sticky;
	top: 0;
	right: 0;
	z-index: 10; /* This needs to sit on top of the .dbi-modal__inner */
}

.dbi-modal__close-button {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 8px;

	/*
	 * Because of the position this pushes the text outside of the modal, so we don't need overflow: hidden.
	 * In fact, we use the shown overflow to display the focus state.
	 */
	text-indent: 100px;

	position: absolute;
	width: 26px;
	height: 26px;
	right: 12px;
	top: 12px;

	background: #444444 url(../images/modal-close.svg) no-repeat center;
	background-size: 10px 10px;
	border: 0 none;
	border-radius: 100px;
	box-shadow: none;
	outline: none;

	/* This adds a nice focus state */
	&:focus::before {
		content: '';
		display: block;
		border: 1px dotted #333;
		width: calc( 100% + 6px );
		height: calc( 100% + 6px );
		position: absolute;
		top: -3px;
		left: -3px;
		z-index: 10;
	}
}