.dbi-plugin-features {
	padding-top: 2.5rem;

	@include bp(md) {
		padding-top: 2.5rem;
	}

	li {
		color: #787878;
		list-style-position: outside;
	}

	li + li {
		margin-top: 0.75rem;
	}

	ul {
		padding-left: 0.9rem;
	}

	img {
		max-width: 100%;

		@include bp(md) {
			max-width: 965px;
		}
	}

	background-size: 50%;
	background-position: 100% 100%;
	background-repeat: no-repeat;

	&.dbi-plugin-features--offload {
		padding-top: 2rem;
		padding-bottom: 2rem;
		position: relative;

		> img {
			position: absolute;
			bottom: 0;
			right: 0;
			max-width: 50%;
		}
	}

	.dbi-plugin-features__bg {
		position: absolute;
		//left: 100%;
		top: 55%;
		left: 0;
		right: 0;
		bottom: 0;
		//display: none;
		background-size: cover;

		@include bp(md) {
			width: 50%;
			top: 0;
			right: 0;
			left: auto;
		}

	}

	.dbi-plugin-features__figure-right {
		position: relative;
		z-index: 100;
		flex: 0 0 100%;
		max-width: 100%;

		padding: 0;

		.dbi-plugin-features--offload & {
			padding: 2.5rem 0;
		}

		@include bp(md) {
			padding: 0;
			flex: 0 0 50%;
			max-width: 50%;
		}

		h2 {
			color: #fff;
			text-align: center;

			.icon {
				fill: currentColor;
				display: block;
				margin: 0 auto;
			}
		}
	}

}
