.dbi-testimonials {
	background: url( ../images/bg-testimonials.jpg ); //linear-gradient(to bottom, #2e3335, #25292c);
	background-size: cover;
	color: #fff;
	padding: 2.5rem 0;

	.dbi-container {
		justify-content: center;
	}

	.dbi-stars {
		display: block;
		width: 5rem;
		margin: 0 auto;
		padding-right: 0;
		padding-bottom: 0;
	}

	.dbi-testimonials__content {
		width: 100%;
	}

	.dbi-testimonials__quote {
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY( -50% );
		display: none;

		@include bp(md) {
			display: inline;
		}

		.icon {
			width: 1.6rem;
			height: 0.8rem;
			fill: palette( slate, 600 );

			@include bp(sm) {
				width: 3.2rem;
				height: 1.6rem;
			}
		}

		&.dbi-testimonials__quote--left {
			//top: 0%;
		}

		&.dbi-testimonials__quote--right {
			left: auto;
			right: 0;
		}
	}

	.dbi-testimonials__content {
		@extend %cf;
	}

	blockquote {
		margin: 1rem 0 0 0;
		position: relative;

		@include bp(md) {
			margin-top: 2rem;
		}

		p {
			margin-bottom: 1rem;
			position: relative;
			padding-left: 20px;
			padding-right: 20px;

			@include bp(md) {
				text-align: center;
				padding-left: $column-width + $gutter-width;
				padding-right: $column-width + $gutter-width;
			}
		}

		cite {
			text-align: right;
			margin-bottom: 1rem;
			padding-right: 20px;
			float: right;
			display: flex;
			align-items: center;
			justify-content: center;

			@include bp(md) {
				padding-right: $column-width + $gutter-width*2;
			}

			img {
				margin-right: 1rem;
				width: 1.65rem;
				border-radius: 100%;
			}

			div {
				text-align: left; }
		}

		.dbi-testimonials__site {
			font-style: italic; }
	}

	@include bp(md) {
		.owl-carousel {
			padding-left: $column-width;
			padding-right: $column-width;
		}
	}

	.owl-pagination {
		justify-content: center;
		bottom: 1rem;
	}

	.owl-dots {
		margin-top: 0;
	}

	.dbi-btn {
		display: none;

		@include bp(md) {
			display: inline-block;
		}
	}

	.dbi-plugin-video-intro {
		margin-bottom: 2.5rem;
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 56.25%; /* 16:9 */

		iframe,
		video {
			position: absolute;
			width: 100%;
			height: 100%;
		}
	}
}
