$breakpoint-licence-small: 600px;

.wcss-Licence {
	overflow: hidden;
	background-color: #fff;
	margin-bottom: 3rem;
	padding: 0;
	border-radius: 8px;
	box-shadow: -10px 10px 20px 0 rgba( 30, 30, 30, 0.05 );

	hr {
		background: #e5e5e5;
		margin: 0;
	}

	p {
		margin-bottom: 0;
	}

	ul {
		margin-bottom: 0;
		list-style-type: none;
	}

	table {
		width: 100%;
	}

	td,
	th {
		font-size: 0.68rem;

		@media ( max-width: 900px ) {
			font-size: 0.76rem;
		}
	}

	tr:last-child {
		td,
		th {
			@include bp($breakpoint-licence-small) {
				border-bottom: none;
			}
		}
	}

	td {}

	th {
		text-align: left;
		vertical-align: top;
		padding-right: 1rem;
	}

	td:first-child,
	th:first-child {
		padding-left: 0;
	}

	table:not(.seats_table) tr:first-child {
		td,
		th {
			@include bp($breakpoint-licence-small) {
				padding-top: 0;
			}
		}
	}

	select {
		max-width: 90%;

		@include bp(max $breakpoint-licence-small) {
			max-width: 185px;
		}
	}

	td.wcss-Licence-older-versions {
		padding: 0;
		display: flex;
		align-items: center;
	}

	.wcss-Licence-downloads-tab {
		display: flex;
		flex-direction: column;

		.wcss-Licence-download-title {
			width: 21rem;
			font-size: 0.68rem;

			@media ( max-width: 960px ) {
				flex: 1;
			}

			@media ( max-width: 900px ) {
				font-size: 0.76rem;
			}

			@media ( max-width: 820px ) {
				width: auto;
				flex-basis: 100%;
				flex-wrap: wrap;
				margin-bottom: 1rem;
			}
		}
	}

	.wcss-Licence-download-items-wrap {
		display: none;
		width: 100%;
		padding: 0 1.25rem;
		border-bottom: 1px solid #e5e5e5;

		.wcss-Licence-inactive-notice {
			font-size: 0.6rem;
			line-height: 1.5;
			border-left: 5px solid #ee3a71;
			margin: 0.7rem 0;
			background-color: #ffe5ee;
			list-style-type: none;
			padding: 0.8rem 1rem;
			font-size: 16px;
			border-radius: 2px;
			font-weight: 700;
			color: #424242;
			font-family: lato, sans-serif;

			p {
				max-width: 800px;
			}
		}

		.wcss-Licence-download-items-table {
			width: 25.25rem;
			padding: 0.15rem;
			border-radius: 4px;
			background: #fff;
			box-shadow: 0 2px 3px 0 rgba( 0, 0, 0, 0.1 );
			overflow-y: scroll;
			height: 249px;
			width: 100%;
			margin: 0 auto 1.4rem;
			border: 1px solid #e5e5e5;

			@media ( max-width: 640px ) {
				width: auto;
				display: flex;
			}

			.wcss-Licence-download-items-table table.wcss-Licence-download-items tr:first-child td {
				text-align: left;
			}

			table.wcss-Licence-download-items {
				tr {
					&:nth-child(even) {
						background: #eff4f5;
					}

					&:first-child td {
						font-weight: bold;
					}

					td {
						font-size: 0.65rem;
						padding: 0.5rem 1rem;

						&:last-child {
							text-align: right;
						}
					}
				}
			}
		}
	}
}

.wcss-Licence-inactive-notice.wcss-Licence-expired {
	margin-bottom: 1rem;
}

.wcss-Licence-container {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem !important;
}

.wcss-Licence-product-title {
	font-size: 1rem;
	color: #fff;
	font-size: 1.05rem;
}

.wcss-Licence-product-type {
	margin-left: 15px;
	text-transform: uppercase;
	font-size: 0.55rem;
	font-weight: 600;
	padding: 0 0.35rem;
	border-radius: 2px;
	line-height: 2;
}

.wcss-Licence-key-container {
	display: flex;
	flex-direction: row;
	margin-left: auto;
	align-items: center;

	svg {
		width: 21px;
		height: 21px;
		opacity: 0.8;
	}

}

.click-to-copy {
	cursor: pointer;
	position: relative;

	&:hover::after {
		content: "Copy";
		font-family: ff( "body" );
		font-size: 90%;
		margin-left: 10px;
		color: palette( brand, 500 );
		position: absolute;

		.seats-table-container & {
			right: 0;
			background: #fff;
			padding: 0 10px;
		}

		.seats-table-container tr:nth-child(2n) & {
			background: #eff4f5;
		}
	}

	&.copied::after {
		content: "Copied!";
		font-family: ff( "body" );
		font-size: 90%;
		margin-left: 10px;
		position: absolute;

		.seats-table-container & {
			right: 0;
			background: #fff;
			padding: 0 10px;
		}

		.seats-table-container tr:nth-child(2n) & {
			background: #eff4f5;
		}
	}
}

.wcss-Licence-key {
	font-family: "Courier New", monospace;
	font-weight: 500;
	font-size: 0.7rem;
	color: #fff;
	margin-left: 15px;

	@media ( max-width: 900px ) {
		font-size: 0.76rem;
	}

	&.click-to-copy:hover::after {
		color: #fff;
	}
}

.wcss-Licence-summary {
	overflow: hidden;
}

.wcss-Licence-brand {
	display: flex;
	padding: 2.5rem 1.8rem;
	align-items: center;

	@media ( max-width: 960px ) {
		flex-direction: column;
	}
}

/*License Product Branding*/

/*MDB*/

.brand-mdb {
	background-image: linear-gradient( 135deg, #155684 0%, #06325d 100% );

	.wcss-Licence-product-type {
		color: #083d69;
		background: #a5ddf1;
	}

	a.current {
		border-bottom: 2px solid #005ef0;
	}

	.button.renewal {
		background: #005ef0;
		border: 1px solid #005ef0;

		&:hover {
			background: #2074f7;
			border: 1px solid #2074f7;
		}
	}
}

/*OME*/

.brand-os3 {
	background-image: linear-gradient( 135deg, #893248 0%, #44022e 100% );

	.wcss-Licence-product-type {
		color: #6a1944;
		background: #f78b7e;
	}

	a.current {
		border-bottom: 2px solid #c2526f;
	}

	.button.renewal {
		background: #f16453;
		border: 1px solid #f16453;

		&:hover {
			background: #ff8374;
			border: 1px solid #ff8374;
		}
	}
}

/*OSES*/

.brand-oses {
	background-image: linear-gradient( 135deg, #492e66 0%, #351e4d 100% );

	.wcss-Licence-product-type {
		color: #3b2255;
		background: #c5a2eb;
	}

	a.current {
		border-bottom: 2px solid #9d6fcf;
	}

	.button.renewal {
		background: #e99f21;
		border: 1px solid #e99f21;

		&:hover {
			background: #f9b33b;
			border: 1px solid #f9b33b;
		}
	}
}

.button.upgrade {
	padding: 0.425rem 0;
	width: 6.75rem;
	text-align: center;
	background: none;
	border: 1px solid #fff;

	&:hover {
		background: rgba( 255, 255, 255, 0.2 );
		border: 1px solid #fff;
	}

	@media ( max-width: 480px ) {
		width: 100%;
		margin-top: 0.75rem;
	}
}

.button.renewal {
	padding: 0.425rem 0;
	width: 6.75rem;
	text-align: center;
	border-radius: 3px;

	@media ( max-width: 480px ) {
		width: 100%;
	}
}

.wcss-Licence-title-key {
	margin-right: auto;
}

.wcss-Licence-actions {
	margin-left: auto;
	margin-top: 0;

	@media ( max-width: 960px ) {
		margin: 2rem auto 0 0;
	}

	@include bp(md) {
		float: right;
		clear: none;
	}

	@media ( max-width: 480px ) {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.button {
		margin-bottom: 0;
	}
}

.wcss-Licence-navigation {
	display: flex;
	flex-direction: row;
	background: #fff;
	width: 100%;
	margin-right: 1.6rem;

	ul::-webkit-scrollbar {
		display: none;
	}

	ul {
		margin: 0;
		padding: 0 1.25rem;
		display: flex;
		margin: 0 !important;
		box-shadow: 0 1px 6px 0 rgba( 0, 0, 0, 0.25 );
		width: 100%;

		@media ( max-width: 540px ) {
			overflow: scroll;
		}
	}

	li:last-of-type {
		padding-right: 1.25rem;
	}

	li {
		margin-right: 1.6rem;
	}

	a {
		box-sizing: border-box;
		display: inline-block;
		vertical-align: middle;
		cursor: pointer;
		font-size: 13px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 0.5px;
		text-decoration: none;
		font-family: ff( "body" );
		line-height: 1;
		border: 0;
		padding: 0.8rem 0 0.7rem;
		color: #8a8c8e;
		transition: all 0.2s $transition;
		margin: 0;
		width: 100%;
		height: 2.15rem;

		@media ( max-width: 960px ) {
			padding: 1rem 0 1.5rem;
		}

		&:active,
		&:focus,
		&:hover,
		&:visited {
			text-decoration: none;
		}

		&:active,
		&:focus {
			outline: none;
		}

		&:hover {
			color: #ee3b71;
		}

		&.current,
		&.current:hover {
			background-color: #fff;
			color: #000;
		}
	}
}

.wcss-Licence-tabs {
	background-color: #fff;

	.tab-title {
		opacity: 0.8;
		font-family: ff( body );
		font-weight: 600;
		font-size: 20px;
		color: #000;
		letter-spacing: 0;
		padding: 0.65rem 0;
		width: 12rem;

		@media ( max-width: 640px ) {
			width: fit-content;
		}

	}

	.licence-row {
		padding: 1rem 1.25rem;
		display: flex;
	}

	.licence-row-seats {
		border-bottom: 0;
		padding: 1rem 1.25rem 0;
		display: flex;
	}

	.wcss-Licence-download-addon,
	.wcss-Licence-download-beta,
	.wcss-Licence-download-core {
		border-top: 1px solid #e5e5e5;
		padding: 1rem 1.25rem;
		display: flex;

		@media ( max-width: 960px ) {
			flex-wrap: wrap;
		}

		.wcss-Licence-download a {
			font-weight: 600;
		}
	}

	.wcss-Licence-row-top-border {
		border-top: 1px solid #e5e5e5;
	}

	.wcss-Licence-addons-title {
		text-transform: uppercase;
		background: #f7f7f7;
		border-top: 1px solid #e5e5e5;
		padding: 0.7rem 1.25rem;
		display: flex;
		font-size: 13px !important;

		th {
			font-size: 0.6rem;
			font-weight: 400;
		}
	}

	table {
		td,
		th {
			@include bp(max $breakpoint-licence-small) {
				display: block;
				padding: 0.5rem 0;
			}
		}

		th {
			width: 12rem;

			@media ( max-width: 640px ) {
				flex: 1;
				width: auto;
			}
		}

		tr:last-child {
			border-bottom: 0;

			td:last-child {
				@include bp(max $breakpoint-licence-small) {
					padding-bottom: 0;
					border-bottom: none;
				}
			}
		}
	}
}

.wcss-Licence-subscription-tab {
	td {
		display: flex;
		justify-content: flex-end;
		width: 12rem;

		@media ( max-width: 960px ) {
			width: fit-content;
		}

		.license-table-block-right {
			text-align: right;
		}
	}

	.btn-checkout .button {
		display: none;
	}
}

.wcss-Licence-subscription-auto-renewal,
.wcss-Licence-subscription-next-payment,
.wcss-Licence-subscription-status {
	border-top: 1px solid #e5e5e5;
	padding: 1rem 1.25rem;
	display: flex;
	align-items: center;
}

.wcss-Licence-subscription-status {
	min-height: 102px;

	@media ( max-width: 960px ) {
		flex-wrap: wrap;
	}
}

.wcss-Licence-download-core {
	td,
	th {
		font-size: 0.7rem;

		@media ( max-width: 900px ) {
			font-size: 0.76rem;
		}
	}

	.wcss-Licence-download-title {
		font-weight: bold;
	}

	a {
		text-decoration: none;
	}
}

td.wcss-Licence-changelog,
td.wcss-Licence-download {
	margin-right: 1.6rem;
}

.wcss-Licence-download-addon,
.wcss-Licence-download-beta {
	th:first-child {
		padding-left: 0.5rem;
		font-weight: normal;

		@include bp($breakpoint-licence-small) {
			padding-left: 1rem;
		}
	}

	td.wcss-Licence-changelog,
	td.wcss-Licence-older-versions {
		@include bp(max $breakpoint-licence-small) {
			padding-left: 0;
		}
	}
}

td.wcss-Licence-older-versions {
	a .nav-arrow {
		display: inline-block;
		transition: transform 0.2s ease;
		cursor: pointer;

		svg {
			fill: #ee3a71;
			width: 0.5rem;
			height: 0.5rem;
			pointer-events: none;
			margin-left: 0.15rem;
		}
	}
}

tr.open td.wcss-Licence-older-versions .nav-arrow {
	transform: rotate( 180deg );
}

.wcss-Switch {
	position: relative;
	display: inline-block;
	padding: 4px;
	overflow: hidden;
	border-radius: 3px;
	background-color: #909aa3;
	cursor: pointer;

	span {
		box-sizing: border-box;
		float: left;
		display: inline-block;
		height: 100%;
		font-size: 0.65rem;
		line-height: 0.7rem;
		border-radius: 3px;
		font-weight: bold;
		padding: 0.25rem 0.45rem;
		background: #fff;
		color: #8d8d8d;
		z-index: 1;
	}

	.wcss-Switch-on {
		visibility: hidden;
		color: #46a33b;
	}

	&.wcss-Switch-status-on {
		background-color: #7ac172;

		.wcss-Switch-on {
			visibility: visible;
		}

		.wcss-Switch-off {
			visibility: hidden;
		}
	}
}

.wcss-Licence-pie {
	float: left;
	margin-right: 1rem;

	svg {
		width: 50px;
		height: 50px;
		transform: rotate( -90deg );
		border-radius: 50%;
		background-color: #ddd;
	}
}

.wcss-Licence-pie-slice {
	fill: transparent;
	stroke: #86db91;
	stroke-width: 32;
}

.wcss-Licence-activations-usage-desc {
	font-size: 0.7rem;
	font-weight: bold;
	margin-bottom: 0.3rem !important;
	margin-right: 0.3rem;

	@media ( max-width: 900px ) {
		font-size: 0.76rem;
	}
}

.wcss-Licence-support-tab {
	h4 {
		font-size: 0.7rem;
		font-weight: bold;
		font-family: ff( body );
		line-height: 1rem;

		@media ( max-width: 900px ) {
			font-size: 0.76rem;
		}
	}
}

.wcss-Licence-support-access,
.wcss-Licence-support-email {
	padding: 1rem 1.25rem;
	display: flex;
	flex-direction: column;
	border-top: 1px solid #e5e5e5;
	margin-bottom: 0 !important;

	p {
		padding: 0 !important;
		margin-top: 0.5rem !important;
	}
}

.wcss-Licence-support-access-change {
	font-weight: bold;
}

.wcss-Licence-support-tab {
	p {
		font-size: 0.68rem;

		@media ( max-width: 900px ) {
			font-size: 0.76rem;
		}
	}
}

.wcss-Licence-support-access-emails {
	font-weight: 700;
	font-size: 0.8rem !important;
	color: #8d8d8d;
}

.wcss-Licence-compatibility-notice {
	padding: 1rem 1.25rem 0;
	display: flex;
}

.stepper-input.stepper-input {
	display: flex;

	.button {
		width: 1.5rem;
		margin: 0;
		font-weight: 400;
		display: flex;
		justify-content: center;
		position: relative;
		background: #fff;
		border: 1px solid #c6c6c6;
		padding: 0.35rem;

		&:hover {
			background: #e6e6e6;
			border-color: #b8b8b8;
		}
	}

	.button-add {
		margin-left: -2px;
		border-radius: 0 3px 3px 0;
	}

	.button-subtract {
		margin-right: -2px;
		border-radius: 3px 0 0 3px;
	}

	svg {
		width: 16px;
		height: 16px;
	}

	input[type="text"] {
		padding: 0;
		margin: 0;
		width: 2.6rem;
		font-size: 0.75rem;
		text-align: center;
		border: 1px solid #c6c6c6;
		border-radius: 0;
	}

	input:focus {
		outline: 0;
	}
}

.btn-checkout {
	display: flex;
	margin-left: auto;
}

.seat-info {
	display: flex;
	margin-left: auto;
	align-items: center;
}

.seats-assigned {
	margin-right: 1.5rem;
}

.seats-add {
	font-weight: 600;
}

.seats_table {
	th {
		padding: 1rem !important;
		display: flex;
		align-items: center;
		min-height: 2.25rem;
		font-size: 0.6rem;
		width: auto !important;
	}

	tr:nth-child(2n) {
		background-color: #eff4f5;
	}

	.seats_table tr td {
		padding: 1rem !important;
	}

	.seats_table tr td {
		padding: 1rem !important;
	}

	.seats_table th {
		padding: 1rem !important;
	}

	.button,
	.dbi-btn {
		padding: 0.4rem 0.8rem !important;
	}

	td,
	tr {
		display: flex;
		align-items: center;
		text-transform: none !important;

		.col-1 {
			flex: 0;
		}

		.col-2 {
			flex: 2;

			@media ( min-width: 960px ) {
				flex-basis: 8%;
				max-width: 160px;
			}
		}

		.col-3 {
			flex: 2;
			word-break: break-word;

			@media ( min-width: 960px ) {
				flex-basis: 8%;
				max-width: 280px;
			}

			@media ( max-width: 720px ) {
				flex-basis: 100%;
				padding-top: 0 !important;
			}
		}

		.col-4 {
			flex: 5;
			flex-basis: 15%;
			max-width: 390px;

			@media ( max-width: 960px ) {
				flex-basis: 100%;
				padding-top: 0 !important;
			}
		}

		.col-5 {
			flex: 2.5;
			flex-basis: 5%;

			@media ( min-width: 600px ) {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}

			@media ( min-width: 960px ) {
				max-width: 180px;
			}

			@media ( max-width: 960px ) {
				flex-basis: 100%;
				padding-top: 0 !important;
			}
		}
	}

	th.col-3 {
		@media ( max-width: 720px ) {
			display: none;
		}
	}

	th.col-5,
	th.col-4 {
		@media ( max-width: 960px ) {
			display: none;
		}
	}

	td:first-child {
		padding-left: 1rem;
		display: flex;
		align-items: center;
	}

	tbody td {
		min-height: 2.8rem;

		@media ( max-width: 640px ) {
			min-height: 2.5rem;
		}
	}

	tbody {
		display: block;
		max-height: 21rem;
		overflow: auto;
	}

	thead {
		background: #dfe4e9 !important;
	}

	tr {
		flex-wrap: wrap;
		display: flex;
	}

	td.empty {
		flex: 11;
		font-weight: bold;
		color: rgba( 0, 0, 0, 0.3 );
		font-size: 15px;
	}

	input {
		font-size: 15px;
		width: 100%;
	}

	.assign-seat {
		margin-left: auto;
	}

	.removing-seat {
		pointer-events: none;
		cursor: not-allowed;

		.edit-seat {
			display: none;
		}

		.remove-seat {
			margin-left: auto;
		}
	}
}

.seats-table-container {
	padding: 1rem 1.25rem 0;
	margin-top: 0;

	tr.inactive-seat {
		td:not(.actions) {
			opacity: 0.4;
		}

		td.actions {
			flex: 0;

			.edit-seat {
				display: none;
			}
		}
	}
}

.key {
	font-family: "Courier New", monospace;
	font-weight: 600;
	opacity: 0.8;
}

.white {
	color: #fff !important;
}

.notice-seats-downgrade {
	background: #ffedd2;
	border: 1px solid #ffb772;
	border-radius: 4px;
	padding: 0.5rem;
	font-size: 14px;
	color: #c36d1a;
	margin: -15px 0.7rem 1rem 1.25rem;
	width: 100%;

	span {
		font-weight: 700;
	}
}

.dbi-checkout-order-details {
	.wcss-Licence {
		box-shadow: -10px 10px 20px 0 rgba( 30, 30, 30, 0.1 );
		width: 100%;
	}
}

.tooltip-saved {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	width: 12rem;
	opacity: 0;
	transition: all 500ms;

	@media ( max-width: 960px ) {
		width: fit-content;
	}

	@media ( max-width: 640px ) {
		margin-left: auto;
	}

	.save-icon {
		width: 18px;
		height: 18px;
		background: palette( brand, 500 );
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-right: 10px;
	}

	.icon-checkmark {
		width: 12px;
		height: 12px;
		display: flex;
		align-items: center;
		fill: #fff;
	}

	p {
		color: palette( grey, 200 );
		font-size: 15px;
		font-weight: 700;
	}
}

.seats_table {
	@media ( min-width: 720px ) and ( max-width: 960px ) {
		tr,
		td {
			.col-2 {
				flex: 3;
			}

			.col-3 {
				flex: 4;
			}
		}
	}

	@media ( max-width: 599px ) {
		td {
			padding: 0.5rem !important;
		}

		.col-1,
		.seat-number {
			display: none !important;
		}
	}
}
