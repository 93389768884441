.masonry-grid {
	width: 105%;
	height: auto;
	margin: 0 -2.5%;
}

.masonry-grid__item,
.masonry-grid__item--sizer {
	width: 100%;
	padding: 0 2.5%;

	@include bp(md) {
		width: 50%;
	}
}
