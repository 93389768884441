.dbi-site__signup {
	display: flex;
	text-align: center;
	justify-content: center;
	align-items: center;
	background-color: palette( grey, 900 );
	flex-direction: column;
	padding: 1.25rem 5%;

	.form-group {
		width: 100%;

		@include bp(md) {
			width: auto;
		}
	}

	@include bp(md) {
		flex-direction: row;
		padding: 1.25rem;
	}

	input[type="email"] {
		@include bp(lg) {
			min-width: 15rem;
		}
	}
}

.dbi__signup-errors {
	padding: 1.25rem 5%;
	background-color: palette( error, 200 );
	color: palette( slate, 500 );
	text-align: center;
}

.dbi-site__signup,
.dbi-well__signup {
	input[type="email"] {
		margin-bottom: 0.5rem;

		@include bp(md) {
			margin-bottom: 0;
		}
	}

	input[type="text"] {
		display: none;
	}

	&.expanded {
		label {
			display: none;
		}

		input[type="text"] {
			display: block;
		}

		input[type="email"],
		input[type="text"] {
			margin-bottom: 0.5rem;

			@include bp(md) {
				margin-bottom: 0;
				margin-right: 0.5rem;
				border: 1px solid palette( grey, 600 );
				border-radius: 0.15rem;
			}
		}

		.dbi-btn {
			border-radius: 0.15rem;
		}
	}
}

.dbi-well {
	.dbi-well__signup {
		&.expanded {
			.form-group {
				flex-flow: wrap;

				input {
					flex: 1 100%;
					margin: 0 0 0.5rem;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.dbi__signup-errors {
		padding: 0.5rem;
		margin-bottom: 0.5rem;
		background-color: #292b2d;
		color: #fff;
	}
}

.cf-drip-form {
	.field {
		margin-bottom: 0.5rem;

		input {
			width: 100%;
		}
	}

	.dbi-btn {
		margin-bottom: 0;
	}
}

body[class*="my-account"] .dbi-site__signup,
body.signin .dbi-site__signup {
	background-color: #fcfcfc;
}
