.alert-bar:not(.alert-bar-footer) {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.25rem 2rem;
	justify-content: center;
	z-index: 9999;

	@media (max-width: 960px) {
		flex-direction: column;
		padding: 1rem !important;
	}

	p {
		margin: 0.6rem 2.5rem;
		font-weight: 600;

		//reduce margin for mobile devices
		@media (max-width: 960px) {
			margin: 1rem 0 0 0;
		}

		a {
			text-decoration: underline;
			color: #ffdaa2;

			&:hover {
				filter: brightness(0.9);
			}
		}
	}
}

.alert-bar {
	font-family: ff("body");
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.42857;
	p {
		letter-spacing: normal;
		font-size: 0.75rem;
	}
	.dbi-btn {
		padding: 0.4rem 0.8rem !important;
		text-transform: uppercase;
		font-size: 0.6rem;
		white-space: nowrap;

		@media (max-width: 960px) {
			margin: 1rem 0 0 0;
		}

		&:hover {
			color: #f03770;
			box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.05),
				0 2px 1px 0 rgba(0, 0, 0, 0.15);
		}
	}

	.dismiss {
		right: 20px;
		box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.3);

		@media (max-width: 960px) {
			top: 32px;
		}
	}

	.alert-bar__countdown {
		vertical-align: text-bottom;
		padding: 0.3rem 0.75rem;
		border-radius: 5px;
		font-size: 0.9rem;
		font-weight: 600;
		white-space: nowrap;
		background: rgba(0, 0, 0, 0.25);
		margin: 0;

		small {
			margin-right: 0.2rem !important;
			opacity: 0.75;
		}

		.days,
		.hours,
		.minutes,
		.seconds {
			margin-right: -2px;
		}
	}

	&.alert-bar--narrow {
			padding: 0.5rem 2rem!important;

			@media (max-width: 960px) {
				padding: 1rem 2rem!important;
			}

		p {
			color: #fff !important;
			margin: 0 !important;
		}

		br.hide-if-minimized {
			display: inline;
			content: " ";
			clear: none;

			&::after {
				content: " ";
			}
		}

		.dbi-btn {
			color: #fff !important;
			margin-left: 1rem;
			padding: 0!important;
			text-decoration: underline;

			@media (max-width: 960px) {
				margin: .5rem 0 0 0;
			}

			&:hover {
				box-shadow: none;
			}
		}
	}
}

body.wp-migrate-db-pro .alert-bar .alert-bar__countdown,
body.wp-offload-media .alert-bar .alert-bar__countdown,
body.wp-offload-ses .alert-bar .alert-bar__countdown {
	vertical-align: inherit;
}
