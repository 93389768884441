body[class*="wp-migrate-db-pro"] {
	--levelup-accent: #0052ff;
	--levelup-btn-hover: #004ae6;
	--levelup-btn-active: #0042cc;
	--levelup-active-link: rgba( 0, 82, 255, 0.05 );
	--levelup-focus: rgba( 73, 196, 255, 0.8 );
}

body[class*="wp-offload-ses"] {
	--levelup-accent: #7b38d7;
	--levelup-btn-hover: #6f32c2;
	--levelup-btn-active: #622dac;
	--levelup-active-link: rgba( 123, 56, 215, 0.05 );
	--levelup-focus: rgba( 218, 88, 255, 0.8 );
}

body[class*="wp-offload-media"] {
	--levelup-accent: #f16453;
	--levelup-btn-hover: #d95a4b;
	--levelup-btn-active: #c15042;
	--levelup-active-link: rgba( 241, 100, 83, 0.05 );
	--levelup-focus: rgba( 255, 186, 96, 0.8 );
}

body {
	&.blog,
	&.archive,
	&.search {
		--levelup-accent: #ed3a71;
		--levelup-btn-hover: #d53466;
		--levelup-btn-active: #be2e5a;
		--levelup-active-link: rgba( 237, 58, 113, 0.05 );
		--levelup-focus: rgba( 255, 106, 151, 0.8 );
	}
}

%posts-layout {
	display: flex;
	flex-wrap: wrap;

	.post {
		background: #fff;
		border: 1px solid #eff0f0;
		box-shadow: 0 1px 4px 0 rgba( 0, 0, 0, 0.05 );
		border-radius: 14px;
		padding: 0.65rem;
		transition: all 0.2s ease-in-out;
		margin-bottom: 2.75rem;
		cursor: pointer;
		transition: all 0.3s ease-in-out;

		&:hover {
			box-shadow: 0 5px 20px 0 rgba( 141, 151, 158, 0.5 );
			transition: all 0.3s ease-in-out;
		}

		&:hover h3 {
			color: #2e3a43;
		}

		h3 {
			font-weight: 600;
			color: #3f4e5a;
			line-height: 1.3;
			font-family: ff( "headings" );
			margin-bottom: 0.2rem;
		}

		.info {
			display: flex;
			padding: 0.3rem;
			margin-top: auto;

			.author {
				display: flex;
				align-items: center;
				margin-right: auto;

				.avatar {
					border-radius: 50%;
					width: 36px;
					height: 36px;
				}

				p {
					font-weight: 500;
					font-size: 0.55rem;
					color: #7f7f7f;
					margin: 0 0 0 0.6rem;
				}
			}

			.date {
				align-items: center;
				display: flex;

				h4 {
					font-family: ff( "body" );
					font-weight: 700;
					font-size: 0.55rem;
					color: palette( brand, 500 );
					text-align: right;
					margin-left: auto;
				}
			}
		}
	}

	/*Vertical card layout*/
	.vert-layout {
		width: 14.25rem;
		display: flex;
		flex-direction: column;
		overflow: hidden;

		@media ( max-width: 1160px ) {
			width: auto;
		}

		.thumbnail {
			width: 100%;
			height: 7.7rem;
			overflow: hidden;
			border-radius: 8px;
			margin-bottom: 0.5rem;

			@media ( max-width: 1160px ) {
				height: initial;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		.post-content {
			padding: 0.3rem;
			margin-bottom: auto;
			display: flex;
			flex-direction: column;
			flex: 1;

			h3 {
				font-size: 0.95rem;
				margin-bottom: 0.8rem;
			}

			p {
				font-size: 14px;
				color: #7f7f7f;
				line-height: 1.5;
				margin-bottom: 0.75rem;
				margin-top: auto;

				@media ( max-width: 1160px ) {
					height: auto;
				}
			}
		}
	}

	.horz-layout,
	.vert-layout {
		position: relative;

		.post-link {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			cursor: pointer;
		}
	}

	/*Horizontal card layout*/
	.horz-layout {
		width: 24rem;
		margin-bottom: 1.1rem;
		display: flex;
		max-width: 100%;

		@media ( max-width: 480px ) {
			display: block;

			.thumbnail.thumbnail {
				width: 100%;
				margin-bottom: 0.8rem;
			}
		}

		@media ( max-width: 820px ) {
			width: 100%;
			margin-bottom: 2.25rem;
		}

		.col {
			flex-direction: column;
			display: flex;
			flex: 1;

			@media ( max-width: 820px ) {
				justify-content: space-around;
				width: 100%;
			}
		}

		.thumbnail {
			width: 152px;
			min-width: 152px;
			height: 100%;
			overflow: hidden;
			border-radius: 8px;
			margin-bottom: 0;
			margin-right: 0.8rem;

			@media ( max-width: 480px ) {
				height: initial;
			}

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}

		h3 {
			font-size: 0.7rem;
			line-height: 1.25;
			font-weight: 600;
			letter-spacing: -0.005rem;

			.no-widows {
				display: inline-block;
			}

			@media ( max-width: 820px ) {
				font-size: 0.8rem;
				line-height: 1.35;
				height: auto;
			}

			@media ( max-width: 480px ) {
				font-size: 0.65rem;
			}
		}

		.info {
			display: flex;
			padding: 0;

			@media ( max-width: 480px ) {
				margin-top: 0.3rem;
			}

			.author {
				display: flex;
				align-items: center;
				margin-right: auto;

				.avatar {
					width: 18px;
					height: 18px;
					display: flex;
				}

				p {
					font-size: 0.45rem;
					margin: 0 0 0 0.3rem;
				}
			}

			.date {
				align-items: center;
				display: flex;

				h4 {
					font-family: ff( "body" );
					font-size: 0.45rem;
				}
			}
		}
	}
}

body.blog,
body.archive,
body.search,
.page-template-template-level-up {
	symbol[id^="icon-levelup"] g {
		stroke: var( --levelup-accent );
	}

	.levelup-category-nav svg {
		fill: var( --levelup-accent );
	}
}

.levelup-category-nav {
	width: 100%;
	background: #fff;
	box-shadow: 0 1px 1px 0 rgba( 0, 0, 0, 0.05 );
	padding: 0;
	border-bottom: 1px solid #dcd9d9;

	.inner {
		position: relative;
		z-index: 10;
		left: 50%;
		width: 100%;
		max-width: 1170px;
		transform: translateX( -50% );
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	ul {
		padding: 0;
		display: flex;
		margin: 0 auto;

		@media ( max-width: 768px ) {
			overflow-x: scroll;
		}

		li {
			list-style: none;

			a.active {
				box-shadow: 0 -4px 0 var( --levelup-accent ) inset;
			}

			&.inactive:not(.inactive-category):hover a {
				box-shadow: none;
			}

			&.inactive:not(.inactive-category) {
				opacity: 0.4;
				user-select: none;

				a {
					pointer-events: none;
				}
			}

			.category-name {
				display: flex;
				position: relative;
			}

			.results-counter {
				width: 18px;
				height: 18px;
				background: #93a7cd;
				color: #fff;
				font-weight: 600;
				font-size: 11px;
				line-height: 100%;
				border-radius: 50%;
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				position: absolute;
				right: -29px;
				top: -1px;
				padding: 0 0 1px 0;
			}

			a {
				display: flex;
				align-items: center;
				flex-direction: column;
				font-size: 0.53rem;
				color: #7182a3;
				text-transform: uppercase;
				font-weight: 600;
				text-align: center;
				padding: 1rem 1.7rem;
				height: 100%;
				justify-content: center;

				&.active,
				&:hover {
					background: var( --levelup-active-link );
					text-decoration: none;
					color: var( --levelup-accent );
				}

				&:focus:not(:focus-visible) {
					outline: none;
				}

				&:focus-visible {
					outline: 2px solid var( --levelup-focus );
					outline-offset: -2px;
				}
			}
		}
	}

	.icon-levelup {
		width: 20px;
		height: 20px;
		margin-bottom: 0.8rem;
	}
}

#levelup-content {
	background: #f4f4f4;

	.no-search-results {
		text-align: center;
		margin: auto;

		h2 {
			margin-bottom: 1rem;
		}

		img {
			border-radius: 6px;
		}
	}

	h2 {
		font-family: ff( "headings" );
		font-size: 1.1rem;
		text-transform: none;
		color: #333e53;

		&.section-title {
			font-size: 1.391rem;
			display: flex;
			align-items: center;
		}

		svg {
			width: 36px;
			height: 36px;
			margin-right: 16px;
		}

		@media ( max-width: 820px ) {
			padding: 1rem 0;
			text-align: center;
		}
	}

	.inner {
		position: relative;
		padding: 3rem 30px;
		width: 100%;
		max-width: 1200px;
		margin: auto;

		body:not(.blog) & {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-between;
		}

		@media ( max-width: 1160px ) {
			padding: 3rem 50px;
			justify-content: space-around;
		}

		@media ( max-width: 920px ) {
			padding: 3rem 30px;
		}
	}

	.search-results-posts {
		width: 100%;
		align-items: baseline !important;

		span {
			font-weight: 400;
			font-size: 15px;
			color: #808693;
			margin-left: 0.6rem;
		}

		.posts {
			display: flex;
			justify-content: space-between;

			&::after {
				content: "";
				width: 14.25rem;
			}

			.post {
				@media ( max-width: 1160px ) {
					width: 46%;
				}

				@media ( max-width: 768px ) {
					width: 100%;
				}
			}
		}
	}

	.featured-posts, .recent-posts {
		h2 {
			display: flex;
			align-items: center;
		}
	}

	.featured-posts {
		width: 30.5rem;

		@media ( max-width: 1160px ) {
			width: 45%;
		}

		@media ( max-width: 920px ) {
			width: 100%;
		}

		.posts {
			@media ( max-width: 920px ) {
				justify-content: space-between;
				display: flex;
			}

			.post {
				@media ( max-width: 920px ) {
					width: 45%;
				}

				@media ( max-width: 640px ) {
					width: 100%;
				}

				&:nth-child(odd) {
					margin-right: 1.2rem;

					@media ( max-width: 1160px ) {
						margin-right: 0;
					}
				}
			}
		}
	}

	.recent-posts {
		width: 16.5rem;
		display: flex;
		flex-direction: column;
		margin-left: auto;
		margin-bottom: 80px;

		@media ( max-width: 1160px ) {
			margin-left: 0;
		}

		@media ( max-width: 820px ) {
			width: 100%;
		}
	}

	h2 {
		margin-bottom: 2.7rem;
	}

	.posts {
		@extend %posts-layout;
	}
}

.dbi-page-header.dbi-page-header {
	position: relative;

	> *:not(.dbi-menu) {
		position: relative;
		z-index: 1;
	}

	img.background-icon {
		position: absolute;
		z-index: 0;
		user-select: none;
		bottom: 0;
		left: 0;

		@media ( max-width: 960px ) {
			display: none;
		}
	}

}

body.blog,
body.archive,
body.search {
	#levelup-content .inner {
		max-width: 1300px;
	}

	#levelup-content {
		.recent-posts,
		.featured-posts {
			width: 100%;
		}

		.featured-posts {
			margin-bottom: 80px;

			.posts {
				.vert-layout {
					@media ( min-width: 641px ) {
						width: 47.6% !important;
						&.post:nth-child(odd) {
							margin-right: 4.8%;
						}
					}


					.thumbnail {
						height: 13rem;
					}
				}
			}
		}

		.recent-posts {
			.dbi-btn {
				background: #ed3a71;
				margin: 0 auto;
				&:hover {
					color: #fff;
					background-color: #cb124a;
					border-color: #cb124a;
				}
			}

			.posts {
				justify-content: space-between;

				&::after {
					content: "";
					width: 16.522rem;
				}

				.vert-layout {
					@media ( min-width: 1180px ) {
						width: 16.522rem;
					}

					@media ( min-width: 641px ) and ( max-width: 1179px ) {
						width: 47.6%;
					}
				}
			}
		}

		.dbi-pagination {
			max-width: 640px;
		}
	}
}

.delicious-brainwaves {
	background: url( ../images/blog/bg-podcast.jpg ) bottom no-repeat #14141e;
	padding: 8rem 1rem 6.25rem;
	text-align: center;
	background-size: contain;

	.inner {
		max-width: 840px;
		padding: 0 30px;
		margin: auto;
	}

	.logo {
		max-width: 244px;
		height: auto;
	}

	p {
		font-size: 21px;
		color: #778194;
		font-family: ff( "headings" );
		letter-spacing: -0.23px;
		margin-top: 1.9rem;
		margin-bottom: 3.5rem;
	}

	.embed {
		max-width: 500px;
		margin: auto;
	}

	.dbi-btn {
		background: #ed3a71;
		margin-top: 3.5rem;
	}
}
