.dbi-job-listing {
	max-width: $narrow-max-width;
	position: relative;
	left: 50%;
	transform: translateX( -50% );
	display: flex;
	flex-wrap: wrap;
	padding-left: 5%;
	padding-right: 5%;
	padding-bottom: 1rem;
	margin-bottom: 1rem;
	margin-top: 1.5rem;
	color: rgba( 0, 0, 0, 0.84 );
	font-size: 0.8rem;
	line-height: 1.6;

	p {
		margin-bottom: 1rem;
		line-height: inherit;
	}

	ul {
		margin-bottom: 0.75rem;
	}

	@include bp(md) {
		margin-top: 4rem;
	}

	@include bp(1220px) {
		padding-left: 0;
		padding-right: 0;
	}

	.dbi-job-listing__description {
		margin-bottom: 2rem;

		h2 {
			margin-bottom: 1.2rem;
			line-height: 1.2;
			color: rgba( 0, 0, 0, 0.84 );
			text-transform: uppercase;
		}

		h3 {
			font-size: 1.1rem;
			margin-bottom: 0.75rem;
			text-transform: inherit;
			font-weight: 700;
			color: rgba( 0, 0, 0, 0.84 );
			letter-spacing: inherit;
		}

		small {
			font-size: 0.65rem;
		}

		.dbi-job-listing__avatar {
			float: left;
			margin: 0 1rem 1rem 0;
			width: 90px;
			height: 90px;
		}

		.dbi-job-listing__avatar--left {
			@include bp(xl) {
				position: absolute;
				top: 0;
				left: -25%;
				width: 128px;
				height: 128px;
			}
		}
	}
}
