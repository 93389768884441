
.woocommerce {
	max-width: $xlarge-max-width;
	margin: 0 auto;
	padding: 3rem 20px;

	@include bp(md) {
		margin-bottom: 1rem;
	}

	.button-primary-lg {
		background: #ee3a71 !important;
		box-shadow: 0 4px 0 0 #d52f62;
		border-radius: 3px;
		display: flex;
		color: #fff !important;

		&:hover {
			background: #d52f62 !important;
			box-shadow: 0 4px 0 0 #bf2554;
		}
	}

	form {
		.form-row {
			margin-bottom: 1rem;
		}

		label {
			display: block;
			margin-bottom: 0.3rem;
		}

		label.inline {
			margin-bottom: 0;
		}

		input[type="email"],
		input[type="password"],
		input[type="tel"],
		input[type="text"] {
			width: 100%;
		}
	}

	.dbi-pricing__table {
		margin-top: 0;
		padding-bottom: 0;
	}
}

body.signin,
body.woocommerce-lost-password {
	.woocommerce {
		max-width: 400px;
	}
}

body.checkout {
	.woocommerce {
		max-width: $max-width;
		padding: 2rem 20px;
	}
}

section.cross-sell {
	position: relative;
	flex: 0 1 48.75%;
	flex-direction: row;

	@media ( max-width: 900px ) {
		flex: 0 1 100%;
	}

	// &:last-of-type {
	// 	flex: 0 1 100%;
	// }

	.cross-sell-content {
		display: flex;
		justify-content: flex-start;
		align-content: flex-start;
		align-items: flex-start;
		height: 100%;
	}

	.inner {
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		height: 100%;
	}

	.brand {
		margin: {
			left: 32px;
		};
	}

	h1 {
		font-family: ff( "body" );
		font-size: 20px;
		font-weight: 700;
		margin: {
			bottom: 8px;
		};
		letter-spacing: 0;
		line-height: 1.3;
		color: #424242;
	}

	.chip {
		display: inline-block;
		margin: {
			bottom: 16px;
		};
		padding: 4px 6px;
		background-color: palette( grey, 500 );
		color: #fff;
		border-radius: 6px;
		font-size: 0.5rem;
		text-transform: uppercase;
		width: fit-content;
	}

	.product-description {
		margin-bottom: 16px;
	}

	.product {
		padding: 8px;
		border-radius: 100%;

		@media ( max-width: 640px ) {
			padding: 4px;
		}

		svg {
			display: block;
		}

		&.product-mdb {
			background-color: rgba(palette( mdb, secondary ), .3);
		}

		&.product-oses {
			background-color: rgba(palette( oses, secondary ), .3);
		}

		&.product-os3 {
			background-color: rgba(palette( os3, primary-alt ), .3);
		}

		&.product-spinupwp {
			background-color: rgba(palette( suwp, secondary ), .3);
		}

		&.product-acf {
			background-color: rgba(palette( acf, secondary ), .3);
		}

	}

	.icon {
		fill: #fff;
		width: 64px;
		height: 64px;

		@media ( max-width: 640px ) {
			width: 40px;
			height: 40px;
		}

	}

	.cross-link {
		display: block;
		margin: {
			top: auto;
			bottom: 8px;
		};
		font-weight: 600;

		&:hover {
			text-decoration: none;
		}

		.icon {
			fill: currentColor;
			width: 20px;
			height: 11px;
			vertical-align: top;
			position: relative;
			left: 0.2rem;
			float: none;
			margin-top: 6px;
		}

	}

}

.shop_table {
	width: 100%;

	&:not(.woocommerce-checkout-review-order-table) {
		background-color: #fff;
		margin-bottom: 1.25rem;
		padding: 0.3rem;
		border-radius: 8px;
		box-shadow: -10px 10px 20px 0 rgba( 30, 30, 30, 0.05 );
		border: 1px solid #e0dfdf;
		border-collapse: separate;

		tr {
			font-size: 0.6rem;

			@media ( max-width: 768px ) {
				display: flex;
				flex-wrap: wrap;
			}
		}

		thead {
			background: #eff4f5;
		}

		tr:nth-child(2n) {
			td,
			th {
				background-color: #eff4f5 !important;
			}
		}
	}

	td,
	th {
		padding: 0.9rem;
	}

	.button {
		padding: 0.5rem 1rem !important;
	}

	thead {
		td,
		th {
			text-align: left;
			text-transform: uppercase;

			@media ( max-width: 960px ) {
				flex: 1;
			}
		}
	}

	th.woocommerce-orders-table__header.woocommerce-orders-table__header-order-actions {
		@media ( max-width: 960px ) {
			flex: 0 !important;
			padding: 0 !important;
		}
	}

	td {
		font-size: 0.65rem;

		a {
			font-weight: 800;
		}
	}

	tbody {
		th {
			text-align: left;
			text-transform: uppercase;
		}
	}
}

.woocommerce-orders-table__cell-order-date,
.woocommerce-orders-table__cell-order-number,
.woocommerce-orders-table__cell-order-status,
.woocommerce-orders-table__cell-order-total {
	@media ( max-width: 960px ) {
		flex: 1;
	}
}

.woocommerce-orders-table__header-order-actions {
	span {
		@media ( max-width: 960px ) {
			display: none;
		}
	}
}

.woocommerce-orders-table__cell-order-actions {
	@media ( max-width: 960px ) {
		display: flex;
		flex-basis: 100%;
	}
}

.woocommerce-MyAccount-licences {
	.auto-renewal {
		p {
			margin-bottom: 0;
		}

		input {
			margin-right: 0.2rem;
		}

		label {
			display: inline-block;
			color: #000;
			font-size: inherit;
			padding-right: 1rem;
		}

		select {
			display: block;
			margin-bottom: 0.5rem;
		}

		.button {
			font-size: inherit;
			background-color: #999;
			display: block;
		}
	}
}

body.woocommerce-account,
body.woocommerce-checkout {
	.select2-selection {
		height: 47px;
		border-color: palette( grey, 600 );
		border-radius: 0.15rem;
	}

	.select2-container--default .select2-selection--single {
		.select2-selection__rendered {
			line-height: 45px;
			padding-left: 0.75em;
			padding-right: 45px;
			color: #333;
			font-size: 0.8rem;
		}

		.select2-selection__arrow {
			height: 45px;
			width: 45px;
		}
	}

	.select2-results__option,
	.select2-search input {
		font-size: 0.8rem;
	}

	.select2-results__option {
		padding: 0.5rem 0.75rem;
	}

	.select2-search input {
		padding: 0.5em;
	}
}

.payment-methods {
	@media ( max-width: 768px ) {
		display: flex;
		flex-direction: column;
	}
}

.woocommerce-add-payment-method {
	ul.payment_methods {
		padding-left: 0;
		list-style-type: none;

		li {

		}

		.wc-stripe-elements-field {
			@extend .dbi-base-input;
		}

		.form-row {
			width: 7.5rem;
		}

		.form-row-wide,
		.woocommerce-SavedPaymentMethods-saveNew {
			width: 100%;
			max-width: 20rem;
		}
	}
	// There is only one option here so we can hide it. It is checked by default.
	input[type="radio"] {
		display: none;
	}

	ul.woocommerce-error {
		list-style: none;
		margin: 0;
		padding: 0;
	}
}

.payment-method-actions {
	@media ( max-width: 768px ) {
		padding: 0.25rem 0 1rem 0 !important;
		display: flex;
	}
}
